import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import Whatssap from '../../images/icons/whatsapp.svg';
import logoHeader from '../../images/logo-juancho-te-presta-vertical.png';
import { JTPState } from '../../store';
import styled from '../../styles/styled-components';
import Navbar from './Navbar';

const Navlink = styled(NavLink)`
  height: 30px;
  display: flex;
  font-size: 12px;
  border-radius: 30px;
  width: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;

  > h3 {
    color: #fff;
  }
`;

const HeaderStyled = styled.header`
  position: relative;
`;
const HeaderBar = styled.div`
  height: 80px;
  background-color: ${props => props.theme.blue};
  padding: 0.5rem 1rem 1rem;
`;
const ContentContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  aling-items:center;
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
`;
const Img = styled.img`
  display: block;
  margin:5px 0 5px 0;
  height: 50px;
`;
const Img2 = styled.img`
  display: block;
  margin:5px 0 5px 0;
  height: 30px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
`;

const AccountOptions = styled.span`
  margin-top: 17px;
  height: 30px;
  margin-left: 10px;
`;
const SideButtonsStyled = styled.div`
  position: fixed;
  bottom: 143px;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  z-index: 2000;
  color: transparent;
  flex-direction: column;

  :hover {
    color: ${props => props.theme.red};
    font-size: 17px;
  }
`;
const Button2 = styled.a`
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  text-decoration: none;
  margin: 0.5rem 0;
  height: 2.95rem;
  width: 3.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    
  }
  background-color:${props => props.theme.gray};
  margin-left: 5px;
`;
const MessageLogout = () => {
  swal('', 'Has cerrado sesión correctamente.', 'success', {
    dangerMode: true,
  });
};


interface Props {
  loggedIn: boolean;
}

const Header: React.FC<Props> = ({ loggedIn }) => {
  return (
    <HeaderStyled>
      
      <HeaderBar>
        <ContentContainerStyled>
          <SideButtonsStyled>
            <Button2 href="https://wa.me/5744807833">
              <Img2 src={Whatssap} alt="whatsapp" />
            </Button2>
          </SideButtonsStyled>
          <a href={`${process.env.REACT_APP_CMS}`} className="navbar-item">
            <Img src={logoHeader} alt="Juancho te presta" />
          </a>
          <AccountOptions>
            {loggedIn && (
              <Navlink to="/logout" onClick={MessageLogout}>
                <h3>Salir</h3>
              </Navlink>
            )}
          </AccountOptions>
        </ContentContainerStyled>
      </HeaderBar>
      <Navbar />
    </HeaderStyled>
  );
};

export default connect((state: JTPState) => ({
  loggedIn: state.session.loggedIn,
}))(Header);
