import { LitElement, html, css } from 'lit';

class PopupServices extends LitElement {
  static styles = css`
  :host {
      --light-blue-gray:#CED5F0;
      --light-gray:#ccc;
      --dark-gray:#333;
      --color-gender:#2F5BFF;
      --gray-color:#6c757d;
      display: inline-block;
    }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      color: black;
      visibility: hidden;
    }
    .popup-container--visible {
      visibility: visible;
    }
    .popup-container__popup {
      position:relative;
      background-color: rgba(254, 254, 254, 0.8);
      backdrop-filter: blur(5px); 
      padding: 10px 20px 20px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 35px;
      position: relative; 
      z-index: 1000;
      max-width: 32ch;
      transform: translateY(100%);
      opacity: 0;
      transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    }
    .popup-container__popup--visible {
      transform: translateY(0);
      opacity: 1;
    }
    .popup-container__title-bar {
      margin-top:20px;
      display: flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      margin-bottom: 10px;
      font-weight:600;
    }
    .close-button {
      cursor: pointer;
      position:absolute;
      top:10px;
      right:10px;
      transition: background-color 0.3s ease;
    }
    .close-button:hover {
      background-color: var(--light-gray);
    }
 
    .popup-container__overlay {
      backdrop-filter: blur(2px); 
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 998; 
    }
    .popup-container__button--keep{
      background-color:var(--color-gender);
      border:none;
      border-radius: 20px;
      margin-top: 10px;
      height: 39px;
      transition: box-shadow 0.3s, transform 0.3s;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0 , 0.4);
      cursor:pointer;
      font-weight: 600;
      padding: 0 15px 0 15px;
      color: white;
    }

    .popup-container__button--delete{
      color:var(--gray-color);
      border: none;
      background: none;
      text-decoration: underline;
      padding:10px;
      cursor:pointer;
    }
    .popup-container__buttons{
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
    }
    .popup-container__do{
      display:flex;
      align-items:center;
      font-size:13px;
      color:var(--color-gender);
    }
    .popup-container__simbol{
      font-family: Arial, sans-serif;
      border-radius:50%;
      border: solid 1px var(--color-gender);
      font-size:23px;
      width:18px;
      height:18px;
      margin:10px;
      /* color:black; */
      cursor:pointer;
      p{
        margin:0;
      }
    }
    .popup-container__simbol--less{
      padding:0 0 3px 5px; 
      line-height: 15px; 
    }
    .popup-container__simbol--plus{
      padding:0 0 0 2px;
      line-height: 18px; 
    }
    .popup-container__simbol---{
      background-color:var(--gray-color);
      width:45px;
      height:4px;
      margin-bottom:15px;
      cursor:pointer;
    }
    .popup-container__simbol--x{
      font-family:Arial;
      position:absolute;
      top:7px;
      right:7px;
      font-size:20px;
      width:18px;
      height:18px;
      margin:10px;
      color:var(--gray-color);
      cursor:pointer;
      p{
        margin:0;
        padding:0 0 1px 4px;
        line-height: 16px; 
      }
    }
    .popup-container--info {
      max-height:200px;
      overflow-y: auto;
    }
  `;
  static get properties() {
    return {
      valueColor: { type: String },
      stateOpenInfo:{type:Boolean},
      isOpen: { type: Boolean },
    };
  }
  constructor() {
    super();
    this.stateOpenInfo = false;
    this.isOpen = false;
  }
  updated(changedProps) {
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--color-gender', this.valueColor);
    }
    if (changedProps.has('isOpen')) {
      this._togglePopupVisibility();
    }
  }

  _togglePopupVisibility() {
    const popupContainer = this.shadowRoot.querySelector('.popup-container');
    const popup = this.shadowRoot.querySelector('.popup-container__popup');
    
    if (this.isOpen) {
      popupContainer.classList.add('popup-container--visible');
      setTimeout(() => {
        popup.classList.add('popup-container__popup--visible');
      }, 50);
    } else {
      popup.classList.remove('popup-container__popup--visible');
      setTimeout(() => {
        popupContainer.classList.remove('popup-container--visible');
      }, 500);
    }
  }


  closePopup() {
    this.isOpen = false;
    this.dispatchEvent(new CustomEvent('close-popup'));
  }
  closePopupWithoutDelete(){
    this.isOpen = false;
    this.dispatchEvent(new CustomEvent('close-popup-without-delete'));
  }

  openInfo(){
    this.stateOpenInfo = !this.stateOpenInfo
  }

  render() {
    return html`
      <div class="popup-container">
        <div class="popup-container__popup">
          <div class="popup-container__simbol--x" @click="${this.closePopup}">
            <p>x</p>
          </div>
          
          <div class="popup-container__title-bar">
            <!-- <div class="popup-container__simbol---" @click="${this.closePopup}"></div> -->
            <slot name="title"></slot>
          </div>
          <!-- Contenido de la ventana emergente -->
          <slot></slot>
          <div class="popup-container__do"><p>¿Qué debo hacer si no la contrato?</p>
            <div class="popup-container__simbol" @click="${this.openInfo}">
               ${this.stateOpenInfo===true
                ?html`<p class="popup-container__simbol--less">-</p>`
                :html`<p class="popup-container__simbol--plus">+</p>`}
            </div>
          </div>
          <div class="popup-container--info" >
          ${this.stateOpenInfo===true
                ?html` <slot name="extra-info"></slot>`
                :html``}
          </div>

          <div class="popup-container__buttons">
            <button  class="popup-container__button--keep" @click="${this.closePopupWithoutDelete}">Quiero conservarla</button>
            <button  class="popup-container__button--delete" @click="${this.closePopup}">Eliminar</button>
          </div>
        </div>
        <!-- Capa de fondo -->
        <div class="popup-container__overlay"></div>
      </div>
    `;
  }
}

customElements.define('popup-services', PopupServices);