import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { TokenAttrs } from '../../helpers/tokenHelpers';
import creditListGet, { isLoanActive, isLoanCancel, isLoanProcess, isLoanPunished, isLoanReject, isLoanRejectAfterDays } from '../../services/creditListGet';
import { LoanStatus } from '../../services/loanChangeStatusPut';
import { LoanList } from '../../services/loansPayment';
import userStatusService, { Result as userResult } from '../../services/userStatus';
import { PathName } from '../../views/Workflow';
import { NavigationRequirePayload } from '../actions/navigation';
import { logoutRequest } from '../actions/session';
import { ActionPromise } from '../asyncDispatch';
import loans from '../reducers/allLoanReducer';
import { LoanState } from '../reducers/loanReducer';
import { tokenAttrsSelector } from '../selectors';
import callService from './callService';
import { loanState } from './loanState';
import { refreshTokenSaga } from './session';

function pathFromLoanStatus(status: LoanStatus): PathName {
  return (
    (
      {
        'Submitted and pending approval': '/loans/credit-history',
        Rejected: '/loans',
        'Submitted and awaiting scrapping': '/loans/finance-status',
        'Submitted and awaiting request': '/loans/amount-and-term',
        'Approved and awaiting bank details':'/loans/summary',
        'Submitted and pending ID validation': '/users/validate-identity',
        Approved: '/loans/signature',
      } as Record<LoanStatus, PathName>
    )[status] || '/loans'
  );
}

//Load
export function* navigationRequiresSaga({
  payload: require,
  meta,
}: ActionPromise<NavigationRequirePayload>) {
  try {
    let ok = true;
    if (require.attrs) {
      yield call(refreshTokenSaga);
      const attrs = yield select(tokenAttrsSelector);

      ok = Object.entries(require.attrs).reduce<boolean>(
        (ok, [key, status]: [string, string | undefined]) => {
          return ok && key in attrs && status === attrs[key];
        },
        ok
      );
    }

    const loan: LoanState = yield call(loanState);
    if (loan.status === 'Rejected') {
      yield put(logoutRequest());
      return yield call(meta.reject, new Error('Loan Rejected'));
    }

    if (require.loanStatus) {
      ok =
        ok && Array.isArray(require.loanStatus)
          ? require.loanStatus.includes(loan.status)
          : require.loanStatus === loan.status;
    }

    if (ok) {
      yield call(meta.resolve);
    } else {
      yield call(
        meta.reject,
        new Error(
          `Navigation: '${window.location.pathname}' requires: ${JSON.stringify(
            require
          )}`
        )
      );
    }
  } catch (e) {
    yield call(meta.reject, e);
  }
}

//next
export function* resolveNextPathSaga({
  payload: _current,
  meta,
}: ActionPromise<PathName>): SagaIterator {
  yield call(refreshTokenSaga);
  const attrs: TokenAttrs = yield select(tokenAttrsSelector);
  const loan: LoanState = yield call(loanState);
  const loansList: LoanList = yield callService(creditListGet);
  // let sucessfullyIv = false;
  // if (loan.id !== '' && loan.status !== 'Active') {
  //   const { sucessfully, statusClient } = yield callService(videoIDGet);
  //  sucessfullyIv = sucessfully

  //   if(statusClient==="reject"){
  //     yield call(meta.reject, new Error('MessageError1'));
  //     return;
  //   }
  // }
  yield put(loans.actions.update(loansList));
  const user: userResult = yield callService(userStatusService);

  if (
    user.status.id === 600 &&
    [1314, 1317, 1368,1532, 1505, 1506,1533,1546,1516].includes(user.clientClosureReason?.id || 0)
  ) {
    yield call(meta.reject, new Error('MessageError4'));
    return;
  }

  if (
    user.status.id === 600 &&
    [1315].includes(user.clientClosureReason?.id || 0)
  ) {
    yield call(meta.reject, new Error('MessageError2'));
    return;
  }

  if (isLoanReject(loansList)) {
    yield call(meta.reject, new Error('MessageError3'));
    return;
  }

  if (loan.status === 'Withdrawn by applicant') {
    yield call(meta.reject, new Error('Loan Withdrawn by applicant'));
    return;
  }

  const flow = {
    next() {
      if (!isLoanProcess(loansList) && isLoanRejectAfterDays(loansList)) {
        return '/loans';
      }

      /**********************
      Si tiene credito cancelados, ninguno CASTIGADO y ninguno en proceso (pag 17)
      */
      if (
        isLoanCancel(loansList) &&
        !isLoanPunished(loansList) &&
        !isLoanProcess(loansList)
      ) {
        return '/loans';
      }

      /*********************
      Castigado (601): siempre debe estar acompañado de un crédito Activo (300) 
      o 600,700 (cerrado), así que se permitirá ingresar a realizar el pago del 
      crédito bajo ese estado pero no solicitud uno nuevo (pag 32)
      */
      if (
        isLoanPunished(loansList) &&
        (isLoanActive(loansList) || isLoanCancel(loansList))
      ) {
        return '/loans';
      }

      /*********************
        Sino tiene creditos ACTIVOS y tampoco en PROCESO
      */
      if (
        !isLoanActive(loansList) &&
        !isLoanProcess(loansList) &&
        Object.values(loansList).length > 0
      ) {
        return '/loans';
      }

      if (attrs.pi === 'TODO') return '/users/basic-info';

      // if (attrs.dc === 'TODO') return '/users/disclaimer';

      if (loan.status === 'Submitted and awaiting scrapping')
        return '/loans/finance-status';

      if (loan.status === 'Submitted and pending approval')
        return '/loans/credit-history';

      if (loan.status === 'Submitted and awaiting request')
        return '/loans/amount-and-term';

      if (loan.status === 'Approved and awaiting bank details')
        return '/loans/summary';

      if (loan.status === 'Approved'){
        return '/loans/signature';
      } 
      // if (!isLoanActive(loansList)) {
      //   if (!sucessfullyIv)
      //     return '/users/validate-identity';
      // }
      // if (attrs.sq === 'TODO') return '/users/security-questions';

      // if (loan.status === 'Approved pre-disburse')
      //   return '/loans/predisburse-info';

      if (loan.status === 'Approved pre-disburse')
          return '/loans/signature';

      if (loan.status === 'Approved and awaiting signature')
        return '/loans/signature';

      if (loan.status === 'Submitted and awaiting confronta')
        return '/loans/signature';
      return pathFromLoanStatus(loan.status);
    },
  } as Record<PathName | 'next', () => PathName>;

  /**
   * login -> loans
   * loans: click on loan process active -> resolve path
   * map the loan status: to next path
   */
  yield call(meta.resolve, {
    next: flow.next(),
  });
}
