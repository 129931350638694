/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { object, string } from 'yup';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormSubmit from '../../../components/Form/FormSubmit';
import { formats } from '../../../components/Form/Input';
import InputGroup from '../../../components/Form/InputGroup';
import Column from '../../../components/Grid/Column';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import ButtonGeneric from '../../../components/UI/ButtonGeneric';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { ModalSendEmail, useModalSendEmail } from '../../../components/UI/ModalSendEmail';
import {
  Alert2,
  AlertEmail,
  Background,
  Close,
  ContentDays,
  ContinerSend,
  DocumentList,
  MainContent,
  MainContentSend,
  PrincipleMessage,
  Resend,
  SubMessage2,
  TitleSend2,
  WatchFlash
} from '../../../components/UI/StylesOptionality';
import { submitTrap } from '../../../helpers/formHelpers';
import alert from '../../../images/icons/1_Icono-alerta.svg';
import watchFlash from '../../../images/icons/2_Icono-relojflash.svg';
import basicInfoPatch from '../../../services/basicInfoPatch';
import { ComponentsGet, ValuesPach } from '../../../services/changeLoanValuesPost';
import loanSignaturePost from '../../../services/loanSignaturePost';
import { ResolveNextPath } from '../../../store/actions/navigation';
const InitialValues = {
  bank: '' as any,
  account: '',
  accountEndsWith: '',
  accountType: '' as any,
  automaticDebit: true,
  disbursementMethodId: '',
  email: '',
  fullName: '',
};


type FormData = typeof InitialValues;
const formDataReducer = (state: FormData, action: any): FormData => {
  return {
    ...state,
    ...action,
  };
};
interface ErrorWithEmail {
  errors: {
    email: string;
  };
}
interface EmailForm {
  email: string;
}
export interface Props extends RouteComponentProps {
  email: string;
  loanStatus: string;
  term: number;
  daysCounter:number;
  existsElectronicSignature:boolean;
  existsGuaranteeVariable:boolean;
  submit(params: ValuesPach): Promise<void>;
  resolveNextPath: ResolveNextPath;
  optionalComponents:ComponentsGet[];
}
const PredisburseInfoView: React.FC<Props> = (
  {
    history,
    email,
    loanStatus,
    daysCounter,
    existsElectronicSignature,
    existsGuaranteeVariable,
    submit,
    optionalComponents
  }
) => {
  const [formData, updateFormData] = React.useReducer(
    formDataReducer,
    InitialValues
  );
  const [modal, openModal] = useModalC();
  const [modalSend, openModalSend] = useModalSendEmail();
  const [emailUpdated, setEmailUpdated]=useState("")
  const [resend, setResend] = useState(false)
  const [addServices, setAddServices] = useState(false)

  useEffect(() => {
    setEmailUpdated(email)
  }, [email]);
  const ErrorMessageConfig = (msj: string) => ({
    main: <p>{msj}</p>,
    accept: 'Aceptar',
  });
  
const FinishMessage = (title: string) => ({
  title:(
  <h3>{title}</h3>
  ),
  main: (
    <p>
      <strong>Pendiente de tu correo,</strong> te llegaron todos los contratos 
      para que los firmes de manera electrónica. 
    </p>
  ),
  accept: 'Aceptar',
});
  const handleClickResend = () =>{
    setResend(true)
  }
  const handleClickAddServices= async()=>{
    setAddServices(true)
    const response:ValuesPach={
      optionalComponents:optionalComponents,
    }
    await submit(response);
    history.push('/login');
  }
  const submitAll = useCallback(async () => {

    setEmailUpdated(formData.email.trim())  
    try {
      await basicInfoPatch({ email: formData.email.trim() });
      await loanSignaturePost({
            url: window.location.origin + '/loans/predisburse',
            loanStatusId:203
          }
        );
    } catch (e) {
      const error = e as ErrorWithEmail;
      await openModal(ErrorMessageConfig(error.errors.email));
      return;
    }
    setResend(false)
    await openModalSend(FinishMessage("¡Genial!"));

    // history.push('/logout');
  }, [formData, loanStatus, history]);
  return (
    <>
    <Column>
      <GeneralContent>
        <ModalSendEmail props={modalSend} />

      {existsElectronicSignature&& existsGuaranteeVariable && <>
        <ModalSendEmail props={modalSend} />
        <p>
          Firmaste tus documentos exitosamente, vamos a revisar que todo esté
          correcto. En caso de necesitar información adicional te contactaremos.
        </p>
        <p>Si todo está bien, desembolsaremos tu crédito pronto.</p>
      <ButtonContainer>
        <Button color="red" expand as={NavLink} to="/logout/redirect/home">
          Salir ahora
        </Button>
      </ButtonContainer>
          </>
      } 
      {(!existsElectronicSignature || !existsGuaranteeVariable) && <>
          <TitleSend2> !Tu solicitud quedará en pausa!</TitleSend2>
          <ContentDays>
            <Alert2 src={alert} alt='icono  de alerta'/>
            {daysCounter === 1 ?<p>Tienes {daysCounter} día calendario</p>:
            <p>Tienes {daysCounter} días calendario</p>}
          </ContentDays>
          </>
      }
      {/* contrata aval pero no firma */}
      {!existsElectronicSignature&& existsGuaranteeVariable && <>
          <PrincipleMessage>
              Te enviamos un correo alectrónico a 
              <strong>{` ${emailUpdated} `}</strong> con las instrucciones
              para gestionar la firma por tu cuenta, ya que no has contratado
              nuestro servicio: <label> Firma electrónica</label>
          </PrincipleMessage>
          <PrincipleMessage> Estos con los contratos que necesitas firmar físicamente
            para recibir tu crédito:
          </PrincipleMessage>
          <MainContent>
              <DocumentList>
                    <li>Garantía mobiliaria</li>
                    <li>Acuerdo económico</li>
                    <li>Poder económico</li>
                    <li>Débito automático</li>
                    <li>Contrato garantía crediticia</li>
                    <li>Privacidad compañía avaladora</li>
              </DocumentList>
          </MainContent>
          </>
      }
      {/* contrata firma pero no aval*/}
      {existsElectronicSignature&& !existsGuaranteeVariable && <>
          <PrincipleMessage>
              Te enviamos <strong>dos</strong> correos alectrónicos a 
              <strong>{` ${emailUpdated} `}</strong> Revisa cuidadosamente 
              lo que debes hacer, ya que no estás contratando el servicio: <label>Aval.</label>
          </PrincipleMessage>
          <PrincipleMessage> <strong>1.</strong> Abre el correo "Link para firmar tus contratos"
          y haz clic en el enlace para firmar los siguientes contratos:
          </PrincipleMessage>
          <MainContent>
              <DocumentList>
                    <li>Garantía mobiliaria</li>
                    <li>Acuerdo económico</li>
                    <li>Poder económico</li>
                    <li>Débito automático</li>
              </DocumentList>
          </MainContent>
          <PrincipleMessage> <strong>2.</strong> Abre el correo llamado "Contratación de aval"
          y sigue las instrucciones para contratar el aval por tu cuenta.
          </PrincipleMessage>
          </>
      }  
      {!existsElectronicSignature&& !existsGuaranteeVariable && <>
          <PrincipleMessage>
              Te enviamos <strong>dos</strong> correos alectrónicos a 
              <strong>{` ${emailUpdated} `}</strong> Revisa cuidadosamente 
              lo que debes hacer, ya que no estás contratando los servicios:
              <label> Firma electrónica y Aval.</label>
          </PrincipleMessage>
          <PrincipleMessage> <strong>1.</strong> Estos con los contratos que necesitas firmar físicamente
            para recibir tu crédito:
          </PrincipleMessage>
          <MainContent>
              <DocumentList>
                    <li>Garantía mobiliaria</li>
                    <li>Acuerdo económico</li>
                    <li>Poder económico</li>
                    <li>Débito automático</li>
              </DocumentList>
          </MainContent>
          <PrincipleMessage> <strong>2.</strong> Abre el correo llamado "Contratación de aval"
          y sigue las instrucciones para contratar el aval por tu cuenta.
          </PrincipleMessage>
          </>
      }  
      
      <Resend>
        <p>¿No encuentras el correo? <strong onClick={handleClickResend}>Reenvíalo.</strong> </p>
      </Resend>
     
        {(!existsElectronicSignature || !existsGuaranteeVariable) && <>
          <SubMessage2>
             <WatchFlash src={watchFlash} alt="reloj flash" />
            <p>Agrega los servicios opcionales para que te 
              desembolsemos en 24 horas
            </p>
          </SubMessage2>
          <ButtonGeneric
             margin={true}
             loading={addServices}
             onClick={handleClickAddServices}
             textColor="black">¡Agregar ahora!</ButtonGeneric>
         </>
        } 
      </GeneralContent>
    </Column>
    <ModalC props={modal} />
      {resend &&<ContinerSend>
        <Background onClick={() => setResend(false)}/>
          <MainContentSend>
          <AlertEmail src={alert} alt="alerta enviar correo" />
          <Close onClick={() => setResend(false)} />
            <p>Enviaremos los contratos para firmar a la siguiente dirección de 
              correo electrónico:
            </p>
            <Formik
            initialValues={{ email }}
            validationSchema={object<EmailForm>({
              email: string()
                .trim()
                .email('Formato no valido')
                .required('Campo obligatorio'),
            })}
            onSubmit={submitTrap(submitAll as any)}
          >
            <Form>
              <FormChange onChange={(value) => updateFormData(value)} />
              <InputGroup
                name="email"
                type="email"
                icon="envelope"
                format={formats.email}
                placeholder="example@email.com"
              />
              <ButtonContainer>
                <FormSubmit>Enviar</FormSubmit>
              </ButtonContainer>
            </Form>
          </Formik>
        </MainContentSend>
      </ContinerSend> }
      </>
  );
}

export default PredisburseInfoView;
