import * as Sentry from '@sentry/browser';
import { FormikActions } from 'formik';
import { FormFieldsError } from '../services/FormFieldsError';

type Submit<T> = (
  values: T,
  actions: FormikActions<T>,
  setFormError: (message: React.ReactNode) => void
) => Promise<void> | void;

export const submitTrap = <T = any>(submit: Submit<T>) => {
  return async (values: T, actions: FormikActions<T>) => {
    try {
      actions.setSubmitting(true);
      await submit(values, actions, setFormError(actions));
    } catch (e) {
      if (typeof e === 'object' && e && !('errors' in e && (e as any).errors?.hidden)) {
        Sentry.withScope((scope) => {
          scope.setFingerprint(['form-error', window.location.pathname]);
          Sentry.captureException(e);
        });
      }
      if (e instanceof FormFieldsError) {
        actions.setErrors(e.errors);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };
};

export const setFormError = <T>(actions: FormikActions<T>) => (
  message: React.ReactNode
) => {
  actions.setFieldError('form-error', message as any);
};
