import { ServiceLoaded } from '../types/Service';
import serviceBuilder from './serviceBuilder';

export interface SimulatorTerm {
  months: number;
  incomeMultiplier: number;
  minScore: number;
}

export interface SimulatorWorkType {
  workingStatus: string;
  minIncome: number;
  terms: SimulatorTerm[];
}
export interface SimulatorComponentsType {
  id: number;
  name: string;
  optional: boolean;
  value:number;
}

export interface SimulatorParams {
  components:SimulatorComponentsType[];
  options: SimulatorWorkType[];
}

type Result = SimulatorParams;

export const url = 'sec/loans/sim?path=:path';

const service = serviceBuilder<{path:number}, Result>('get', {
  query: true,
  url,
  auth: true,
});

export default async function (
  path:number
): Promise<ServiceLoaded<Result>> {
  try {
    const { payload, status } = await service({path});
    const findAndModifyComponent = (name: string, modifier: (value: number) => number) => {
      const component = payload.components.find((component:SimulatorComponentsType) => component.name === name);
      if (component) {
        component.value = modifier(component.value);
      }
    };
    findAndModifyComponent("guaranteeConstant", value => value * 0.01);
    findAndModifyComponent("guaranteeVariableMan", value => parseFloat((value * 0.01).toFixed(7)));
    findAndModifyComponent("guaranteeVariableWoman", value => value * 0.01);
    findAndModifyComponent("interest", value => value * 0.01);
    findAndModifyComponent("VAT", value => parseFloat((value * 0.01).toFixed(5)));
    return { payload, status };
  } catch (e) {
    throw e;
  }
}
