/* eslint react-hooks/exhaustive-deps: 0 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import noop from '../../helpers/noop';
import check from '../../images/icons/3_Icono-check.svg';
import styled from '../../styles/styled-components';

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  #z-index: 1000;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;
const Close = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  &:before {
    content: ' ';
    position: absolute;
    background-color: #000;
    width: 2px;
    height: 16px;
    top: 1px;
    right: 7px;
    transform: rotate(45deg);
    cursor: pointer;
  }
  &:after {
    content: '';
    position: absolute;
    background-color: #000;
    width: 16px;
    height: 2px;
    top: 8px;
    right: 0;
    transform: rotate(45deg);
    cursor: pointer;
  }
`;
const Content = styled.div`
  position:relative;
  background-color: #fff;
  color: ${(props) => props.theme.gray500};
  padding: 20px 50px 30px 50px;
  margin: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  max-height: 95%;
  z-index: 1001;
  max-width: 400px;
  border-radius: 40px;
  font-size: 1.1rem;
  p{
    margin-top:30px;
    text-align:left;
    color:black;
    font-size:14px;
  }
`;

const Check = styled.img`
  width: 100px;
  height:100px;
`
// const Alert = styled.img`
//   position:absolute;
//   width: 70px;
//   height: 70px;
//   top: -80px;
//   left:calc(50% - 30px);
// `
const Title = styled.div`
  color:#6235CB;
`

// modal 2
type Fulfill = (value: { accept?: boolean; cancel?: boolean }) => void;

interface ModalContent {
  title?: ReactNode;
  main: ReactNode;
  accept?: string | boolean;
  cancel?: string | boolean;
  noClosable?: boolean;
}

type ModalAsync = (
  c: ModalContent
) => Promise<{ accept?: boolean; cancel?: boolean }>;

export const useModalSendEmail= (): [
  { content?: ModalContent; fulfill: Fulfill; close: () => void },
  ModalAsync
] => {
  const [content, setContent] = useState<ModalContent>();
  const [fulfill, setFulfill] = useState<Fulfill>(noop);

  const modal = useCallback((c: ModalContent) => {
    setContent(c);
    const promise = new Promise((resolve: Fulfill) => {
      setFulfill(() => resolve);
    });
    return promise;
  }, []);

  const close = useCallback(() => {
    setContent(undefined);
  }, []);

  return [{ content, fulfill, close }, modal];
};

interface ModalProps {
  props: {
    fulfill: Fulfill;
    content?: ModalContent;
    close(): void;
  };
  container?: HTMLElement;
}

export const ModalSendEmail: React.FC<ModalProps> = ({
  props: { content, fulfill, close },
  container = document.getElementById('modal'),
}) => {

  const cancel = useCallback(() => {
    fulfill({ cancel: true });
    close();
  }, [fulfill]);

  useEffect(() => {
    return () => {
      if (fulfill) fulfill({ cancel: true });
      close();
    };
  }, []);

  return (
    <>
      {container &&
        content &&
        ReactDOM.createPortal(
          <ModalStyled>
            <Background onClick={() => !content.noClosable && cancel()} />
            <Content>
              {/* <Alert src={alert} alt="icono de alerta"/> */}
              {!content.noClosable && <Close onClick={cancel} />}
              {content.title && <Title className="title">{content.title}</Title>}
              {content.main && <div className="main"> {content.main}</div>}
              <Check src={check} alt="icono check"/>
            </Content>
          </ModalStyled>,
          container
        )}
    </>
  );
};
