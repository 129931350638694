import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/Router/PrivateRoute';
import NotFound from '../NotFound';
import CancelCredit from './CancelCreditView';
import CheckCreditHistoryView from './CheckCreditHistory/CheckCreditHistoryView';
import ConfirmLoanContainer from './ConfirmLoan/ConfirmLoanContainer';
import LoanActiveDescriptionView from './Loans/LoanActiveDescriptionView';
import LoanActiveView from './Loans/LoanActiveView';
import LoanPaymentFeedback from './Loans/LoanPaymentFeedback';
import LoanPaymentPSEView from './Loans/LoanPaymentPSEView';
import LoansPaymentInformationView from './Loans/LoansPaymentInformationView';
import PredisburseInfoContainer from './PredisburseInfo/PredisburseInfoContainer';
import PredisburseView from './PredisburseView';
import ScrappingViewBelvo from './Scrapping/ScrappingViewBelvo';
import SignatureContainer from './Signature/SignatureContainer';
import SummaryLoanContainer from './Summary/SummaryLoanContainer';

const LoanRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <PrivateRoute exact path="/loans" component={LoanActiveView} />

    <PrivateRoute
      path="/loans/quotas/:loanId/:code"
      component={LoanActiveDescriptionView}
    />
    <PrivateRoute
      path="/loans/payment/:loanId/:paymentId/:code"
      component={LoansPaymentInformationView}
    />
    <PrivateRoute path="/loans/pse" component={LoanPaymentPSEView} />
    <PrivateRoute
      path="/loans/feedback/:loanId/:paymentId/:code"
      component={LoanPaymentFeedback}
    />
    <PrivateRoute
      require={{ loanStatus: 'Submitted and awaiting request' }}
      path="/loans/amount-and-term"
      component={ConfirmLoanContainer}
    />
    <PrivateRoute
      require={{ loanStatus: 'Approved and awaiting bank details' }}
      path="/loans/summary"
      component={SummaryLoanContainer}
    />
    <PrivateRoute
      // require={{
      //   loanStatus: [
      //     'Approved',
      //     'Approved pre-disburse',
      //     'Approved and awaiting signature',
      //   ],
      // }}
      path="/loans/signature"
      component={SignatureContainer}
    />
    <PrivateRoute
      require={{ loanStatus: 'Submitted and awaiting scrapping' }}
      path="/loans/finance-status"
      component={ScrappingViewBelvo}
    />
    <PrivateRoute
      require={{ loanStatus: 'Submitted and pending approval' }}
      path="/loans/credit-history"
      component={CheckCreditHistoryView}
    />
    <PrivateRoute
      // require={{ loanStatus: 'Approved pre-disburse' }}
      path="/loans/predisburse-info"
      component={PredisburseInfoContainer}
    />
    {/* <PrivateRoute
      require={{ loanStatus: 'Approved and awaiting signature' }}
      path="/loans/signature-confirm"
      component={ConfirmSignature}
    /> */}
    <Route path="/loans/predisburse/:uuid" component={PredisburseView} />
    <Route path="/loans/cancel/:uuid" component={CancelCredit} />
    <Route component={NotFound} />
  </Switch>
);

export default LoanRoutes;
