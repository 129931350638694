import { LitElement, html, css } from 'lit';
import ayuda from '../images/preguntas-mas-frecuentes.png';

class ModalDialog extends LitElement {
  static properties = {
    title: {},
  }

  static styles = css`
   

    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 2147483;
    }

    .modal {
      color:black;
      background-color:#ced5f0;
      border: none;
      max-width: 24ch;
      border-radius: 8px;
      padding: 1.5em;
      position: absolute;
    }

    .help-image {
      cursor: pointer;
    }

    .close-button {
      position: absolute;
      font-weight: 60;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12.1px;
      height: 12.1px;
      margin: 3px;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 50%;
      position: relative;
    }

    .message {
      font-weight: 400;
      font-size: 12px;
      line-height: 10px;
      margin: 0;
    }
  `;

  constructor() {
    super();
    this.modal = null;
    this.backdrop = null;
  }

  firstUpdated() {
    this.modal = this.shadowRoot.querySelector('.modal');
    this.backdrop = this.shadowRoot.querySelector('.modal-backdrop');
    this.container = this.shadowRoot.querySelector('.container');
   
  }

 
  openPopup() {
   
    if (this.modal && this.backdrop && this.container) {
      this.backdrop.style.display = 'flex';
      const rect = this.container.getBoundingClientRect();
      const modalRect = this.shadowRoot.querySelector('.modal').getBoundingClientRect();
      let top = rect.bottom - window.scrollY;
      let left = rect.left - window.scrollX;
     
      
      if (top + modalRect.height > window.innerHeight) {
        top = window.innerHeight - modalRect.height - 10;
      }
      if (left + modalRect.width > window.innerWidth) {
        left = window.innerWidth - modalRect.width - 10;
      }
      if (top < 0) {
        top = 10;
      }
      if (left < 0) {
        left = 10;
      }
      console.log("rect ",rect)
      console.log("modalRect  ",modalRect )
      console.log("top ",top )
      console.log("left ",left)
      console.log("window.innerHeight",window.innerHeight)
      console.log("window.innerWidth",window.innerWidth)
      console.log("modalRect.height",modalRect.height)
      console.log("modalRect.width ",modalRect.width)

      this.modal.style.top = `${top}px`;
      this.modal.style.left = `${left+20}px`;
      
    }
  }

  closePopup() {
    console.log('Closing popup');
    if (this.backdrop) {
      this.backdrop.style.display = 'none';
    }
  }

  render() {
    return html`
      <div class="container" @mouseover="${this.openPopup}" @mouseout="${this.closePopup}">
        <img class="help-image" src="${ayuda}" alt="ayuda" />
        <div class="modal-backdrop">
          <div class="modal">
            <h3>${this.title}</h3>
            <!-- <span class="close-button" @click="${this.closePopup}">X</span> -->
            <slot class="message"></slot>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('modal-dialog', ModalDialog);