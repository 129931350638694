/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Column from '../../components/Grid/Column';
import '../../components/lit/components/button-form';
import '../../components/lit/simulator-summary/simulator-summary';
import { ModalC, useModalC } from '../../components/UI/ModalDisclaimer';
import firstDatePayment from '../../helpers/financial/firstDatePayment';
import API_URL from '../../services/API_URL';
import leadsPost from '../../services/leadsPost';
import { JTPState } from '../../store';
import styled from '../../styles/styled-components';

const Header = styled.div`
  position:absolute;
  width:100%;
  top:0;
  height:150px;
  background-color:#FFCD00;
`
const ContainerSimulate= styled.div`
  background: linear-gradient(to bottom, #D9D9D9, white);
  padding-top:60px;
  position:relative;
`

interface FormSubmitEvent extends Event {
  detail: {
    termsAndConditions: Boolean,
    email: String,
    phone: String,
    recaptchaToken: String
  }
}
interface APIError extends Error {
  response?: Response;
}

interface Props extends RouteComponentProps { }

const SimulateView: React.FC<Props> = ({ history }) => {
  const [modalC, openModalC] = useModalC(); 
  const messageError={
    main: (
      <div>
         
      <p>
      <strong>
        JuanchoTePresta.com, 
      </strong> pone a tu disposición el presente Simulador en los
      términos de Ley, el cual tiene fines 
      informativos y no comporta oferta o promesa de contratar.
      Este simulador es indicativo y está destinado a 
      proporcionarte información y estimaciones de carácter general.
      El resultado de esta simulación no comprende una 
      certificación o recomendación comercial, contable, 
      tributaria o legal. Los términos de esta simulación 
      son suministrados con base en las condiciones comerciales
      y de mercado que han sido establecidas para la fecha en 
      que realizas esta simulación. La tasa de interés
      remuneratoria corresponderá a la que JUANCHO 
      TE PRESTA SAS tenga establecida al momento de tu 
      utilización. Otros componentes del crédito dependen 
      de terceros y pueden variar según tu nivel de riesgo
      y capacidad de pago.
      </p>
      </div>
    ),
    noClosable: false,
  }
  const handledisclaimer= async () => {
        await openModalC(messageError)     
  }
  const simulator = useSelector((state: JTPState) => state.simulator);
  console.log("datos de lsimulador",simulator)
  const [valueFirstDatePayment, setFirstDatePayment] = useState('');
  const buttonFormRef = useRef<HTMLElement>(null);
  const creditSimulatorRef = useRef<HTMLElement>(null);
  useEffect(() => {
    handledisclaimer()
    firstDatePayment().then((resp) => {
      setFirstDatePayment(resp.format('DD-MM-YYYY'));
    });
  }, []);

  const handleButtonClick = () => {
    history.push('/signup');
  };
  const handleFormSubmit = async (e:FormSubmitEvent)=>{
    const _event = e.detail
    try {
      await leadsPost(_event);
    } catch (error) {
        const apiError = error as APIError;
        if (apiError.response && apiError.response.status === 500) {
          // alert("El usuario ya existe. Por favor, intente con otro correo electrónico.");
        } else {
          console.error("Error en la solicitud", apiError);
          // alert("Ha ocurrido un error. Por favor, intente nuevamente más tarde.");
        }
    }
  }
  useEffect(() => {
    const buttonFormElement = buttonFormRef.current;
    const creditSimulatorElement = creditSimulatorRef.current;
    if (creditSimulatorElement ) {
      creditSimulatorElement.addEventListener('button-click',handleButtonClick);
    }

    if (buttonFormElement) {
      const handleFormSubmitWrapper = (evt: Event) => {
        if ((evt as FormSubmitEvent).detail) {
          handleFormSubmit(evt as FormSubmitEvent);
        }
      };
      buttonFormElement.addEventListener('form-submit', handleFormSubmitWrapper);

      return () => {
        if (creditSimulatorElement ) {
          creditSimulatorElement.removeEventListener('button-click', handleButtonClick);
        }
        if (buttonFormElement) {
          buttonFormElement.removeEventListener('form-submit', handleFormSubmitWrapper);
        }
      };
    }
  }, [buttonFormRef,creditSimulatorRef]);

  const getValue = (name: string) => {
    const component = simulator.components.find((comp: { name: string }) => comp.name === name);
    return component ? component.value : 0;
  };

  const interest = getValue('interest');
  const electronicSignature = getValue('electronicSignature');
  const guaranteeVariableMan = getValue('guaranteeVariableMan');
  const guaranteeVariableWoman = getValue('guaranteeVariableWoman');
  const maxAmount = getValue('maxAmount');
  const guaranteeConstant = getValue('guaranteeConstant');
  const guaranteeFurniture = getValue('guaranteeFurniture');
  const minAmount = getValue('minAmount');
  const incomeFactor = getValue('VAT');

  return (
    <Column fluid>
      <ModalC props={modalC}/>
      <ContainerSimulate>
      <Header></Header>
      <button-form ref={buttonFormRef}></button-form>
        <credit-simulator 
          terms={[24,18,12]}
          interest={interest}
          electronicSignature={electronicSignature} 
          //disposableIncome={2000000}
          guaranteeVariableMan={guaranteeVariableMan} 
          guaranteeVariableWoman={guaranteeVariableWoman} 
          maxAmount={maxAmount} 
          incomeFactor=	{incomeFactor}
          guaranteeConstant={guaranteeConstant}
          guaranteeFurniture={guaranteeFurniture} 
          minAmount={minAmount}
          genderId={38}//37=man 38=woman
          secureWellbeing={0}
          product={"jtp"}
          urlGetProduct={API_URL}
          firstDatePayment={valueFirstDatePayment}
          ref={creditSimulatorRef}
        > 
        </credit-simulator>
      </ContainerSimulate>
    </Column>
  );
};

export default SimulateView;