import serviceBuilder from './serviceBuilder';

export interface ComponentsGet{
  id: number;
  value:number;
  name:string;
  statusId?:number;
}
export interface Components{
  id: number|null;
  value:number|null;
  statusId?:number;
}
export interface ValuesPach{
  optionalComponents?:ComponentsGet[];
  repaymentsStartingFromDate?: string;
  view?:string;
}
export interface LoanValues{
  mandatoryComponents:Components[];
  optionalComponents:Components[];
  loanPurpose: number;
  amount:number;
  term:number;
  repaymentsStartingFromDate: string;
  path:number;
  interest:number|null;
  genderName:string;
  product:string;
}

export const url = 'sec/loans/application';

export default serviceBuilder<LoanValues>('post', {
  url,
  auth: true,
});
