import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import loans from './reducers/allLoanReducer';
import { basicInfo } from './reducers/basicInfoReducer';
import { changeLoanValuesPatch } from './reducers/changeLoanValuesPatchReducer';
import { changeLoanValues } from './reducers/changeLoanValuesReducer';
import { disclaimer } from './reducers/disclaimerReducer';
import { jtdClientDecision } from './reducers/jtdClientDecisionReducer';
import loan from './reducers/loanReducer';
import pixel from './reducers/pixelReducer';
import { ready } from './reducers/readyReducer';
import selectors from './reducers/selectorsReducer';
import { session } from './reducers/sessionReducer';
import { signup } from './reducers/signupReducer';
import { simulator } from './reducers/simulatorReducer';
import { updateLoanStatus } from './reducers/updateLoanStatusReducer';
import { user } from './reducers/userReducer';
import { rootSaga } from './sagas';

const prod = process.env.NODE_ENV === 'production';
const dev = !prod;

export interface JTPState extends ReturnType<typeof root> {}

const requests = combineReducers({
  signup: signup.reducer,
  basicInfo: basicInfo.reducer,
  changeLoanValues: changeLoanValues.reducer,
  changeLoanValuesPatch : changeLoanValuesPatch.reducer,
  disclaimer: disclaimer.reducer,
  updateLoanStatus: updateLoanStatus.reducer,
  jtdClientDecision:jtdClientDecision.reducer,
});

const root = combineReducers({
  ready: ready.reducer,
  session: session.reducer,
  simulator: simulator.reducer,
  user: user.reducer,
  mifos: selectors.reducer,
  loan: loan.reducer,
  loans: loans.reducer,
  pixel: pixel.reducer,
  requests,
});

function rootReducer(state: JTPState | undefined, action: AnyAction) {
  return root(
    {
      ...(state || ({} as JTPState)),
      requests: requests(state?.requests, action),
    },
    action
  );
}

const sagaMiddleware = createSagaMiddleware();

function createStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    devTools: {
      features: {
        pause: true, // start/pause recording of dispatched actions
        lock: dev, // lock/unlock dispatching actions and side effects
        persist: dev, // persist states on page reloading
        export: true, // export history of actions in a file
        import: true, // import history of actions from a file
        jump: true, // jump back and forth (time travelling)
        skip: true, // skip (cancel) actions
        reorder: dev, // drag and drop actions in the history list
        dispatch: dev, // dispatch custom actions or action creators
        test: dev, // generate tests for the selected actions
      },
    },
  });

  Object.defineProperty(window, '$store', {
    configurable: false,
    enumerable: true,
    get() {
      return store;
    },
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = createStore();
