import { LitElement, html} from 'lit-element';
import { jtdAgreementStyles } from './styles/jtd-agreement-styles.js';
import infoIcon from '../images/informacionicon-white.png';
class JtdAgreement extends LitElement {
  

  static styles = jtdAgreementStyles;

  static properties = {
    title: { type: String },
    isLoading: { type: Boolean }
  };

  constructor() {
    super();
    this.title = '';
    this.isLoading = false;
  }

  handleAccept() {
    this.isLoading = true;
    this.dispatchEvent(new CustomEvent('desition-agreement', {
      bubbles: true, 
      composed: true,
      detail:{
         desition:true
     } 
     }));
  }

  handleCancel() {
    this.isLoading = true;
    this.dispatchEvent(new CustomEvent('desition-agreement', {
      bubbles: true, 
      composed: true,
      detail:{
         desition:false
     } 
     }));
  }

  render() {
    return html`
      <div class="modal">
        <div class="modal__background"></div>
        <div class="modal__main-content">
          <div class="modal__second-content">
            <div class="modal__icon-container">
              <img class="modal__notice-content" src=${infoIcon} alt="Información" />
            </div>
            <div class="modal__text-container">
              <h4>${this.title}</h4>
              <div class="modal__text-first">
                <p>Tu crédito está exento del 50% en aval variable y se descontará por nómina.</p>
              </div>
              <div class="modal__text-second">
                <p>
                  Si aceptas, notificaremos a tu empresa y una vez nos autorice, te contactaremos para continuar con la solicitud.
                </p>
              </div>
            </div>
            <div class="modal__info-content">
              <div class="modal__info">
                <p>Tu crédito con Juancho solo será posible a través de este convenio.</p>
              </div>
            </div>
            <div class="modal__button-content">
              ${this.isLoading ? html`<span>Cargando...</span>` : html`
                <button class="modal__button" @click="${this.handleAccept}">
                  <p>SI QUIERO</p>
                </button>
                <button class="modal__button" @click="${this.handleCancel}">
                  <p>NO ME INTERESA</p>
                </button>
              `}
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
customElements.define('jtd-agreement', JtdAgreement);
