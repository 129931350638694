import { LitElement, html} from 'lit-element';
import './form-info';
import NotificacionesSvg from '../images/Notificaciones.svg'
class ButtonForm extends LitElement {
  static get properties() {
      return {
        showForm: { type: Boolean },
        ValueColor:{ type: String },
      };
  }
  constructor() {
    super();
    this.ValueColor = "#FFCD00";
    this.showForm=false;
    this.email = "";
    this.cedula = "";
  }
  handleAcceptClosePopup(e) {
    
    this.showForm = false;
    this.style.setProperty('--color-botton', "#FFCD00");
    const form = e.detail; 
    this.dispatchEvent(new CustomEvent('form-submit', {
        bubbles: true, 
        composed: true,
        detail:{
          termsAndConditions: form.termsAndConditions,
          email: form.email,
          phone: form.phone,
          recaptchaToken: form.recaptchaToken
        }  
    })) 
    this.requestUpdate();

  }

  handleClosePopup() {
      this.showForm = false;
      this.style.setProperty('--color-botton', "#FFCD00");
      this.requestUpdate();
  }
  handleClick(){
    this.showForm = true;
    this.style.setProperty('--color-botton', "#FFCD00");
  }
  createRenderRoot() {
    return this;
  }


  render() {
    return html`
     <style>
       :root{
        --color-botton:#FFCD00;
        --value-color:#FFCD00;
      }
      .container-bt {
        position: fixed;
        background-color: var(--color-botton);
        color: var(--color-texto, #6c757d);
        height: 48px;
        border-radius: 10px 0 0 10px;
        text-align: center;
        line-height: 14px;
        cursor: pointer;
        z-index: 50000;
        right: 0;
        bottom: 90px;
        box-shadow: 0 0 5px 1px  #fff;
      }

      .container-bt__main {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .container-bt:hover {
        /* width: 170px;  */
        background-color: var(--value-color);
      }

      .container-bt__text {
        opacity: 0;
        max-width: 0;
        overflow: hidden;
        margin-left: 5px;
        transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out;
        p{
          font-weight:500;
        }
      }

      .container-bt:hover .container-bt__text {
        max-width: 150px; 
        opacity: 1;
        margin-left: 10px;
      }

      .container-bt__text p {
        margin: 0;
      }

      .container-bt__svg {
        flex-shrink: 0;
        padding-right:5;
        width: 46px; 
        height: 48px; 
        animation: shake 0.2s infinite;
      }
      .container-bt__svg img {
        width: 100%;
        height: 100%;
      }
      @keyframes shake {
        0%,
        100% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(10deg);
        }
        75% {
          transform: rotate(-10deg);
        }
      }
      </style>
    <div>
      ${this.showForm? html`<form-info
            valueColor=${this.ValueColor}
            @formSubmission="${this.handleAcceptClosePopup}" 
            @close-popup="${this.handleClosePopup}" 
            >
            
          </form-info>`:html``}
      <div class="container-bt">
        <div class="container-bt__main"
         @click="${this.handleClick}" 
         @mouseover="${this.handleMouseOver}">
          <div class="container-bt__text">
            <p>Recibe noticias</p>
            <p>de Juancho</p>
          </div>
          <div class="container-bt__svg">
            <img src=${NotificacionesSvg} alt="Notificaciones">
          </div>
        </div>
      </div>
      </div>
    `;
  }
}

customElements.define('button-form', ButtonForm);