import { LitElement, html} from 'lit';
import '../components/lite-captcha';

class FormInfo extends LitElement {
  static get properties() {
    return {
      valueColor: { type: String },
      termsAndConditions: { type: Boolean },
      email: { type: String },
      emailError: { type: String },
      phone: { type: String },
      phoneError: { type: String },
      recaptchaToken: { type: String },
      loading: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.termsAndConditions = false;
    this.email = '';
    this.emailError = '';
    this.phone = '';
    this.phoneError = '';
    this.recaptchaToken = '';
    this.loading = false;
  }
  createRenderRoot() {
    return this;
  }

  updated(changedProps) {
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--color-gender', this.valueColor);
    }
  }

  closePopup() {
    this.dispatchEvent(new CustomEvent('close-popup'));
  }

  acceptClosePopup() {
    let valid = true;
    if (!this.validateEmail()) valid = false;
    if (!this.validatePhone()) valid = false;
    if (!this.validateTermAndConditions()) valid = false;
    if (!this.recaptchaToken) {
      valid = false;
      alert('Por favor, completa el reCAPTCHA.');
    }
    if (valid) {
      this.dispatchEvent(new CustomEvent('formSubmission', {
        detail: {
          termsAndConditions: this.termsAndConditions,
          email: this.email,
          phone: this.phone,
          recaptchaToken: this.recaptchaToken
        }
      }));
    }
  }

  validateEmail() {
    this.emailError = '';
    if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.emailError = 'Correo electrónico no válido.';
      return false;
    }
    return true;
  }

  validatePhone() {
    this.phoneError = '';
    if (!this.phone.match(/^\d{10}$/)) {
      this.phoneError = 'Número de celular no válido.';
      return false;
    }
    return true;
  }

  validateTermAndConditions() {
    if (!this.termsAndConditions) {
      this.style.setProperty('--color-checkbox', '#ff0000');
      return false;
    }
    this.style.setProperty('--color-checkbox', '#6c757d');
    return true;
  }

  handleCheckboxChange(e) {
    this.termsAndConditions = e.target.checked;
    this.validateTermAndConditions();
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this[name] = value;
  }

  handleRecaptchaChange(e) {
    this.recaptchaToken = e.detail.value;
  }

  render() {
    return html`
    <style>
      :root {
      --light-blue-gray: #CED5F0;
      --light-gray: #ccc;
      --dark-gray: #333;
      --color-gender: #2F5BFF;
      --gray-color: #6c757d;
      --white-color: #ffffff;
      --error-color: #ff0000;
      --color-checkbox: #6c757d;
      display: inline-block;
    }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
    .popup-container__popup {
      position:relative;
      background-color: white;
      padding: 20px 30px 30px 30px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 40px;
      z-index: 1000;
      max-width: 450px;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      color:black;
    }
    .popup-container__title-bar {
      margin-top:20px;
      display:flex;
      flex-direction:column;
      align-items:center;
      margin-bottom: 10px;
      font-weight: 600;
      max-width:300px;
      p{
        text-align:center;
      }
    }
    .close-button {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      transition: background-color 0.3s ease;
    }
    .close-button:hover {
      background-color: var(--light-gray);
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 998; 
    }
    button {
      background-color: var(--light-blue-gray);
      border: none;
      border-radius: 20px;
      margin-top: 20px;
      height: 39px;
      transition: box-shadow 0.3s, transform 0.3s;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0 , 0.4);
      cursor: pointer;
      font-weight: 600;
      padding: 0 45px 0 45px;
      color: black;
      font-size:18px;
      background-color: var(--color-gender);
    }
    .input-container {
      width: 100%;
      margin-bottom: 10px;
    }
    .input-container label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .input-container input {
      display:inline-block;
      width: 95%;
      padding: 10px;
      border-radius: 10px;
      border: none;
      background-color:var(--light-blue-gray);
    }
    .error-message {
      color: var(--error-color);
      font-size: 0.9em;
      margin-top: 5px;
    }
    .checkbox-container {
      margin:8px 20px 11px 0;
    }

    .checkbox-container input[type="checkbox"] {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 2px solid var(--color-checkbox); 
      background-color: transparent;
      transition: background-color 0.3s, border-color 0.3s;
      position: relative;
      margin-right:8px;
    }

    .checkbox-container input[type="checkbox"]:checked {
      background-color: var(--gray-color); 
      border-color: var(--gray-color);
    }

    .checkbox-container input[type="checkbox"]:checked::before {
      content: '✔';
      color: var(--white-color); 
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight:700;
    }

    .popup-container__simbol---{
      background-color:var(--light-gray);
      width:80px;
      height:7px;
      margin-bottom:15px;
      cursor:pointer;
    }
    .popup-container__simbol--x{
      font-family:Arial;
      position:absolute;
      top:2px;
      right:2px;
      border-radius:50%;
      font-size:25px;
      width:25px;
      height:25px;
      margin:20px;
      color:var(--gray-color);
      cursor:pointer;
      p{
        margin:0;
        padding:0 0 1px 4px;
        line-height: 16px; 
      }
    }
    .checkbox-container__label{
      display:flex;
      align-items:flex-start;
    }
    .checkbox-container__div{
      margin-top:3px;
    }

    </style>
      <div class="popup-container">
        <div class="popup-container__popup">
          <div class="popup-container__simbol--x" @click="${this.closePopup}">
            <p>x</p>
          </div>
          <div class="popup-container__title-bar">
            <!-- <div class="popup-container__simbol---" @click="${this.closePopup}"></div> -->
            <p>Te enviaremos un correo con información de nuestros servicios</p>
          </div>
          <div class="input-container">
            <label for="email">Correo electrónico:</label>
            <input
              type="email"
              name="email"
              placeholder="juan@correo.com"
              .value="${this.email}"
              @input="${this.handleInputChange}"
              @blur="${this.validateEmail}"
            />
            ${this.emailError ? html`<div class="error-message">${this.emailError}</div>` : ''}
          </div>
          <div class="input-container">
            <label for="phone">Celular:</label>
            <input
              type="number"
              name="phone"
              placeholder="3214256709"
              .value="${this.phone}"
              @input="${this.handleInputChange}"
              @blur="${this.validatePhone}"
            />
            ${this.phoneError ? html`<div class="error-message">${this.phoneError}</div>` : ''}
          </div>
          <div class="checkbox-container">
            <label class="checkbox-container__label">
              <div class="checkbox-container__div">
                <input
                  type="checkbox"
                  name="termsAndConditions"
                  .checked=${this.termsAndConditions}
                  @change=${this.handleCheckboxChange}
                />
              </div>
              Acepto la política de Privacidad y Tratamiento de Datos Personales.
            </label>
          </div>
          <lite-captcha
            sitekey="6Le_iwgqAAAAADSe5c4B2ZHqOiyF7gzICEQh7Ll8"
            hl=""
            @change=${this.handleRecaptchaChange}
          ></lite-captcha>
          <button @click="${this.acceptClosePopup}">Enviar</button>
        </div>
        <div class="overlay"></div>
      </div>
    `;
  }
}

customElements.define('form-info', FormInfo);
