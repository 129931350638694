import { ServiceLoaded } from '../types/Service';
import { ApplicationResponse } from './changeLoanValuesGet';
import serviceBuilder from './serviceBuilder';

export const url = 'loans/application/:uuid';

const service = serviceBuilder<{ uuid: string },ApplicationResponse>('get', {
  query: true,
  url,
  auth: false,
});
export default async function(
  uuid: string
): Promise<ServiceLoaded<ApplicationResponse>> {
  try {
    const { payload, status } = await service({ uuid });
    if (payload) {
     
      return { payload, status };
    }
    throw new Error('Respuesta no recivida');
  } catch (e) {
    throw e;
  }
}
