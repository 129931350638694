import * as styledComponents from 'styled-components';
import theme from './theme';

export type Theme = typeof theme;

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<Theme>;

export { ThemeProvider, createGlobalStyle, css, keyframes };
export default styled;
