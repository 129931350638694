/**
 * Formats the given number.
 * @param {number} num The number to format.
 * @param {Object} formatter The formatter.
 * @param {string} formatter.locale The locale.
 * @param {Object} formatter.options The format options.
 * @returns {string} The formatted number.
 */
export const formatNumber = (num, formatter = { locale: 'es-CO' }) => {
  return new Intl.NumberFormat(formatter.locale, formatter.options).format(num);
};
export const toCamel = s => {
  return s.replace(/([-_][a-z])/ig, $1 => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};
