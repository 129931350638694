import { css } from 'lit';

export const creditSimulatorStyles = css`
:host {
  --color-gender:#2F5BFF;
  --light-gray:#ccc;
  --silver-color:#D9D9D9;
  --steel-gray:#6c757d;
  --semi-transparent-blue:#2F5BFF59;
  --light-blue-gray:#CED5F0;
  --dark-gray:#333;
  --color-dark-gray:#E5E5E5;
  --color-dark:#ACACAC;
  font: normal 14px/1.4 Helvetica, Poppins, sans-serif;
  --color-yellow:#FFCD00;
  --color-low-yellow:#DFA500;
  --color-high-yellow:#FFE332;

  --color-blue:#305CFF;
  --color-low-blue:#173FDD;
  --color-high-blue:#5181FF;

  --color-red:#FF2C00;
  --color-low-red:#C61800;
  --color-high-red:#FF6045;

  --color-gray:#616E81;
  --color-low-gray:#323F53;
  --color-high-gray:#848DA6;

  --color-purple:#885BFF;
  --color-low-purple:#6235CB;
  --color-high-purple:#AA83FF;

  --color-green:#45DC91;
  --color-low-green:#27AD68;
  --color-high-green:#7BFFBD;

  --color-orange:#F97134;
  --color-low-orange:#D25012;
  --color-high-orange:#FF9968;
}
range-slider {
  width: 350px;
  }

.credit-simulator {
  position:relative;
  z-index:10;
  background-color:white;
  font-family: Poppins, sans-serif;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  max-width: 420px;
  margin: 0 auto 10px auto;
  padding: 40px;
  border-radius: 55px;
  text-align:left;
}
.container-range-slider{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}
.container-title{
  display:flex;
  flex-direction:column;
  justify-content:left;
  margin:10px;
  align-self:flex-start;
  h3{
      width:100%;
      font-family: 'Poppins', sans-serif; 
      font-weight: 700; 
      font-size: 25px; 
      line-height: 37.5px; 
      margin-top: 0; 
      margin-bottom: 0.5rem;
  }
  h2{
      color:var(--color-gray);
      width:100%;
      font-family: 'Poppins', sans-serif; 
      font-weight: 700; 
      font-size: 25px; 
      line-height: 37.5px; 
      margin-top: 0; 
      margin-bottom: 0.5rem;
  }
}
.color-modifier{
  color:var(--color-gray);
}
.container-product{
  background-color: var(--color-gender);
  border-radius:15px;
  box-shadow: 0px 4px 8px var(--semi-transparent-blue);
  width:350px;
  margin-bottom:1rem;  
  h3 {
      color:white;
      margin:5px 10px;
  }
}
.text-init{  
max-width:280px;  
}

label {
  display: block;
  margin-bottom: 5px;
}

.slider-limits {
  display: flex;
  justify-content: space-between;
  width: 400px;
  color:var(--steel-gray);
  span{
    font-size:12px;
  }
}


#slider-custom-styles {
  --slider-height: 4px;
  --slider-background: var(--silver-color);
  --slider-value-color: var(--color-blue);
  --thumb-color: var(--color-blue);
  --thumb-diameter_x: 24px;
  --thumb-diameter_y: 24px;
}

.amount {
  font-weight: 600; 
  font-size: 27px; 
  line-height: 32.68px; 
  margin: 0 0 2px;
  color :var(--color-blue);
}
.amount-color{
  color :var(--color-low-purple);
}

.gender p {
  display:flex;
  align-items:center;
  justify-content:center;
}

.gender-options {
  text-align:center;
}

.gender-options button {
  cursor:pointer;
  width: 38px;
  height: 38px;
  border-radius: 11px;
  margin: 0 15px;
  border:0;
  font-size:27px;
  font-weight:700;
  color:white;
}
.btn-gender{
  background-color: var(--light-blue-gray);
}

.selected {
  background-color: var(--color-gender);
}

.btn-request {
  background-color: var(--color-yellow);
  border:none;
  border-radius: 22px;
  margin-top: 20px;
  height: 44px;
  transition: box-shadow 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0 , 0.4);
  cursor:pointer;
  font-weight: 700;
  padding: 0 20px;
  font-size:22px;

}
.btn-request:disabled {
  cursor:none;
  background-color: var(--light-gray);
}

/* .btn-request:hover {
  box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.8);
  transform: translateY(1px);
} */


select#mi-selector {
  width: 100%; /* Ancho completo */
  height:35px;
  padding: 5px 30px 5px 5px; /* Deja espacio para la flecha */
  background-color: var(--color-dark-gray); 
  border-radius: 7px; 
  font-size: 16px; 
  color: var(--dark-gray); 
  outline: none; 
  transition: border-color 0.3s, box-shadow 0.3s; 
  border: none;
  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none;  
}
.purpose-credit{
  position: relative;
  display: inline-block;
  margin-top:20px;
  width:calc(100% - 20px);
  color:var(--dark-gray);
  p{
      margin:0;
      font-weight:600;
  }
}
.custom-arrow {
  position: absolute;
  top: 18px;
  height:10px;
  right:36px;
  transition: all 0.5s ease;
  pointer-events: none;
}
.ButtonBackground{
  position: absolute;
  opacity: 0.1;
  outline-width: 0;
  border-width: 0;
  right: auto;
  width: 30px;
  height:20px;
  top: 15px;
}
.ButtonLine {
  position: absolute;
  width: 3px;
  height: 17px;
  background-color: var(--color-dark);
  border-radius:1.5px;
  transition: all 0.5s ease;
  top: 12px;
  &.top{
      left:5px;
  }
  &.bottom{
      left:16px;
  }
}
.arrow-down{
  &.top{
      transform: rotate(45deg);
      
  }
  &.bottom{
      transform:rotate(-45deg);
  }
}

.arrow-up {
  &.top{
      transform: rotate(-45deg);
  }
  &.bottom{
  transform: rotate(45deg);
  }
}

select#mi-selector option {
  padding: 10px; 
  background-color: var(--color-dark-gray); 
  color: var(--dark-gray); 
  border:none;
}

select#mi-selector option[value=""] {
color: var(--light-gray); 
background-color: var(--color-dark-gray); 
}

select#mi-selector:disabled {
  background-color: var(--color-dark-gray); 
  color: var(--steel-gray); 
  cursor: not-allowed; 
}
#mi-selector.error {
  border-color: red;
}

#mi-selector.normal {
  border-color: var(--light-gray);
}
.help-two{
  font-size:11px;
  font-weight: 400;  
  background-color:var(--steel-gray);
  border-radius: 50%;
  margin: 0 5px;
  padding: 1px 2.9px 1px 2.9px;
  color:white;
  line-height:10px; 
  cursor: pointer;
  }
.credi-simulate--p{
  text-align:center;
  font-weight: 400; 
  font-size: 13px; 
  line-height: 18px; 
  margin:0;
  padding:0;
}
.continer-footer{
  font-size:12px;
  color:var(--steel-gray);
  width:75%;
  text-align:center;
  p{
    margin:20px 0 0 0;
  }
}
@media (max-width: 550px){
  range-slider {
      width:260px; 
  }
  .slider-limits {
      width: 320px;
      
  }
  .credit-simulator {
      max-width: 340px;
      margin:30px auto 10px auto;
      border-radius:40px;
      padding: 25px;
  }
  .container-product{
      width:340px;   
  }
  .continer-footer{
    width:90%;
  }
}

  /* Otros estilos */
`;