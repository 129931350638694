import { LitElement, html } from 'lit';
// Importamos el componente custom-slider
import './body-info';
import '../components/modal-dialog';
import './range-slider';
import './selector-doble';
import './popup-request';
import '../jtd-agreement/jtd-agreement'
import '../components/button-form';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { formatNumber, toCamel } from '../utils/formatNumber';
import{calMaxAmountExternalSimulator,maxAmountGen,jtdSimInfo} from'../utils/simCalculations'
import {creditSimulatorStyles} from './styles/credit-simulator-styles.js'
class CreditSimulator extends LitElement {
    static styles = creditSimulatorStyles;
    static get properties() {
        return {
            term: { type: Number },
            interest: { type: Number },
            electronicSignature: { type: Number },
            guaranteeVariableMan: { type: Number },
            guaranteeVariableWoman: { type: Number },
            maxAmount: { type: Number },
            minAmount: { type: Number },
            incomeFactor: { type: Number },
            guaranteeConstant: { type: Number },
            guaranteeFurniture: { type: Number },
            gender: { type: String },
            disposableIncome: { type: Number },
            productType: { type: Number },
            internalSimulator:{type:Boolean},
            normalizeMaxAmount:{ type: Number },
            secureWellbeing:{type:Number},
            terms:{},
            product:{type:String},
            options: { type: Array },
            defaultOption: { type: String },
            urlGetProduct:{type:String},
            _guaranteeVariable:{type:Number},
            loanPurpose:{type: Array },
            firstDatePayment:{type:String},
            showPopupRequest: { type: Boolean },
            isButtonDisabled: { type: Boolean },
            simUserMsg:{type:String}
        };
    }

    constructor() {
        super();
        this.term = 0;
        this.interest = 0;
        this.electronicSignature = 0;
        this.guaranteeVariableMan = 1;
        this.guaranteeVariableWoman = 1;
        this.maxAmount = 0;
        this.minAmount = 0;
        this.incomeFactor = 0;
        this.guaranteeConstant = 0;
        this.guaranteeFurniture = 0;
        this.stateGender = 0;
        this.disposableIncome = 0;
        this.productType = 0;
        this.internalSimulator=false;
        this.normalizeMaxAmount=5000000;
        this.gender="Masculino";
        this.secureWellbeing=0;
        this.terms=[];
        this.urlGetProduct="";
        this.firstDatePayment="";
        this.showPopupRequest = false;
        this.simUserMsg ="";
        this.resultJtdSim =[]

        this._guaranteeVariable=0;
        this._objecMaxAmount={};
        this._terms=[];
        this._term=0;
        this.amount=0;
        this.product="JTD";
        this.messageProduct="Juancho Te Presta"
        this.defaultOption = '';
        this.loanPurpose=[]
        this.checkboxSecureWellbeing=true;
        this.checkboxSignature=true;
        this.checkboxAval=true;
        this.formData={
            secureWellbeing:true,
            signature:true,
            aval:true,
            loanPurpose: 0,
            amount:0,
            term:0,
            repaymentsStartingFromDate: ""
        };
        this.updateSlider = this.updateSlider.bind(this);
        this.isSmallScreen = window.innerWidth < 500;
        this.isButtonDisabled = false; 
    }
    connectedCallback() {
        super.connectedCallback();
        const dataItems = this.getAttribute('dataItems');
        if (dataItems) {
          this.loanPurpose = JSON.parse(dataItems);
        }
        window.addEventListener('resize', this.updateSlider);
        setTimeout(() => {
            this.initializeTooltips();
        }, 0);
      }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.updateSlider);
    }
    updateSlider() {
        const currentIsSmallScreen = window.innerWidth < 500;
        if (currentIsSmallScreen !== this.isSmallScreen) {
          this.isSmallScreen = currentIsSmallScreen;
          const slider = this.shadowRoot.querySelector('range-slider');
          if (slider) {
            slider.updateSlider();
          }
        }
      }
    firstUpdated(){
        const selector = this.shadowRoot.querySelector('#mi-selector');
        if (selector) {
            selector.addEventListener('change', () => {
                const errorMessage = this.shadowRoot.querySelector('#error-message');
                if (selector.value && selector.value !== this.defaultOption) {
                    errorMessage.style.display = 'none';
                    selector.classList.remove('error');
                    selector.classList.add('normal');
                }
            });
        }

        if(this.internalSimulator){
            if(this.gender==="Femenino"){
                this._guaranteeVariable= this.guaranteeVariableWoman
            }else{
                this._guaranteeVariable= this.guaranteeVariableMan
            }
            let iniTerms = this.terms.split(",")
            let maxTerm=iniTerms[iniTerms.length - 1]
            this.term=maxTerm
            iniTerms = iniTerms.map(str => parseInt(str, 10));
            const removeItemArray = (arr, item) => {
                let i = arr.indexOf(item);
                if (i !== -1) {
                  arr.splice(i, 1);
                }
                return arr;
              };
            const calMaxAmountInternalSimulator = maxAmountGen(
                this.interest,
                this.electronicSignature,
                this.disposableIncome,
                this._guaranteeVariable,
                this.maxAmount,
                this.incomeFactor,
                maxTerm,
            )
            const termsBase = [...iniTerms]
            const objecMaxAmount = iniTerms.reduce(
                (o, term) => ({ ...o, [term]: calMaxAmountInternalSimulator(term) }),
                {}
              );
              for (let k in objecMaxAmount) {
                if (
                  termsBase.indexOf(parseInt(k)) !== -1 &&
                  objecMaxAmount[k] <this.minAmount
                ) {
                  delete objecMaxAmount[k];
                  iniTerms = removeItemArray(iniTerms, parseInt(k));
                }
               
              }
              this._terms = iniTerms
              this._objecMaxAmount = objecMaxAmount
            
              console.log("this._objecMaxAmount",this._objecMaxAmount)
              this.normalizeMaxAmount=objecMaxAmount[maxTerm]
              this.amount=this.normalizeMaxAmount
              this.requestUpdate();

            if(this.product==="JTP"){
                this.productType=0
                this.messageProduct="Juancho Te Presta"
            }else if(this.product==="JTD"){
                this.messageProduct="Juancho Te Descuenta"
                this.productType=1

                if(this.simUserMsg==="jtdAcceptMsg"){
                    const resultJtdSimInfo = jtdSimInfo(
                        objecMaxAmount,
                        this.interest,
                        this._guaranteeVariable,   
                        this.electronicSignature,
                        this.checkboxSecureWellbeing,
                        this.checkboxSignature,
                        this.checkboxAval,
                        this.secureWellbeing,
                        this.product
                      )
                    console.log("resultJtdSimInfo ==>",resultJtdSimInfo)
                    this.resultJtdSim = resultJtdSimInfo
                } 
            }else{
                this.productType=0
                this.messageProduct="Juancho Te Presta"
            }
        }else{
            this.disposableIncome= this.maxAmount
            this.amount=this.maxAmount
            this._terms=[12,18,24]
            this.handleClickGender(1)
            
        }
    }
    


    updated(changedProps) {
        if(this.term===0&&this._terms){
            this.term=this._terms[this._terms.length - 1]
        }
        if (changedProps.has('gender')) {
            if(this.gender==="Femenino"){
                this._guaranteeVariable= this.guaranteeVariableWoman
            }else{
                this._guaranteeVariable= this.guaranteeVariableMan
            }
            this.requestUpdate();
          }
        if (changedProps.has('term')&&this.term>0) {
           if(this.internalSimulator){
            this.normalizeMaxAmount = this._objecMaxAmount[this.term]
           }else{
            this.normalizeMaxAmount = calMaxAmountExternalSimulator(
                this.term,
                this.disposableIncome,
                this.minAmount,
                this.maxAmount 
            )
           }
            if(this.amount>=this.normalizeMaxAmount){
              this.amount=this.normalizeMaxAmount
            }
            this.requestUpdate();
          }  
      }
    
    
    handleClickGender(buttonNumber) {
        if (buttonNumber === 1) {
            this.stateGender = 1;
            this.gender="Femenino"
        } else if (buttonNumber === 2) {
            this.stateGender = 2;
            this.gender="Masculino"
        } 
        const buttons = this.shadowRoot.querySelectorAll('.btn-gender');
        buttons.forEach(button => {
            button.classList.remove('selected');
        });
        buttons[buttonNumber - 1].classList.add('selected');
        this.requestUpdate();
    }
    initializeTooltips() {
    
        const tooltips = [
          { selector: '.gender-info', content: `<h3>Genero</h3> 
            <p>Queremos incentivar a las <strong>mujeres</strong> a cumplir 
            sus sueños, por esta razón, les ofrecemos una cuota mensual más baja.</p>` },
        ];
    
        tooltips.forEach(({ selector, content }) => {
          const elements = this.shadowRoot.querySelectorAll(selector);
          elements.forEach(el => {
            tippy(el, {
              content: content,
              animation: 'scale',
              allowHTML: true,
              theme: 'light',
              maxWidth: 300,
              onShow(instance) {
                const tippyBox = instance.popper.querySelector('.tippy-box');
                tippyBox.style.backgroundColor = 'rgba(240, 240, 240, 0.7)';
                tippyBox.style.backdropFilter = 'blur(5px)';
                tippyBox.style.borderRadius = '15px'; // Aumenta este valor para un borde más redondeado
            },
            });
          });
        });
      }
    handleDesitionAgreement(e){
        const desition = e.detail.desition; 
        this.dispatchEvent(new CustomEvent('desition', {
            bubbles: true, 
            composed: true,
            detail:{
                jtd_client_decision:desition,
                jtd_sim_info:this.resultJtdSim 
            }  
        }))  
    }  


    render() {
        return this.simUserMsg==="jtdAcceptMsg"?html`
        <jtd-agreement
          title="¡Tú empresa tiene un convenio con Juancho!"
          .isLoading=${false}
          @desition-agreement="${this.handleDesitionAgreement}" 
        >
        </jtd-agreement>
        ` : html`
            <div class="credit-simulator">
                <div class="container-title">
                    ${this.internalSimulator?html`
                        <h2 clase="color-modifier">Tu crédito</h2>
                        <div class="text-init">
                        <p class="color-modifier"> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis </p>
                        </div>
                    <div class="container-product" style="${this.productType === 1 
                        ? 'background-color:#6235CB' 
                        : 'background-color:#2F5BFF'}">
                        <h3>${this.messageProduct}</h3>
                    </div>
                    `:html`
                    <h3>Simula tu crédito para</h3>
                    <div class="text-init">
                    <p >Aquí texto para llevar al usuario a escoger uno de los productos - JTP o JTD</p>
                    </div>
                    
                    <selector-doble
                        id= "selector-custom"
                        @stateLoanChanged=${this._updateProduct}
                    ></selector-doble>
                    `}
                </div>
               
                <label for="amount-input">¿Cuánto dinero necesitas?</label>
                <p class="amount ${this.productType === 1 ? "amount-color":""}">$${formatNumber(this.amount)}</p>
                <div class="container-range-slider">
                    <range-slider
                        id="slider-custom-styles"
                        data-prop="amount"
                        .min=${this.minAmount}
                        .max=${this.normalizeMaxAmount}
                        .step=${50000}
                        .value=${this.amount}
                        sliderValueColor="${this.productType === 1 ? '#6235CB' : '#2F5BFF'}"
                        thumbColor="${this.productType === 1 ? '#6235CB' : '#2F5BFF'}"
                        @change=${this._updateValue}
                    ></range-slider>
                </div>
                <div class="slider-limits">
                    <span>$${formatNumber(this.minAmount)}</span>
                    <span>$${formatNumber(this.normalizeMaxAmount)}</span>
                </div>
                <p>Elige en cuántas cuotas quieres pagar</p>
                <body-info
                    id="body-info-styles"
                    ValueColor=${this.productType === 1 ? '#6235CB' : '#2F5BFF'}
                    stateTerm=${this.term}
                    @stateTermChanged=${this._updateTerm}
                    interest=${this.interest}
                    electronicSignature=${this.electronicSignature} 
                    disposableIncome=${this.disposableIncome}
                    guaranteeVariable=${this._guaranteeVariable} 
                    maxAmount=${this.normalizeMaxAmount} 
                    incomeFactor=${this.incomeFactor}
                    guaranteeConstant=${this.guaranteeConstant}
                    guaranteeFurniture=${this.guaranteeFurniture} 
                    amount=${this.amount}
                    secureWellbeing=${this.secureWellbeing}
                    .terms=${this._terms}
                    .objecMaxAmount=${this._objecMaxAmount}
                    .product=${this.product}
                    firstDatePayment=${this.firstDatePayment}
                    ._checkboxEndorsementState=${this.checkboxAval}
                    ._checkboxSignatureState=${this.checkboxSignature}
                    ._checkboxSafeState=${this.checkboxSecureWellbeing}
                ></body-info>
                ${this.internalSimulator===true?html`
                    <div class="purpose-credit">
                        <p>Motivo del préstamo:</p>
                        <select id="mi-selector" name="opciones">
                            ${this.defaultOption !== false ? html`
                                <option value="${this.defaultOption}">
                                <!-- Seleccione una opción -->
                                </option>
                            ` : ''}
                            ${this.loanPurpose !== null ? this.loanPurpose.map((option, index) => {
                                return html`
                                    <option value="${option.value}" key="${option.value}-${index}">
                                        ${option.label || option.value}
                                    </option>
                                `;
                            }) : ''}
                        </select>
                        <div class="custom-arrow">
                            <div class="ButtonBackground"></div>
                            <div class="ButtonLine top arrow-up "></div>
                            <div class="ButtonLine bottom arrow-up "></div>
                        </div>
                    </div>
                    <p id="error-message" style="color: red; display: none;">Debe seleccionar una opción.</p>
                    <button class="btn-request"
                     ?disabled="${this.isButtonDisabled}" 
                     @click="${this.handleButtonRequest}">¡Solicítalo ya!</button>
                `:html`
                <div class="gender">
                    <p>Selecciona tu género  <span class="gender-info help-two">?</span></p>
                    <div class="gender-options">
                        <button class="btn-gender" @click="${() => this.handleClickGender(1)}"> F</button>
                        <button class="btn-gender" @click="${() => this.handleClickGender(2)}"> M</button>
                    </div>
                </div>
                <button class="btn-request" @click="${this.handleButtonRequestExternalSimulator}">¡Solicítalo ya!</button>
                `}
                
                ${this.showPopupRequest ? html`
                <popup-request valueColor=${this.productType === 1 ? '#6235CB' : '#2F5BFF'} 
                    .secureWellbeing= "${this.checkboxSecureWellbeing}"
                    .signature = "${this.checkboxSignature}" 
                    .aval = "${this.checkboxAval}" 
                    product = "${this.product}"
                    @accept-close-popup="${this.handleAcceptClosePopup}" 
                    @close-popup="${this.handleClosePopup}">
                    <div slot="title">¿Estás seguro?</div>
                    <p class="credi-simulate--p">Si eliminas estos servicios tendrás que conseguirlos por tu cuenta.<br /><br />
                    </p><p class="credi-simulate--p">Puedes agregarlos de nuevo:</p>
                </popup-request>
                ` : ''}
                <div class="continer-footer">
                    <p>El valor de tu cuota se define según tu historial crediticio 
                        y capacidad de pago
                    </p>
                </div>
            
            </div>
        `;
    }
    handleButtonRequest() {
        this.isButtonDisabled = true;
        const bodyInfo = this.shadowRoot.querySelector('body-info');
        this.formData = bodyInfo.getFormData();

        const selector = this.shadowRoot.querySelector('#mi-selector');
        const selectedOption = selector ? selector.value : null;
        const errorMessage = this.shadowRoot.querySelector('#error-message');
        if (!selectedOption || selectedOption === this.defaultOption) {
            errorMessage.style.display = 'block';
            selector.classList.add('error');
            selector.classList.remove('normal');
            this.isButtonDisabled = false;
            return; 
        } else {
            errorMessage.style.display = 'none';
            selector.classList.remove('error');
            selector.classList.add('normal');
        }
        if (selectedOption) {
            this.formData.loanPurpose = selectedOption;
        }
       if(!this.formData.aval || !this.formData.signature || !this.formData.secureWellbeing){
        this.checkboxSecureWellbeing=this.formData.secureWellbeing;
        this.checkboxSignature=this.formData.signature;
        this.checkboxAval=this.formData.aval;
        this.showPopupRequest = true;
       }else{
        let date_format = this.formData.repaymentsStartingFromDate
        this.dispatchEvent(new CustomEvent('button-request', {
            bubbles: true, 
            composed: true,
            detail:{
               termsAndConditions: true,
               loanPurpose:this.formData.loanPurpose,
               amount:this.formData.amount,
               term:this.formData.term,
               repaymentsStartingFromDate: date_format,
               secureWellbeing:this.formData.secureWellbeing,
               signature:this.formData.signature,
               aval:this.formData.aval, 
           } 
           }));

       }
    }
    handleButtonRequestExternalSimulator() {
       console.log("redireccionamiento enviada")
       this.dispatchEvent(new CustomEvent('button-click', { bubbles: true, composed: true }));
    }

    handleAcceptClosePopup(e) {
        const event = e.detail;
        this.checkboxSecureWellbeing = event.secureWellbeing; 
        this.checkboxSignature = event.signature; 
        this.checkboxAval = event.aval; 
        this.showPopupRequest = false;
        let date_format = this.formData.repaymentsStartingFromDate
        console.log("date_format",date_format)
        this.dispatchEvent(new CustomEvent('button-request', {
             bubbles: true, 
             composed: true,
             detail:{
                termsAndConditions: true,
                loanPurpose:this.formData.loanPurpose,
                amount:this.formData.amount,
                term:this.formData.term,
                repaymentsStartingFromDate: date_format,
                secureWellbeing:event.secureWellbeing,
                signature:event.signature,
                aval:event.aval, 
            } 
            }));
        this.requestUpdate();

    }
    handleClosePopup(e) {
        const event = e.detail;
        this.checkboxSecureWellbeing = event.secureWellbeing; 
        this.checkboxSignature = event.signature; 
        this.checkboxAval = event.aval; 
        this.showPopupRequest = false;
        let date_format = this.formData.repaymentsStartingFromDate
        // date_format=date_format.format('DD-MM-YYYY')
        console.log("date_format",date_format)
        this.isButtonDisabled = false;
        this.requestUpdate();
    }

    _updateValue(e) {
        const event = e;
        const [element] = event.composedPath();
        const {prop } = element.dataset;
        const propToUpdate = `${toCamel(prop)}`;
        const value = parseInt(element.value);
        if(value){
            this[propToUpdate] = value;
            this.requestUpdate();
        }   
    }
    _updateTerm(e){
        this.term = e.detail; 
        this.requestUpdate();
    }

    _updateProduct(e) {
        this.productType = e.detail; 
        const url = `${this.urlGetProduct}/loans/sim`; 
        if(this.productType===1){
            this.style.setProperty('--color-gender', '#6235CB'); 
            const params= {"path":5}
            if (this.urlGetProduct) {
              this.fetchData(url,params);
            }
        }else if(this.productType===0){
            this.style.setProperty('--color-gender', '#2F5BFF');  
            const params= {"path":1}
            if (this.urlGetProduct) {
              this.fetchData(url,params);
            }
        }
        this.requestUpdate();
    }
    async fetchData(urlString, params){
        try{
            const url = new URL(urlString);
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
            const respons = await fetch(url);
            if(!respons.ok){
                throw new Error ('Error en la peticion: ')
            }
            const data = await respons.json();
            const getValue = (name) => {
                const component = data.components.find((comp) => comp.name === name);
                return component ? component.value : null;
              };
            const guaranteeVariableMan = getValue('guaranteeVariableMan');
            const guaranteeVariableWoman = getValue('guaranteeVariableWoman');
            this.guaranteeVariableWoman=guaranteeVariableWoman/100
            this.guaranteeVariableMan=guaranteeVariableMan/100
            this.gender==="Femenino"? this._guaranteeVariable= guaranteeVariableWoman/100
            : this._guaranteeVariable= guaranteeVariableMan/100
        }catch (error){
            console.log('Error fectching data:', error)
        }
    }
}

customElements.define('credit-simulator', CreditSimulator);
