import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { ModalA, useModalA } from '../../../components/UI/ModalApproved';
import basicInfoGet from '../../../services/basicInfoGet';
import buroGet, { BuroResponse } from '../../../services/buroGet';
import { LoanValues } from '../../../services/changeLoanValuesPost';
import riskMatrixGet from '../../../services/riskMatrixGet';
import { Option, Selectors, findOption } from '../../../services/selectorsGet';
import simulatorAuthGetPath from '../../../services/simulatorAuthGetPath';
import { JTPState } from '../../../store';
import {
  ResolveNextPath,
  resolveNextPath,
} from '../../../store/actions/navigation';
import {
  UpdateSimulatorStart,
  updateSimulatorStart,
} from '../../../store/actions/updateSimulator';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { changeLoanValues } from '../../../store/reducers/changeLoanValuesReducer';
import ConfirmLoanView from './ConfirmLoanView';
export interface Props extends RouteComponentProps {
  submit(params: LoanValues): Promise<void>;
  resolveNextPath: ResolveNextPath;
  loanPurpose: Option<string>[];
  mifos: Selectors;
  hydrateSimulator: UpdateSimulatorStart;
  loans: any;
  fullName:string
}

const ConfirmLoanContainer: React.FC<Props> = (props) => {
  const firstNameOne=props.fullName.split(' ')[0].toLowerCase()
  const fisrtNameLowerCase =firstNameOne.toLowerCase();
  const firstName =firstNameOne.charAt(0).toUpperCase()+fisrtNameLowerCase.slice(1)
  const messageWaiting = {
    main: (
      <div>
         <p>
          Tu empresa aún no ha gestionado la solicitud de tu préstamo por
          convenio de nómina. Inténtalo más tarde.
        </p> 
      </div>
    ),
    accept: 'ACEPTAR',
    noClosable: false,
  };
  const messageReject = {
    main: (
      <div>
         <p>
          !Lo sentimos! Tu empresa no aprobó la solicitud de préstamo por convenio de 
          nómina. En 90 días podrás volver a intentarlo.
        </p> 
      </div>
    ),
    accept: 'ACEPTAR',
    noClosable: false,
  };
    const messagejtdErrorMsg ={
      main: (
        <div>
            <p>
          Inicia sesión nuevamente para continuar tu solicitud o comunícate con
          nosotros a:
        </p>
        <p>
          <b>WhatsApp: </b>
          <a
            href="https://wa.me/5744807833"
            rel="noopener noreferrer"
            target="_blank"
            title="WhatsApp"
          >
            https://wa.me/5744807833
          </a>
        </p>
        <p>
          <b>Línea gratuita nacional: </b> 018000413811
        </p>
        <p>
          <b>Correo: </b> ayuda@juanchotepresta.com
        </p>
        </div>
      ),
      accept: 'ACEPTAR',
      noClosable: false,
    }
  const messageApproved={
    main: (
         <p>
          {firstName}, tu empresa aceptó la solicitud de crédito por convenio de nómina.
        </p>  
    ),
    cancel: 'IR AHORA',
    noClosable: false,
  }
  const [modalC, openModalC] = useModalC();
  const [modalA, openModalA] = useModalA();
  return (
    <>
    <ModalC props={modalC}/>
    <ModalA props={modalA}/>
    <Mount
      before={async () => {
        // await props.hydrateSimulator();
        
        const buro: BuroResponse = await buroGet();
         //const resultSim = (await simulatorAuthGetPath(5)).payload.components
        const resultSim = (await simulatorAuthGetPath(buro.finalPath)).payload.components
        //if (buro.running) throw new Error('Buro is not ready');
        if (buro.code__ === 201) throw new Error('Buro is not ready');
        
        const bi = (await basicInfoGet()).payload;
        const gender: string = findOption(props.mifos)(
          'genderId',
          bi.genderId
        ).label;
        const riskMatrix = await riskMatrixGet();
        if(buro.product==="JTD"){
          if(buro.simUserMsg==="jtdErrorMsg"){
            await openModalC(messagejtdErrorMsg).then(
              ({ cancel,accept}) => {
                (cancel || accept) && props.history.push('/logout');
              }
            )
          }
          if(buro.simUserMsg==="jtdWaitingMsg"){
            await openModalC(messageWaiting).then(
              ({ cancel, accept}) => {
                (cancel || accept) && props.history.push('/logout');
              }
            )
          }
          if(buro.simUserMsg==="jtdRejectMsg"){
            await openModalC(messageReject).then(
              ({ cancel,accept}) => {
                (cancel || accept) && props.history.push('/logout');
              }
            )
          }
          if(buro.simUserMsg==="jtdApprovedMsg"){
            await openModalA(messageApproved);
          }
        }
        return {
          resultSim,
          gender,
          risk: buro.risk,
          riskMatrix: riskMatrix[buro.risk],
          incomeFactor: buro.incomeFactor,
          finalPath: buro.finalPath,
          disposableIncome: bi.disposableIncome,
          mark: buro.mark,
          maxRiskQuota: buro.maxRiskQuota,
          product:buro.product,
          simUserMsg:buro.simUserMsg,
        };
      }}
      props={props}
      component={ConfirmLoanView}
    />
    
  </>);
};

export default connect(
  (state: JTPState, ownProps) => ({
    ...ownProps,
    mifos: state.mifos,
    loans: state.loans,
    fullName: state.user.user.fullName,
    gender: findOption(state.mifos)('genderId', state.user.personal.genderId)
      .label,
    loanPurpose: state.mifos.loanPurpose,
  }),
  (dispatch) => ({
    submit: asyncDispatch(dispatch, changeLoanValues.actions.request),
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
    hydrateSimulator: asyncDispatch(dispatch, updateSimulatorStart),
  })
)(ConfirmLoanContainer);
