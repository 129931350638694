import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { ButtonHTMLAttributes } from 'react';
import styled, { css, Theme } from '../../styles/styled-components';
import { Props as LinkProps } from './Link';
import { Spinner } from './Spinner';

interface ButtonProps {
  disabled?: boolean | undefined;
  loading?: boolean;
  inline?: boolean;
  expand?: boolean;
  color?: keyof Theme;
  outline?: boolean;
  sm?: boolean;
  flat?: boolean;
  [key: string]: any;
}

export const ButtonCSS = css<ButtonProps>`
  &,
  &:link,
  &:visited {
    font-weight: 700;
    font-size: ${(props) => (props.sm ? '1.2rem' : '1.4rem')};
    padding: ${(props) => (props.sm ? '1px 14px' : '1px 18px')};
    min-width: ${(props) => (props.expand ? '250px' : '0')};
    display: ${(props) => (props.inline ? 'inline-block' : 'block')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
    border-radius: 3.5rem;
    position: relative;
    transition: all 0.25s ease;
    margin: 5px;
    text-align: center;
    outline: none;

    background-color: #FFCD00; 
    border-width: 1px;
    border-style: solid;
    border-color: #FFCD00; 

    color: #1d1d1b; 

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:not(:disabled) {
      background-color: #FFCD00;
      border-color: #FFCD00;
      color: #1d1d1b;

      cursor: pointer;

      > * {
        cursor: pointer;
      }
    }
    .spinner {
      display: ${(props) => (props.loading ? 'block' : 'none')};
    }
    .children {
      visibility: ${(props) => (props.loading ? 'hidden' : 'visible')};
      display: flex;
      align-items: center;
      justify-content: center;
      .symbol.on-right {
        margin-left: 30px; // Espacio entre el texto y el símbolo
        font-family: Arial, sans-serif;
        font-size:2.5rem;
        font-weight: 400;
      }
    }
    :disabled {
      pointer-events: none;
      background-color: #FFCD00;
      border-color: #FFCD00;
      color: #1d1d1b99;
    }
  }
`;

const ButtonStyled = styled.button<ButtonProps>`
  ${ButtonCSS}
`;

export interface Props
  extends Partial<ButtonProps & ButtonHTMLAttributes<any>> {
  icon?: IconProp;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  to?: LinkProps['to'] | string;
  iconPosition?: 'left' | 'right';
  [prop: string]: any;
}

const Button: React.FC<Props> = ({
  as,
  children,
  color,
  sm,
  icon,
  className = '',
  loading,
  flat,
  ...restProps
}) => {
  return (
    <ButtonStyled
      as={as}
      className={`${className} ripple`}
      {...({
        color,
        sm: sm ? 'true' : undefined,
        loading: loading ? 'true' : undefined,
        flat: flat ? 'true' : undefined,
        ...restProps,
      } as any)}
    >
      <span className="children">
        {children}
        <span className="symbol on-right">{'>'}</span>
      </span>
      <Spinner className="spinner" size={28} />
    </ButtonStyled>
  );
};

export default Button;