/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Column from '../../../components/Grid/Column';
import '../../../components/lit/simulator-summary/simulator-summary';
import firstDatePayment from '../../../helpers/financial/firstDatePayment';
import { ComponentsGet, ValuesPach } from '../../../services/changeLoanValuesPost';
import loanSignaturePost from '../../../services/loanSignaturePost';
import { JTPState } from '../../../store';
import { ResolveNextPath } from '../../../store/actions/navigation';

export interface FormValues {
  loanPurpose: number;
}
interface ButtonRequestEvent extends Event {
    detail: {
      Endorsement?: boolean;
      signature?: boolean;
      secureWellbeing?:boolean;
      loanPurpose: string;
      amount: number;
      repaymentsStartingFromDate: string;
      term: number;
  };
}

export interface Props extends RouteComponentProps {
  genderName: string;
  submit(params: ValuesPach): Promise<void>;
  resolveNextPath: ResolveNextPath;
  daysCounter:number;
  product:string;
  mandatoryComponents:ComponentsGet[];
  optionalComponents:ComponentsGet[];
  amount: number;
  term:number;
  resultSim:ComponentsGet[]
}
interface SimulatorSummaryProps {
  ValueColor?: string;
  term: number;
  interest: number;
  electronicSignature: number;
  guaranteeVariable: number;
  guaranteeConstant: number;
  guaranteeFurniture: number;
  amount: number;
  secureWellbeing: any;
  product: string;
  genderName: string;
  firstDatePayment: string;
  _checkboxEndorsementState?: boolean;
  _checkboxSignatureState?: boolean;
  _checkboxSafeState?: boolean;
  existAddOptinalServices?:boolean;
  ref: React.RefObject<HTMLElement>;
}

const SummaryLoanView: React.FC<Props> = ({
  history,
  term,
  submit,
  genderName,
  mandatoryComponents,
  optionalComponents,
  product,
  amount,
  resultSim
}) => {
  const [valueFirstDatePayment, setFirstDatePayment] = useState('');
  const [dataReady, setDataReady] = useState(false);
  const getComponentValues = (name: string, components: Array<{ name: string; value: number; id: number }>) => {
    const component = components.find((comp) => comp.name === name);
    return component ? { value: component.value, id: component.id, name: component.name } : { value: 0, id: 0, name: "" };
  };
  const existAddOptinalServices = optionalComponents.some(component => component.statusId !== 104);
  const interest = getComponentValues('interest',mandatoryComponents);
  interest.value= interest.value/100
  const guaranteeFurniture = getComponentValues('guaranteeFurniture',mandatoryComponents);
  const guaranteeConstant = getComponentValues('guaranteeConstant',mandatoryComponents);
  guaranteeConstant.value= guaranteeConstant.value/100

  const existsElectronicSignature = optionalComponents.some(component => component.name === "electronicSignature");
  const typeGuaranteeVariable = genderName==="Femenino" ? 
      "guaranteeVariableWoman": "guaranteeVariableMan"
  const existsGuaranteeVariable = optionalComponents.some(component => component.name === typeGuaranteeVariable);


  const electronicSignature = existsElectronicSignature
    ? getComponentValues('electronicSignature',optionalComponents)
    : getComponentValues('electronicSignature',resultSim);
  
  const guaranteeVariable =  existsGuaranteeVariable
    ? getComponentValues(typeGuaranteeVariable,optionalComponents)
    : getComponentValues(typeGuaranteeVariable,resultSim);

  guaranteeVariable.value=existsGuaranteeVariable?guaranteeVariable.value/100:guaranteeVariable.value

  const internalsimulatorRef = useRef<HTMLElement>(null);

  const handleRequest = async (e:ButtonRequestEvent) => {
    // let date_format = await firstDatePayment();
    const _event = e.detail
    const optionalComponents=[]

    if((existsElectronicSignature!==_event.signature) || (existsGuaranteeVariable!==_event.Endorsement)){
      const response:ValuesPach ={}
      if(_event.repaymentsStartingFromDate!=='indefinido'){
        response.repaymentsStartingFromDate=_event.repaymentsStartingFromDate
      }
      if(_event.Endorsement){
        const Endorsement:ComponentsGet={
          name:guaranteeVariable.name,
          statusId:202,
          id:guaranteeVariable?.id,
          value:guaranteeVariable?.value===0?0:guaranteeVariable?.value*100
        }
        optionalComponents.push(Endorsement)
      }
      if(_event.signature){
        const signature:ComponentsGet={
          name:electronicSignature?.name,
          statusId:202,
          id:electronicSignature?.id,
          value:electronicSignature?.value
        }
        optionalComponents.push(signature)
      }
      response.optionalComponents=optionalComponents
      await submit(response);
    }
    await loanSignaturePost({
      url: window.location.origin + '/loans/predisburse',
      loanStatusId:202
    });
    history.push('/login');
  };
  useEffect(() => {
    const currentSimulator = internalsimulatorRef.current;
    if (currentSimulator) {
        const handleRequestWrapper = (evt: Event) => {
            if ((evt as ButtonRequestEvent).detail) {
                handleRequest(evt as ButtonRequestEvent);
            }
        };
        currentSimulator.addEventListener('button-request', handleRequestWrapper);
        return () => {
            currentSimulator.removeEventListener('button-request', handleRequestWrapper);
        };
    }
  }, [dataReady]);

  useEffect(() => {
    firstDatePayment().then((resp) => {
      setFirstDatePayment(resp.format('DD-MM-YYYY'));
    });
    setDataReady(true);
  }, []);
  const simulatorProps:SimulatorSummaryProps = {
    term,
    interest: interest.value,
    electronicSignature: electronicSignature.value,
    guaranteeVariable: guaranteeVariable.value,
    guaranteeConstant: guaranteeConstant.value,
    guaranteeFurniture: guaranteeFurniture.value,
    amount,
    secureWellbeing: null,
    product,
    genderName,
    firstDatePayment: valueFirstDatePayment,
    ref: internalsimulatorRef
  };
  if(existsElectronicSignature){
    simulatorProps._checkboxSignatureState=existsElectronicSignature
  }
  if(existsGuaranteeVariable){
    simulatorProps._checkboxEndorsementState=existsGuaranteeVariable
  }
  if(existAddOptinalServices){
    simulatorProps.existAddOptinalServices=existAddOptinalServices
  }

  return (
    <Column fluid>
      {dataReady && <simulator-summary {...simulatorProps} />}
    </Column>)
};

export default connect(
  (state: JTPState, ownProps: any) => ({
    signup: state.requests.signup,
    pixels: state.pixel.listPixel,
    ...ownProps,
  })
)(SummaryLoanView);