import { LitElement, html, css } from 'lit-element';

class SelectorDoble extends LitElement {
  

  static styles = css`
    :host {
      --color-low-purple:#6235CB;
      --color-cool-gray:#DFE1E5;
      --color-blue:#305CFF;
      display: inline-block;
    }
    .container{
      position:relative;
      display:flex;
      z-index: 30;
      height:60px;
    }
    .selector-container{
      width:300px;
      position:absolute;
      display:flex;
      z-index: 30;
    }
  
    .ButtonContainer{
      position: absolute;
      max-width: 20px;
      height:10px;
      right:36px;
      transition: all 0.5s ease;
    }
    .ButtonBackground{
      position: absolute;
      /* background-color:red; */
      opacity: 0.1;
      outline-width: 0;
      border-width: 0;
      right: auto;
      width: 30px;
      height:20px;
      z-index: 3030;
      top: 15px;
      cursor: pointer;
    }
    .ButtonLine {
      position: absolute;
      width: 2px;
      height: 19px;
      background-color: rgba(255, 255, 255, 0.5);
      transition: all 0.5s ease;
      top: 12px;
      &.top{
        left:5px;
      }
      &.bottom{
        left:18px;
      }
    }
    .arrow-down{
      &.top{
        transform: rotate(45deg);
        
      }
      &.bottom{
        transform:rotate(-45deg);
      }
    }

    .arrow-up {
      &.top{
        transform: rotate(-45deg);
      }
     &.bottom{
      transform: rotate(45deg);
     }
    }
    .selector-text{
      display:flex;
      overflow: hidden;
      border-radius:15px;
    }
   
    .selector-one{
      background-color:var(--color-blue);
      box-shadow: 0px 4px 8px #2F5BFF59;
      color:white;
      width:300px;
      cursor: pointer;
    }
    h2{
        margin:5px 15px;
      }
    p{
      margin:10px;
    }
    .selector-two{
      background-color:var(--color-low-purple);
      box-shadow: 0px 4px 8px #2F5BFF59;
      color:white;
      width:300px;
      cursor: pointer;
    }
  `;

static get properties() {
  return {
    stateLoan: { type: Number },
  };
}

   constructor() {
    super();
    this.arrowDirection = 'up';
    this.selectorOne = true; 
    this.selectorTwo = false; 
    this.stateLoan=0;
  }
  toggleArrowDirection() {
    this.arrowDirection = this.arrowDirection === 'down' ? 'up' : 'down';
    if(this.arrowDirection==='down'){
      this.selectorOne = true; 
      this.selectorTwo = true; 
    }
    if(this.arrowDirection==='up'){
      if(this.stateLoan===0){
        this.selectorOne = true; 
        this.selectorTwo = false; 
      }else{
        this.selectorTwo = true; 
        this.selectorOne = false; 
      }  
    }
    console.log("direccion",this.arrowDirection )
    this.requestUpdate();
  }
  toggleSelector(selector) {
    if(this.stateLoan !== selector){
      if(selector===0){
        this.selectorTwo = false;
        this.stateLoan=0; 
        this.arrowDirection="up"
      }
      if(selector===1){
        this.selectorOne = false; 
        this.stateLoan=1; 
        this.arrowDirection="up"
      }
      this.dispatchEvent(new CustomEvent('stateLoanChanged', { detail: this.stateLoan }))
      this.requestUpdate();
    }
    
  }

  render() {
    return html`
    <div class="container">
      <div class="selector-container">
        <div class="ButtonContainer" @click="${this.toggleArrowDirection}">
          <div class="ButtonBackground"  ></div>
          <div class="ButtonLine top ${this.arrowDirection === 'down' ? 'arrow-down' : 'arrow-up'}"></div>
          <div class="ButtonLine bottom ${this.arrowDirection === 'down' ? 'arrow-down' : 'arrow-up'}"></div>
        </div>
        <div class="selector-text" style="${this.stateLoan === 0 ? 'flex-direction:column;' : 'flex-direction:column-reverse;'}">
          <div class="selector-one"  style="${this.selectorOne === true ? 'display: block;' : 'display: none;'}" @click="${() => this.toggleSelector(0)}">
            <h2>Juancho Te Presta</h2>
            <p style="${this.arrowDirection === 'down'? 'display: block;' : 'display: none;'}">Si vas a solicitar un crédito con Juancho por tu cuenta.</p>
          </div>
          <div class="selector-two" style="${this.selectorTwo === true ? 'display: block;' : 'display: none;'}" @click="${() => this.toggleSelector(1)}">
            <h2>Juancho Te Descuenta</h2>
            <p style="${this.arrowDirection === 'down'? 'display: block;' : 'display: none;'}" >Crédito de nómina por la alianza con la empresa donde trabajas.</p>
          </div>
        </div>
      </div>
    </div>
    `;
  }
}

customElements.define('selector-doble', SelectorDoble);
