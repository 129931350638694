import { Formik } from 'formik';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Error from '../../../components/Form/Error';
import Form from '../../../components/Form/Form';
import FormError from '../../../components/Form/FormError';
import FormSubmitPro from '../../../components/Form/FormSubmitPro';
import InputNumber from '../../../components/Form/InputNumber';
import InputPassword from '../../../components/Form/InputPassword';
import ButtonContainerPro from '../../../components/UI/ButtonContainerPro';
import { submitTrap } from '../../../helpers/formHelpers';
import IconEmail from '../../../images/icon_cedula.png';
import IconLock from '../../../images/icon_pass.png';
import logo from '../../../images/img2_desk.png';
import { UserCredentials } from '../../../services/logInPost';
import styled from '../../../styles/styled-components';
import Omit from '../../../types/Omit';
import validationSchema from './ValidationSchema';

const ContentLogin = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-bottom: 30px;
color: ${props => props.theme.blueDark};
.red {
  color: red;
}
`;

const Navlink = styled(NavLink)`
  color: ${(props) => props.theme.red};
  background:red;
`;

const Span = styled.span`
  font-weight:600;
`;

const LoginForm = styled.div`
margin-top:2rem;
  display:flex;
  flex-direction:column;
  align-flex:center;
  justify-content:center;
`;

const LoginButtonGroup = styled.div`
  text-align: center;
`;
const ForgotPassword = styled.div`
  color: black;
  font-weight:600;
  margin: 24px auto 30px auto;
  max-width:210px;
  text-align: center;
  background-color:${props => props.theme.gray100};
  border-radius:1rem;
  padding:0.6rem;
`;
const ContinerNavLink = styled.div`
  margin: 24px auto 30px auto;
  max-width:210px;
  text-align: center;
  border-radius:1rem;
`
  

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2px;
`;
const ContainerInput = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  color: ${props => props.theme.blueDark};
`
const Continerheader = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
` 
const Title = styled.h1`
  margin: 1.8rem 0.8rem 0.8rem;
  font-size:2rem;
  font-weight:800;
  color:black;
  @media (min-width: 700px) {
    margin: 2.5rem 0.8rem 0.8rem;
    font-size:2.5rem;
  }
`
const Description = styled.p`
  margin: 0;
  max-width:210px;
  text-align:center;
`
const Img = styled.img`
  display: block;
  margin:2rem 0 1rem 0;
  height:100px;
  @media (min-width: 700px) {
    margin:2rem 0 0 0;
  }
`;
const IconInputBig = styled.img`
  position:absolute;
  top:6px;
  right:8px;
  width:17px;
  margin:6px;
`
export interface FormValues extends Omit<UserCredentials, 'terminalUuid'> {}

interface Props {
  initialValues: UserCredentials;
  submit(values: UserCredentials): Promise<void>;
}

const LogInView: React.FC<Props> = ({ initialValues, submit }) => {
  return (
    <LoginContainer>
      
      <Continerheader>
        <Title>
          Iniciar sesión
        </Title>
        <Description>
          <Span>Gestiona tus pagos</Span> y toda tu información desde aquí
        </Description>
        <Img src={logo} alt="Juancho te presta" />
      </Continerheader>
      {/* <Hero
        title="Inicia sesión en tu cuenta"
        info="Controla todo sobre tu cuenta y
       gestiona tus pagos desde aquí."
      /> */}
      <LoginForm>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          onSubmit={submitTrap(async (values, _, setFormError) => {
            try {
              await submit({
                id: values.id.replace(/\s/g, ''),
                password: values.password,
              });
            } catch (e) {
              setFormError(
                <p>
                  Cédula y/o contraseña incorrecta. Vuelva a intentarlo o haz
                  clic en Recuperar contraseña
                </p>
              );
            }
          })}
        >
          <Form>
            <ContentLogin>
              <ContainerInput>
                <InputNumber
                  name="id"
                  delimiter=" "
                  maxLength={17}
                  placeholder='Tu Cédula'
                />
                 <IconInputBig src={IconEmail} alt="icono de correo"/>
              </ContainerInput>
              <ContainerInput>
                <InputPassword name="password" icon="500px" placeholder='Tu contraseña' />
                <Error name="password" />
                <IconInputBig src={IconLock} alt="Icono de candado"/>
              </ContainerInput>
              <FormError />
            </ContentLogin>
            <LoginButtonGroup>
              <ButtonContainerPro>
                <FormSubmitPro>Entrar</FormSubmitPro>
              </ButtonContainerPro>
            </LoginButtonGroup>
          </Form>
        </Formik>
      </LoginForm>
        <ContinerNavLink>
          <Navlink to="/login/recovery">
           <ForgotPassword>
            Olvidé mi contraseña  
           </ForgotPassword>
          </Navlink>
        </ContinerNavLink> 
    </LoginContainer>
  );
};

export default LogInView;
