/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';
import ErrorFocus from '../../../components/Form/ErrorFocus';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormError from '../../../components/Form/FormError';
import FormGroup from '../../../components/Form/FormGroup';
import InputAccountNumber from '../../../components/Form/InputAccountNumber';
import InputAccountPhone from '../../../components/Form/InputAccountPhone';
import InputSelect from '../../../components/Form/InputSelect';
import InputSquare from '../../../components/Form/InputSquare';
import Label from '../../../components/Form/Label';
import Column from '../../../components/Grid/Column';
import { Flex } from '../../../components/Grid/Flex';
import { RejectMessageNinetyDays } from '../../../components/Router/PrivateRoute';
import Button from '../../../components/UI/Button';
import ButtonArrow from '../../../components/UI/ButtonArrow';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import ButtonGeneric from '../../../components/UI/ButtonGeneric';
import ButtonGroup from '../../../components/UI/ButtonGroup';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ContentMessages, MessagesFormat } from '../../../components/UI/MessagesViews';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { ModalSendEmail, useModalSendEmail } from '../../../components/UI/ModalSendEmail';
import { SpinnerPro } from '../../../components/UI/SpinnerPro';
import {
  Alert,
  Alert2,
  ContentAval,
  ContentDays,
  DocumentList,
  MainContent,
  Message,
  PrincipleMessage,
  Resend,
  SubMessage,
  SubMessage2,
  TextInfo,
  Title,
  TitleSend,
  TitleSend2,
  WatchFlash
} from '../../../components/UI/StylesOptionality';
import AvalAdvice from '../../../documents/AvalAdvice';
import AvalAndPlatformAdvice from '../../../documents/AvalAndPlatformAdvice';
import PlatformAdvice from '../../../documents/PlatformAdvice';
import noop from '../../../helpers/noop';
import alert from '../../../images/icons/1_Icono-alerta.svg';
import watchFlash from '../../../images/icons/2_Icono-relojflash.svg';
import bankInformationPost from '../../../services/bankInformationPost';
import { ComponentsGet, ValuesPach } from '../../../services/changeLoanValuesPost';
import loanSignaturePost from '../../../services/loanSignaturePost';
import { Repayment } from '../../../services/repaymentGet';
import { Option, OptionNumber } from '../../../services/selectorsGet';
import { ResolveNextPath } from '../../../store/actions/navigation';

const InfoText = styled.div`
   margin-top:10px;
   font-size: 12px;
   color:${(props) => props.theme.gray};
`
const AccountInitialValues = {
  bank: '' as any,
  account: '' as any,
  accountEndsWith: '',
  accountType: '' as any,
  automaticDebit: true,
  wallet:false,
  disbursementMethodId: 0,
};


const InitialValues = {
  bank: '' as any,
  account: '',
  accountEndsWith: '',
  accountType: '' as any,
  automaticDebit: true,
  disbursementMethodId: 1409,
  email: '',
  fullName: '',
  wallet:false
};


type FormData = typeof InitialValues;

const formDataReducer = (state: FormData, action: any): FormData => {
  return {
    ...state,
    ...action,
  };
};
export interface ErrorWithEmail {
  errors: {
    email: string;
  };
}

type Advice = 'aval' | 'platform' | 'avalAndPlatform';

const ErrorPhone422 = {
  main: <p>Te queda <strong>1 </strong>intento para ingresar correctamente tu 
  número de celular. De lo contrario, tu solicitud será rechazada.
  <strong>O si prefieres, elige otra entidad bancaria.</strong></p>,
  accept: 'Aceptar',
};

export const ErrorMessageConfig = (msj: string) => ({
  main: <p>{msj}</p>,
  accept: 'Aceptar',
});

export const FinishMessage = (title: string) => ({
  title:(
  <h3>{title}</h3>
  ),
  main: (
    <p>
      <strong>Pendiente de tu correo,</strong> te llegaron todos los contratos 
      para que los firmes de manera electrónica. 
    </p>
  ),
  accept: 'Aceptar',
});

export interface Props extends RouteComponentProps {
  allBanks: Option[];
  accountTypes: Option[];
  bankAccounts: Option[];
  walletAccounts:Option[];
  fullName: string;
  email: string;
  amount: number;
  loanStatus: string;
  term: number;
  cellPhone:string;
  repayment: Repayment;
  eventTypeEnabled: OptionNumber[];
  daysCounter:number;
  existsElectronicSignature:boolean;
  existsGuaranteeVariable:boolean;
  submit(params: ValuesPach): Promise<void>;
  resolveNextPath: ResolveNextPath;
  optionalComponents:ComponentsGet[];
  walletBanks:Option[]
}

const accountSchema = object({
  disbursementMethodId: string().required('Campo requerido'),
  bank: string().required('Seleccione un banco'),
  accountEndsWith: string().required('Seleccione un número de celular válido'),
  account: string()
    .required('Requerido')
    .test('ends-with', 'error', function (value) {
      const { accountEndsWith } = this.parent;
      if (!accountEndsWith) {
        return this.createError({
          path: 'accountEndsWith',
          message: 'Selecciona una cuenta',
        });
      }
      if (accountEndsWith * 1 === -1) {
        return true;
      }

      if (
        !new RegExp(`.*${accountEndsWith.slice(-4)}$`).test(value)
      ) {
        return this.createError({
          message: (
            <span>
              La cuenta debe finalizar en: <b>{accountEndsWith.slice(-4)}</b>
            </span>
          ) as any,
        });
      }
      return true;
    }),
  
  accountType: string().required('Requerido'),
});
const accountSchemaWallet = object({
  bank: string().required('Seleccione un banco'),
  accountEndsWith: string().required('Seleccione una cuenta válida'),
  account: string()
    .required('Requerido')
    .test('is-ten-digits', 'Número de telefono no valido', function (value) {
      if (value && value.length !== 10) {
        return false;
      }
      return true;
    })
    .test('ends-with', 'error', function (value) {
      const { accountEndsWith } = this.parent;
      if (!accountEndsWith) {
        return this.createError({
          path: 'accountEndsWith',
          message: 'Selecciona una cuenta',
        });
      }
      if (accountEndsWith * 1 === -1) {
        return true;
      }

      if (
        !new RegExp(`.*${accountEndsWith.slice(-4)}$`).test(value)
      ) {
        return this.createError({
          message: (
            <span>
              El celular debe finalizar en: <b>{accountEndsWith.slice(-4)}</b>
            </span>
          ) as any,
        });
      }
      return true;
    }),
});

const SignatureView: React.FC<Props> = ({
  history,
  accountTypes,
  allBanks,
  walletBanks,
  walletAccounts,
  bankAccounts,
  email,
  cellPhone,
  fullName,
  eventTypeEnabled,
  loanStatus,
  daysCounter,
  existsElectronicSignature,
  existsGuaranteeVariable,
  submit,
  optionalComponents
}) => {
  const [formData, updateFormData] = React.useReducer(
    formDataReducer,
    InitialValues
  );

  const [modal, openModal] = useModalC();
  const [modalSend, openModalSend] = useModalSendEmail();
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showPreDisburse, setShowPreDisburse] = useState(false);
  const [wallet, setWallet] = React.useState<boolean>(false);
  const [submitSend, setSubmitSend] = React.useState<boolean>(false);
  const [bankSelected, setBankSelected] = React.useState<boolean>(false);
  const [bankEmpty, setBankEmpty] = React.useState<boolean>(false);
  const [showAdvice, setAdvice] = useState<Advice>();
  const [addServices, setAddServices] = useState(false)
  const [emailUpdated, setEmailUpdated]=useState("")
  const accountFormRef = React.useRef<Formik<any>>({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [stateLoanStatusId,setStateLoanStatusId] = useState(0);

  function paramsBank(code:any){
    const selectedBank = walletBanks.find(bank => bank.value === Number(code));
    if(!wallet){
      if(selectedBank){
        setBankSelected(false)
      }else{
        setBankSelected(true)
      }
      if(code){
        setBankEmpty(true)
      }else{
        setBankEmpty(false)
      }
    }
    if (accountFormRef.current) {
      accountFormRef.current.setFieldValue('bank', '', false);
      accountFormRef.current.setFieldValue('accountEndsWith', '', false);
      accountFormRef.current.setFieldValue('account', '', false);
      accountFormRef.current.setFieldValue('accountType', '', false);
      accountFormRef.current.setTouched({});
    }
  }
    useEffect(() => {
    if(bankAccounts.length===0){
      setWallet(true)
      setBankEmpty(true)
    }
    setEmailUpdated(email)
    if (loanStatus === 'Approved and awaiting signature') {
      setShowConfirmEmail(true);
      setStateLoanStatusId(201)
    }
    else if (loanStatus === 'Approved pre-disburse') {
      setShowPreDisburse(true);
      setStateLoanStatusId(203)
    }
  }, [email,loanStatus]);

  useEffect(() => {
    accountFormRef.current.setFieldValue('disbursementMethodId', eventTypeEnabled[0]?.value, true);
  }, [eventTypeEnabled]);

  const handleClickAddServices= async()=>{
    setAddServices(true)
    const response:ValuesPach={
      optionalComponents:optionalComponents,
    }
    await submit(response);
    history.push('/login');
  }
  const submitFirm = React.useCallback(async () => {
    try {
      await accountFormRef.current.submitForm();
    } catch {
      /** ignore error */
    }
    const { account} = formData; 
    console.log("formData==>",formData)

     if (!formData.disbursementMethodId) {
      return openModal(ErrorMessageConfig('Por favor selecciona un método de desembolso.'));
    }
    const isValid = (form: Formik) => {
      
      return Object.values(form.getFormikBag().errors).length === 0;
    };
    if (!isValid(accountFormRef.current)) {
      return;
    }
    if (loanStatus !== 'Approved and awaiting signature') {
      console.log("datos del formulario==>",account)
      setSubmitSend(true)
      console.log("walletBanks==>",walletBanks)
      console.log("account.bank==>",formData.bank)
      const selectedBank = walletBanks.find(bank => bank.value === Number(formData.bank));
      console.log("analiza estos datos",selectedBank)
      const resultWallet = selectedBank? true:false
      formData.account = formData.account.replace(/\s+/g, '');
      if(resultWallet){
        formData.accountType = accountTypes[0].value;
      }else{
        formData.accountType *= 1;
      }
      formData.bank *= 1;
      formData.automaticDebit = true;
      formData.wallet =  resultWallet;
      const result = await bankInformationPost(formData);
      console.log("resultado del envio de la informacion bancaria",result)
      if (result.status==="error") {

        if(result.error.code===422){
          await openModal(ErrorPhone422);
        }
        if(result.error.code===406){
          await openModal(RejectMessageNinetyDays);
          history.push('/logout');
        }
        if(resultWallet){
          formData.accountType = 0;
        }  
        setSubmitSend(false)
        // accountFormRef.current.setFieldError("accountEndsWith", "Verifica la cuenta selectionada");
        // accountFormRef.current.setFieldError("account", "Error al validar la cuenta bancaria");
        return 
      }
    }
  
    // if (!formData.disbursementMethodId) {
    //     return setShowMethod(true);
    // }
    setShowConfirmEmail(true);
  }, [formData, accountFormRef]);
  const handleClickResend  = useCallback(async () => {
    setIsLoading(true); 
    try {
      await loanSignaturePost({
            url: window.location.origin + '/loans/predisburse',
            loanStatusId:stateLoanStatusId
          });
    } catch (e) {
      const error = e as ErrorWithEmail;
      await openModal(ErrorMessageConfig(error.errors.email));
      return;
    }
    await openModalSend(FinishMessage("¡Genial!"));
    setIsLoading(false);
    // history.push('/logout');
  }, [stateLoanStatusId, history]);

  if (showAdvice) {
    return (
      <Column>
        <GeneralContent>
          <h2>Firmar tu contrato</h2>
          <br />
          {showAdvice === 'aval' && <AvalAdvice username={fullName} />}
          {showAdvice === 'platform' && <PlatformAdvice username={fullName} />}
          {showAdvice === 'avalAndPlatform' && (
            <AvalAndPlatformAdvice username={fullName} />
          )}
          <Flex row justify="space-around">
            <ButtonContainer>
              <Button onClick={() => setAdvice(undefined)}>Regresar</Button>
              <Button
                onClick={() => {
                  setShowConfirmEmail(true);
                  setAdvice(undefined);
                }}
              >
                Continuar
              </Button>
            </ButtonContainer>
          </Flex>
        </GeneralContent>
      </Column>
    );
  }
  if (showConfirmEmail || showPreDisburse) {
    return (
      <>
      <Column>
        <GeneralContent>
          <ModalSendEmail props={modalSend} />
        {/*contrata todo vista firma electronica*/}
        {existsElectronicSignature&& existsGuaranteeVariable && showConfirmEmail && <>
            <Alert src={alert} alt='icono  de alerta'/>
            <TitleSend>!Revisa tu correo!</TitleSend>
            <PrincipleMessage>
                Te enviamos un correo electrónico a la dirección 
                <strong>{` ${emailUpdated} `}</strong> con un enlace para que firmes 
                electrónicamente los siguientes documentos:
            </PrincipleMessage>
          
            <MainContent>
                <DocumentList>
                      <li>Garantía mobiliaria</li>
                      <li>Acuerdo económico</li>
                      <li>Poder económico</li>
                      <li>Débito automático</li>
                      <li>Contrato garantía crediticia</li>
                      <li>Privacidad compañía avaladora</li>
                </DocumentList>
            <Message>
              <p>Una vez que firmes los documentos, iniciaremos las
                validaciones finales para realizar el desembolso.
              </p>
              <SubMessage>
                <WatchFlash src={watchFlash} alt="reloj flash" />
                <p>¡Recibirás tu dinero en un plazo máximo de 24 horas!</p>
              </SubMessage>
            </Message>
            </MainContent>
            </>
        } 
        {/*contrata todo vista predesembolso*/}
        {existsElectronicSignature&& existsGuaranteeVariable && showPreDisburse && 
          <ContentMessages>
            <MessagesFormat>
              <p>
                <strong>Firmaste tus documentos exitosamente, </strong>
                vamos a revisar que todo esté correcto. En caso de necesitar información
                adicional te contactaremos.
              </p>
              <p>Si todo está bien desembolsaremos tu crédito pronto.</p>
              <ButtonGeneric  textcolor="black" as={NavLink}  to="/logout">
                Salir ahora
              </ButtonGeneric>
            </MessagesFormat>
          </ContentMessages>
          
        } 
        {/*no contrata nada*/}
        {(!existsElectronicSignature || !existsGuaranteeVariable) && <>
            <TitleSend2> !Tu solicitud quedará en pausa!</TitleSend2>
            <ContentDays>
              <Alert2 src={alert} alt='icono  de alerta'/>
              {daysCounter === 1 ?<p>Tienes {daysCounter} día calendario</p>:
              <p>Tienes {daysCounter} días calendario</p>}
            </ContentDays>
            </>
        }
        {/* contrata aval pero no firma */}
        {!existsElectronicSignature&& existsGuaranteeVariable && <>
            <PrincipleMessage>
                Te enviamos un correo electrónico a 
                <strong>{` ${emailUpdated} `}</strong> con las instrucciones
                para gestionar la firma por tu cuenta, ya que no has contratado
                nuestro servicio: <label> Firma electrónica</label>
            </PrincipleMessage>
            <PrincipleMessage> Estos con los contratos que necesitas firmar físicamente
              para recibir tu crédito:
            </PrincipleMessage>
            <MainContent>
                <DocumentList>
                      <li>Garantía mobiliaria</li>
                      <li>Acuerdo económico</li>
                      <li>Poder económico</li>
                      <li>Débito automático</li>
                      <li>Contrato garantía crediticia</li>
                      <li>Privacidad compañía avaladora</li>
                </DocumentList>
            </MainContent>
            </>
        }
        {/* contrata firma pero no aval vista firma electronica*/}
        {existsElectronicSignature&& !existsGuaranteeVariable && showConfirmEmail &&  <>
            <PrincipleMessage>
                Te enviamos <strong>dos</strong> correos electrónicos a 
                <strong>{` ${emailUpdated} `}</strong> Revisa cuidadosamente 
                lo que debes hacer, ya que no estás contratando el servicio: <label>Aval.</label>
            </PrincipleMessage>
            <PrincipleMessage> <strong>1.</strong> Abre el correo "Link para firmar tus contratos"
            y haz clic en el enlace para firmar los siguientes contratos:
            </PrincipleMessage>
            <MainContent>
                <DocumentList>
                      <li>Garantía mobiliaria</li>
                      <li>Acuerdo económico</li>
                      <li>Poder económico</li>
                      <li>Débito automático</li>
                </DocumentList>
            </MainContent>
            <PrincipleMessage> <strong>2.</strong> Abre el correo llamado "Contratación de aval"
            y sigue las instrucciones para contratar el aval por tu cuenta.
            </PrincipleMessage>
            </>
        }  
         {/* contrata firma pero no aval vista predesembolso*/}
           {existsElectronicSignature&& !existsGuaranteeVariable && showPreDisburse &&  <>
            <Alert src={alert} alt='icono  de alerta'/>
            <TitleSend>{daysCounter === 1 ?<>¡Te queda {daysCounter} día!</>:
              <>¡Te quedan {daysCounter} días!</>}</TitleSend>
            <PrincipleMessage>
                Estamos esperando que gestiones el aval por tu cuenta,
                ya que decidiste no contratar nuestro servicio:
            </PrincipleMessage>
            <ContentAval>
                <h3>Aval</h3>
                <p>Debes buscar un codeudor</p>
            </ContentAval>
            <PrincipleMessage> Busca el correo llamado "Contratación de Aval" y
              sigue las instrucciones.
            </PrincipleMessage>
            </>
        }  
        
        {/* no contrata ni firma ni aval*/ }
        {!existsElectronicSignature&& !existsGuaranteeVariable && <>
            <PrincipleMessage>
                Te enviamos <strong>dos</strong> correos electrónicos a 
                <strong>{` ${emailUpdated} `}</strong> Revisa cuidadosamente 
                lo que debes hacer, ya que no estás contratando los servicios:
                <label> Firma electrónica y Aval.</label>
            </PrincipleMessage>
            <PrincipleMessage> <strong>1.</strong> Estos con los contratos que necesitas firmar físicamente
              para recibir tu crédito:
            </PrincipleMessage>
            <MainContent>
                <DocumentList>
                      <li>Garantía mobiliaria</li>
                      <li>Acuerdo económico</li>
                      <li>Poder económico</li>
                      <li>Débito automático</li>
                </DocumentList>
            </MainContent>
            <PrincipleMessage> <strong>2.</strong> Abre el correo llamado "Contratación de aval"
            y sigue las instrucciones para contratar el aval por tu cuenta.
            </PrincipleMessage>
            </>
        }  
        {(!showPreDisburse ||(existsElectronicSignature && !existsGuaranteeVariable))  &&
          <Resend>
            {isLoading ? (<>
            <p>¿No encuentras el correo? </p> <SpinnerPro size={44} /> </>
          ) : (<p>¿No encuentras el correo? <strong onClick={handleClickResend }>Reenvíalo.</strong></p>)}
            
        </Resend>
        }
          {(!existsElectronicSignature || !existsGuaranteeVariable) && <>
            <SubMessage2>
               <WatchFlash src={watchFlash} alt="reloj flash" />
              <p>Agrega los servicios opcionales para que te 
                desembolsemos en 24 horas
              </p>
            </SubMessage2>
            <ButtonGeneric
               margin={true}
               loading={addServices}
               onClick={handleClickAddServices}
               textcolor="black">¡Agregar ahora!</ButtonGeneric>
           </>
          } 
        </GeneralContent>
      </Column>
    </>
    );
  }
  return (
    <Column>
    <ModalC props={modal} />
      <GeneralContent>
        <ModalC props={modal} />
        <Title>¿Cómo deseas recibir tu crédito?</Title>

        <Column>
          <Formik
            ref={accountFormRef}
            initialValues={AccountInitialValues}
            validateOnChange
            validationSchema={bankSelected? accountSchema:accountSchemaWallet}
            onSubmit={noop}
          >
            <Form>
              <GeneralContent>
                <FormChange onChange={(value) => updateFormData(value)} />
                <FormError></FormError>
                <FormGroup>
                  {eventTypeEnabled.map((item: OptionNumber) => (
                    
                    <div key={item.value}>
                      <InputSquare
                        name="disbursementMethodId"
                        value={item.value}
                        label={item.label}
                        description={item.description}
                      />
                    </div>
                  ))}

                <TextInfo>Lorem ipsum dolor sit amet consectetur adipisicing 
                  elit. Rerum suscipit ex illo facilis quas quisquam explicabo eius </TextInfo>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Elige el banco:
                  </Label>
                  {wallet? <FormGroup>
                    <InputSelect
                      name="bank"
                      placeholder="Seleccione un Banco"
                      options={walletBanks
                        .slice() 
                        .sort((a, b) => a.label.localeCompare(b.label)) 
                      }
                      onChange={(code)=>{paramsBank(code)}}
                    />
                  </FormGroup>: <FormGroup>
                    <InputSelect
                      name="bank"
                      placeholder="Seleccione un Banco"
                      options={allBanks
                        .slice() 
                        .sort((a, b) => a.label.localeCompare(b.label)) 
                      }
                      onChange={(code)=>{paramsBank(code)}}
                    />
                  </FormGroup> }
                 
                  {bankEmpty && <> { !bankSelected? <><FormGroup>
                    <Label>¿Cuáles son los últimos 4 dígitos de tu número celular?</Label>
                    <InputSelect
                      name="accountEndsWith"
                      defaultOption=""
                      placeholder="Selecciona una cuenta"
                      options={walletAccounts}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Ingresa tu número de celular:</Label>
                    <InputAccountPhone name="account" />
                    <InfoText>
                    Debe coincidir con el seleccionado en la lista.
                  </InfoText>
                  </FormGroup>
                 
                  </>:<>
                    <FormGroup>
                    <Label>La Cuenta contiene esta secuencia:</Label>
                    <InputSelect
                      name="accountEndsWith"
                      defaultOption=""
                      placeholder="Selecciona una cuenta"
                      options={bankAccounts}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirma tu cuenta:</Label>
                    <InputAccountNumber name="account" />
                  </FormGroup>
                  <FormGroup>
                    <InputSelect
                      name="accountType"
                      placeholder="Tipo de cuenta"
                      options={accountTypes}
                    />
                  </FormGroup>
                  </>
                   }</> 
                   
                  }
                
                </FormGroup>
              </GeneralContent>
              <ErrorFocus></ErrorFocus>
            </Form>
          </Formik>
        </Column>
        <Column>
          <ButtonGroup align="center">
            <ButtonContainer>
              <ButtonArrow type="submit" onClick={submitFirm} disabled={submitSend}>
                Siguiente
              </ButtonArrow>
            </ButtonContainer>
          </ButtonGroup>
        </Column>
      </GeneralContent>
    </Column>
  );
};

export default SignatureView;
