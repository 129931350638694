export function monthlyQuote(
  _term,
  _interest,
  _amount,
  _guaranteeVariable,
  _electronicSignature,
  _checkboxSignatureState,
  _checkboxSafeState,
  _checkboxEndorsementState,
  _secureWellbeing,
  _product
  ){
  let variableGuarantee = _guaranteeVariable * 30 * _amount
  let monthlyVAT= _electronicSignature/_term
  const interestMonthly = Math.pow(1 + _interest, 1 / 12) - 1;
  const periodicPaymentAmount = ((interestMonthly * _amount * Math.pow(1 + interestMonthly, _term)) /
  (1 - Math.pow(1 + interestMonthly, _term))) * -1
  if(!_checkboxSignatureState){
    monthlyVAT=0
  }
  if(!_checkboxSafeState || _product==="JTD"){
    _secureWellbeing=0
  }
  if(!_checkboxEndorsementState){
    variableGuarantee=0
  }
  const quote = periodicPaymentAmount + monthlyVAT + variableGuarantee+ _secureWellbeing;
  return Math.round(quote)
}

export function calMaxAmountExternalSimulator(
  _term,
  _disposableIncome,
  _minAmount,
  _maxAmount
 ){
  let incomeMultiplier=0
  switch(_term) {
      case 12:
          incomeMultiplier=1
      break;
      case 18:
          incomeMultiplier=2
      break;
      case 24:
          incomeMultiplier=3
      break;
      default:
        break
  }
  const factorDispoableIncome= _disposableIncome * incomeMultiplier
  const max = Math.max(_minAmount, _maxAmount);    
  let data =  factorDispoableIncome < _minAmount ? _minAmount : factorDispoableIncome > max? max : factorDispoableIncome;
  let data_round = Math.round(data);
  return (data_round - (data_round % 50000))
 }

 export function maxAmountGen(
  _interest,
  _electronicSignature,
  _disposableIncome,
  _guaranteeVariable,
  _maxAmount,
  _incomeFactor,
 ){
      return (_term) => {
      const interestMonthly = Math.pow(1 + _interest, 1 / 12) - 1;
      const valueTechnology = _electronicSignature / _term;
      const paymentFactor = 1 - Math.pow(1 + interestMonthly, -_term);
      const income = _disposableIncome * _incomeFactor;
      const max =
          Number(
          Number(
              ((income - valueTechnology) *
              (paymentFactor /
                  (interestMonthly + _guaranteeVariable * 30 * paymentFactor))) /
              500000
          ).toFixed(1)
          ) * 500000;
      return Math.min(max, _maxAmount);
      }
  }

  export function jtdSimInfo(
    _objecMaxAmount,
    _interest,
    _variableGuarantee,   
    _electronicSignature,
    _checkboxSignatureState,
    _checkboxSafeState,
    _checkboxEndorsementState,
    _secureWellbeing,
    _product
  ){
    const result = [];
    for (const key in _objecMaxAmount) { 
      if (Object.prototype.hasOwnProperty.call(_objecMaxAmount, key)) {
        const objet={}
        objet.term=key
        objet.maxAmount=_objecMaxAmount[key]
        const installmentValue= monthlyQuote(
          key,
          _interest,
          _objecMaxAmount[key],
          _variableGuarantee,
          _electronicSignature,
          _checkboxSignatureState,
          _checkboxSafeState,
          _checkboxEndorsementState,
          _secureWellbeing,
          _product,
        ) 
        objet.installmentValue=installmentValue
        result.push(objet)
      }
    }
    console.log("result ===>",result)
    return result
  }