import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Row from '../../../components/Grid/Row';
import { Spinner } from '../../../components/UI/Spinner';
import BankHouse from '../../../images/bank-house.svg';
import { JTPState } from '../../../store';
import { updateAllLoanStart } from '../../../store/actions/updateAllLoanState';
import { asyncDispatch } from '../../../store/asyncDispatch';
import styled from '../../../styles/styled-components';
import LoansView from './LoansView';

const TabsContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 16%) -2px 4px 8px 2px;
  margin-bottom: 20px;
  text-align: center;

  ul {
    list-style-type: none;
    margin: 2px auto;
    padding: 0;
    overflow: hidden;
  }

  li {
    display: inline;
    text-align: center;
  }

  .active {
    color: rgb(8, 56, 99);
    font-weight: bold;
  }

  li a,
  .dropbtn {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px 12px;
    text-decoration: none;
  }

  li a:hover,
  .dropdown:hover .dropbtn {
    color: #888;
  }

  li.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 14px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: rgb(8, 56, 99);
    color: white;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
`;

const Styled = styled.div`
  text-align: center;
  width: 400px;
  @media (min-width: 750px) {
    width: 500px;
  }

  h3 {
    color: ${(props) => props.theme.blueDark};
    font-size: 25px;
    display: flex;
    justify-content: center;
    img {
      padding-right: 10px;
    }
  }

  .info {
    font-size: 15px;
    font-weight: bold;
    color: ${(props) => props.theme.red};
    background-color: ${(props) => props.theme.gray050};
    padding: 6px;
  }

  .loan-item {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
    margin: 10px;
    padding: 10px;

    .loan-id {
      font-size: 14px;
      text-align: left;
      width: 100%;
      color: ${(props) => props.theme.gray500};
    }

    .loan-id:before {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background-color: ${(props) => props.theme.gray050};
      height: 2px;
    }

    .loan {
      flex: 0;
      display: flex;
      flex-wrap: nowrap;
      background-color: ${(props) => props.theme.gray050};
      align-items: center;
      justify-content: start;
      border-radius: 15px;
      padding: 0 10px;

      label {
        color: ${(props) => props.theme.blueDark};
        margin-right: 5px;
        @media (min-width: 750px) {
          margin-right: 15px;
        }
        padding: 8px 0;
      }
      .balance {
        flex: 1;
        text-align: right;
        margin-right: 5px;
        color: ${(props) => props.theme.red};
      }
      .details {
      }
    }
    .print {
      align-self: center;
      margin: 10px;
    }
  }
`;

const LoadingStyled = styled.div`
  .desc {
    padding: 15px;
    text-align: center;
    padding-bottom: 80px;
    font-weight: 500;
    font-size: 22px;
    color: ${(props) => props.theme.gray500};
  }
`;

interface Props extends RouteComponentProps {
  loans: any;
  getLoans: any;
}

const LoadingComponent = () => (
  <LoadingStyled>
    <Spinner size={64} />
    <div className="desc">Actualizando estado de tu préstamo...</div>
  </LoadingStyled>
);

export type typeTab =
  | 'active'
  | 'new'
  | 'cancel'
  | 'reliefs'
  | 'agreements'
  | 'dateChange'
  | 'support'
  | 'payment'
  | 'refinance'
  | 'download'
  | 'signature'
  | 'updateBasicInfo';

const LoanActiveView: React.FC<Props> = ({ getLoans, loans, history }) => {
  const [TabActive, setTabActive] = useState<typeTab>('active');
  const [loading, setloading] = useState<boolean>(false);

  const fallback = (url: string) => history.push(url)

  const setTab = (tabActive: typeTab) => {
    setTabActive(tabActive);
  };

  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        await getLoans();
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    })();
  }, [getLoans]);

  return (
    <Row center>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Styled>
          {/* <MyElement></MyElement> */}
          
          <h3>
            <img alt="" src={BankHouse} />
            Mis créditos
          </h3>
          <TabsContainer>
            <ul>
              <li className="dropdown">
                <a href="#!" className="dropbtn">
                  GESTIÓN
                </a>
                <div className="dropdown-content">
                  <a
                    href="#!"
                    onClick={() => setTab('reliefs')}
                    className={TabActive === 'reliefs' ? 'active' : ''}
                  >
                    Alivios
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('agreements')}
                    className={TabActive === 'agreements' ? 'active' : ''}
                  >
                    Acuerdos
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('dateChange')}
                    className={TabActive === 'dateChange' ? 'active' : ''}
                  >
                    Cambio de fecha
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('support')}
                    className={TabActive === 'support' ? 'active' : ''}
                  >
                    Soportes
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('payment')}
                    className={TabActive === 'payment' ? 'active' : ''}
                  >
                    Abono a capital
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('refinance')}
                    className={TabActive === 'refinance' ? 'active' : ''}
                  >
                    Refinanciación
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('download')}
                    className={TabActive === 'download' ? 'active' : ''}
                  >
                    Descargas
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('signature')}
                    className={TabActive === 'signature' ? 'active' : ''}
                  >
                    Reporte de Firmas Electrónicas
                  </a>
                  <a
                    href="#!"
                    onClick={() => setTab('updateBasicInfo')}
                    className={TabActive === 'updateBasicInfo' ? 'active' : ''}
                  >
                    Actualización de Datos
                  </a>
                </div>
              </li>
              <li>
                <a
                  href="#!"
                  className={TabActive === 'cancel' ? 'active' : ''}
                  onClick={() => setTab('cancel')}
                >
                  CANCELADOS
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  className={TabActive === 'active' ? 'active' : ''}
                  onClick={() => setTab('active')}
                >
                  ACTIVOS
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  className={TabActive === 'new' ? 'active' : ''}
                  onClick={() => setTab('new')}
                >
                  NUEVO
                </a>
              </li>
            </ul>
          </TabsContainer>
          {loans && <LoansView loans={loans} type={TabActive} fallback={fallback} ></LoansView>}
          
        </Styled>
      )}
    </Row>
  );
};

export default connect(
  (state: JTPState) => ({
    loans: state.loans,
  }),
  (dispatch) => ({
    getLoans: asyncDispatch(dispatch, updateAllLoanStart),
  })
)(LoanActiveView);
