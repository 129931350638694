import figure from '../../images/logo-juancho-te-presta.png';
import styled from '../../styles/styled-components';

const ContentMessages = styled.div`
  disply:flex;
  align-items:center;
  justify-content:center;
  margin-top:calc(100vh/2 - 300px);
  @media (min-width: 960px) {
    margin-top:calc(100vh/2 - 350px);
  }
`
const MessagesFormat = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  border: 1px solid ${props => props.theme.grayLight};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  border-radius: 4rem;
  padding: 3.5rem 1.5rem 2.5rem 1.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: 14px;

  > h4 {
    border-radius: 5px;
    padding: 0.75rem;
    text-align: left;
  }
  > div {
    display: flex;
    width: 340px;
    height: 100px;
    background-image: url(${figure});
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
  }

  > p {
    text-align: left;
    margin:12px;
  }
  @media (min-width: 740px) {
  }
`;

export { ContentMessages, MessagesFormat };

