import { ServiceLoaded } from '../types/Service';
import { request } from './api';
import { ApplicationResponse } from './changeLoanValuesGet';
import { ValuesPach } from './changeLoanValuesPost';

export const url = (uuid: string) => `loans/application/${uuid}`;

export default async function updateLoanApplication(
  uuid: string,
  payload: ValuesPach
): Promise<ServiceLoaded<ApplicationResponse>> {
  try {
    // Realiza la solicitud PATCH utilizando la función request
    const responsePayload = await request(
      'patch',                // Método HTTP
      url(uuid),              // URL con el UUID
      false,                  // No se requiere autenticación
      payload,                // Parámetros (en este caso el payload)
      false                   // query (false porque el payload debe ir en el cuerpo)
    );

    return {
      status: 'loaded',
      payload: responsePayload,
    };
  } catch (e) {
    console.error('Error al realizar la solicitud:', e);
    throw e;
  }
}