import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes } from 'react';
import styled, { css, Theme } from '../../styles/styled-components';
import { Props as LinkProps } from './Link';
import { Spinner } from './Spinner';

interface ButtonProps {
  backgroundColor?: string;
  textcolor?: string;
  disabled?: boolean | undefined;
  loading?: boolean;
  inline?: boolean;
  expand?: boolean;
  color: keyof Theme;
  outline?: boolean;
  sm?: boolean;
  flat: boolean;
  [key: string]: any;
}

export const ButtonCSS = css<ButtonProps>`
  &,
  &:link,
  &:visited {
    font-weight: 700;
    font-size: ${(props) => (props.sm ? '0.7rem' : '1.4rem')};
    padding: ${(props) => (props.sm ? '10px' : '10px 15px')};
    min-width: ${(props) => (props.expand ? '250px' : '0')};
    display: ${(props) => (props.inline ? 'inline-block' : 'block')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
    border-radius: 3.5rem;
    position: relative;
    // text-transform: uppercase;
    transition: all 0.25s ease;
    margin: 5px;
    padding: ${(props) => (props.sm ? '10px' : '10px 15px')};
    text-align: center;
    outline: none;
    border-color: transparent;
    background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.outline || props.flat
      ? 'transparent'
      : props.theme[props.color]};
    color: ${(props) =>
    props.textcolor
      ? props.textcolor
      : props.outline || props.flat
      ? props.theme[props.color]
      : 'white'};

    :focus:not(:disabled),
    :hover:not(:disabled),
    :active:not(:disabled) {
      background-color: ${(props) =>
        props.flat ? 'transparent' : props.theme[props.color] + 'ee'};
      border-color: transparent;
      color: ${(props) => (props.flat ? props.theme[props.color] : 'black')};
      transform: translateY(2px); 
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); 

      cursor: pointer;

      > * {
        cursor: pointer;
      }
    }
    .spinner {
      display: ${(props) => (props.loading ? 'block' : 'none')};
    }
    .children {
      visibility: ${(props) => (props.loading ? 'hidden' : 'visible')};
      .icon.on-left {
        margin-right: 10px;
      }
    }
    :disabled {
      pointer-events: none;
      background-color: ${(props) =>
        props.outline || props.flat
          ? 'transparent'
          : props.theme[props.color]}99;
      border-color: ${(props) => props.theme[props.color]}99;
      color: ${(props) =>
        props.outline || props.flat ? props.theme[props.color] : '#ffffff'}99;
    }
  }
`;

const ButtonStyled = styled.button<ButtonProps>`
  ${ButtonCSS}
`;

export interface Props
  extends Partial<ButtonProps & ButtonHTMLAttributes<any>> {
  icon?: IconProp;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  to?: LinkProps['to'] | string;
  [prop: string]: any;
}

const ButtonGeneric: React.FC<Props> = ({
  as,
  children,
  color = 'yellow',
  sm,
  icon,
  className = '',
  loading,
  flat,
  ...restProps
}) => {
  return (
    <ButtonStyled
      as={as}
      className={`${className} ripple`}
      {...({
        color,
        sm: sm ? 'true' : undefined,
        loading: loading ? 'true' : undefined,
        flat: flat ? 'true' : undefined,
        ...restProps,
      } as any)}
    >
      <span className="children">
        {icon && (
          <FontAwesomeIcon
            className={`icon ${children ? 'on-left' : ''}`}
            size="lg"
            icon={icon}
          />
        )}
        {children}
      </span>
      <Spinner className="spinner" size={28} />
    </ButtonStyled>
  );
};

export default ButtonGeneric;
