import { LitElement, html, css } from 'lit';
import './popup-item';
class PopupImportant extends LitElement {
  static styles = css`
  :host {
      --light-blue-gray:#CED5F0;
      --light-gray:#ccc;
      --dark-gray:#333;
      --color-gender:#2F5BFF;
      --gray-color:#6c757d;
      display: inline-block;
    }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      color: black;
      visibility: hidden;
    }
    .popup-container--visible {
      visibility: visible;
    }
    .popup-container__popup {
      margin:10px;
      position:relative;
      background-color: white;
      padding: 10px 20px 30px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 35px;
      position: relative; 
      z-index: 1000;
      max-width: 420px;
      transform: translateY(100%);
      opacity: 0;
      width:100%;
      transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    }
    .popup-container__popup--visible {
      background-color: rgba(254, 254, 254, 0.7);
      backdrop-filter: blur(5px); 
      transform: translateY(0);
      opacity: 1;
    }
    .popup-container__title-bar {
      margin-top:20px;
      display: flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      margin-bottom: 10px;
      font-weight:600;
      color:var(--color-gender);
    }
    .space{
      margin:5px;
    }
 
    .popup-container__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px); 
      z-index: 998; 
    }
    .popup-container__simbol{
      border-radius:50%;
      border: solid 1px var(--color-gender);
      font-size:23px;
      width:18px;
      height:18px;
      margin:10px;
      /* color:black; */
      cursor:pointer;
      p{
        margin:0;
      }
    }
  
    .popup-container__simbol---{
      background-color:var(--light-gray);
      width:80px;
      height:6px;
      margin-bottom:15px;
      cursor:pointer;
    }
    .popup-container__simbol--x{
      font-family:Arial;
      position:absolute;
      top:7px;
      right:7px;
      font-size:25px;
      border-radius:50%;
      width:25px;
      height:25px;
      margin:20px;
      color:var(--dark-gray);
      cursor:pointer;
      p{
        margin:0;
        padding:0 0 1px 4px;
        line-height: 16px; 
      }
    }
    .main-popup{
      display: flex;
      justify-content: space-between;
    }
    .border_down{
      border-bottom: 1px solid var(--light-gray);
    }
    .popup-container__do{
      display: flex;
      flex-direction:column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px 0;

      h3{
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }
      p{
        margin: 10px 0 0 0;
        font-size: 14px;
      }
    }
    .popup-container__do--arrow{
      margin-left:10px;
      p{
        margin: 15px 0;
        font-size: 35px;
      }
      color: var(--light-gray);
      cursor:pointer;
    }
    .popup-item{
        line-height:13px; 
        font-size:13px;
    }
   
  `;
  static get properties() {
    return {
      valueColor: { type: String },
      stateOpenInfo:{type:Boolean},
      isOpen: { type: Boolean },
    };
  }
  constructor() {
    super();
    this.popupEndorsement = false;
    this.popupSignature = false;
    this.stateOpenInfo = false;
    this.isOpen = false;
  }
  updated(changedProps) {
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--color-gender', this.valueColor);
    }
    if (changedProps.has('isOpen')) {
      this._togglePopupVisibility();
    }
  }

  _togglePopupVisibility() {
    const popupContainer = this.shadowRoot.querySelector('.popup-container');
    const popup = this.shadowRoot.querySelector('.popup-container__popup');
    
    if (this.isOpen) {
      popupContainer.classList.add('popup-container--visible');
      setTimeout(() => {
        popup.classList.add('popup-container__popup--visible');
      }, 50);
    } else {
      popup.classList.remove('popup-container__popup--visible');
      setTimeout(() => {
        popupContainer.classList.remove('popup-container--visible');
      }, 500);
    }
  }
  handleOpenPopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = true;
    }
    if(typePopup==="signature"){
      this.popupSignature = true;
    }
    this.requestUpdate();
  }
  handleClosePopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = false;
    }
    if(typePopup==="signature"){
      this.popupSignature = false;
    }
    this.requestUpdate();
  }
  closePopup() {
    this.isOpen = false;
    this.dispatchEvent(new CustomEvent('close-popup'));
  }

  render() {
    return html`
      <div class="popup-container">
      <popup-item valueColor=${this.valueColor} 
            .isOpen=${this.popupSignature}
            @close-popup="${()=>this.handleClosePopup("signature")}">
            <div slot="title">Firma electrónica</div>
            <p class="popup-item">Lorem ipsum dolor sit amet consectetur 
              adipisicing elit. Ut modi ea et odit reiciendis ipsum 
              ipsa harum, quos tempore quo, voluptatem amet quam 
              vitae delectus omnis iusto. Ea, accusantium quo.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Beatae quo modi quae tenetur tempora odit, ipsa qui? Sunt
              iste omnis eos! Fugit, voluptatem? Iure dicta nihil neque
              accusantium quae repellat!
              adipisicing elit. Ut modi ea et odit reiciendis ipsum 
              ipsa harum, quos tempore quo, voluptatem amet quam 
              vitae delectus omnis iusto. Ea, accusantium quo.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Beatae quo modi quae tenetur tempora odit, ipsa qui? Sunt
              iste omnis eos! Fugit, voluptatem? Iure dicta nihil neque
              accusantium quae repellat!
        </p>
          </popup-item>
          <popup-item valueColor=${this.valueColor} 
            .isOpen=${this.popupEndorsement}
            @close-popup="${()=>this.handleClosePopup("endorsement")}">
            <div slot="title">Aval</div>
            <p class="popup-item">Lorem ipsum dolor sit amet consectetur 
              adipisicing elit. Ut modi ea et odit reiciendis ipsum 
              ipsa harum, quos tempore quo, voluptatem amet quam 
              vitae delectus omnis iusto. Ea, accusantium quo.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Beatae quo modi quae tenetur tempora odit, ipsa qui? Sunt
              iste omnis eos! Fugit, voluptatem? Iure dicta nihil neque
              accusantium quae repellat!
              adipisicing elit. Ut modi ea et odit reiciendis ipsum 
              ipsa harum, quos tempore quo, voluptatem amet quam 
              vitae delectus omnis iusto. Ea, accusantium quo.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Beatae quo modi quae tenetur tempora odit, ipsa qui? Sunt
              iste omnis eos! Fugit, voluptatem? Iure dicta nihil neque
              accusantium quae repellat!
        </p>
        </popup-item>
        <div class="popup-container__popup">
          <div class="popup-container__simbol--x" @click="${this.closePopup}">
            <p>x</p>
          </div>
          
          <div class="popup-container__title-bar">
            <!-- <div class="popup-container__simbol---" @click="${this.closePopup}"></div> -->
            <div class="space"></div>
            <slot name="title"></slot>
          </div>
          <!-- Contenido de la ventana emergente -->
          <div class="main-popup border_down" >
           <div class="popup-container__do ">
              <h3> Si no contrato firma electrónica</h3>
              <p> Te desembolsamos en 24 horas
                si decides contratar el servicio.
              </p>
            </div>
            <div class="popup-container__do--arrow"
              @click="${()=>this.handleOpenPopup("signature")}"
              ><p> ></p></div>
          </div>
          <div class="main-popup" >
            <div class="popup-container__do">
              <h3 >Si no contrato Aval</h3>
              <p> Te desembolsamos en 24 horas
                si decides contratar el servicio.
              </p>
            </div>
           <div class="popup-container__do--arrow"
              @click="${()=>this.handleOpenPopup("endorsement")}"
              ><p> ></p></div>
          </div>
          <!-- <div class="popup-container__do">
            <p class="popup-container__do--titel">Si no contrato firma electrónica</p>
            <p class="popup-container__do--content"> Te desembolsamos en 24 horas
              si decides contratar el servicio.
            </p>
          </div> -->
        </div>
        <!-- Capa de fondo -->
        <div class="popup-container__overlay" @click="${this.closePopup}"></div>
      </div>
    `;
  }
}

customElements.define('popup-important', PopupImportant);