import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimulatorParams } from '../../services/simulatorGet';

const initialState: SimulatorParams = {
  components:[],
  options: []
};

export const simulator = createSlice({
  name: 'simulator',
  initialState,
  reducers: {
    updateSimulator(_, action: PayloadAction<SimulatorParams>) {
      return action.payload;
    },
  },
});
