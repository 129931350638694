import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import changeLoanValuesGet, { ApplicationResponse } from '../../../services/changeLoanValuesGet';
import { ValuesPach } from '../../../services/changeLoanValuesPost';
import { Option, Selectors } from '../../../services/selectorsGet';
import simulatorAuthGetPath from '../../../services/simulatorAuthGetPath';
import { JTPState } from '../../../store';
import {
  ResolveNextPath,
  resolveNextPath,
} from '../../../store/actions/navigation';
import {
  UpdateSimulatorStart
} from '../../../store/actions/updateSimulator';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { changeLoanValuesPatch } from '../../../store/reducers/changeLoanValuesPatchReducer';
import ConfirmLoanView from './SummaryLoanView';
export interface Props extends RouteComponentProps {
  submit(params: ValuesPach): Promise<void>;
  resolveNextPath: ResolveNextPath;
  loanPurpose: Option<string>[];
  mifos: Selectors;
  hydrateSimulator: UpdateSimulatorStart;
  loans: any;
}

const SummaryLoanContainer: React.FC<Props> = (props) => {
  return (
    <>
    <Mount
      before={async () => {
        
        const summary: ApplicationResponse = await changeLoanValuesGet();
        const resultSim = (await simulatorAuthGetPath(summary.path)).payload.components
        
        return {
          resultSim,
          amount: summary.amount,
          term: summary.term,
          path: summary.path,
          genderName: summary.genderName,
          product: summary.product,
          daysCounter: summary.daysCounter,
          mandatoryComponents:summary.mandatoryComponents,
          optionalComponents:summary.optionalComponents
        };
      }}
      props={props}
      component={ConfirmLoanView}
    />
    
  </>);
};

export default connect(
  (state: JTPState, ownProps) => ({
    ...ownProps,
    loans: state.loans,
  }),
  (dispatch) => ({
    submit: asyncDispatch(dispatch, changeLoanValuesPatch.actions.request),
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
  })
)(SummaryLoanContainer);
