import { LitElement, html, css } from 'lit';

class PopupItem extends LitElement {
  static styles = css`
  :host {
      --light-blue-gray:#CED5F0;
      --light-gray:#ccc;
      --dark-gray:#333;
      --color-gender:#2F5BFF;
      --gray-color:#6c757d;
      display: inline-block;
    }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1010;
      color: black;
      visibility: hidden;
    }
    .popup-container--visible {
      visibility: visible;
    }
    .popup-container__popup {
      margin:10px;
      position:relative;
      background-color: white;
      padding: 10px 20px 30px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 35px;
      position: relative; 
      z-index: 1011;
      max-width: 420px;
      transform: translateX(-100%);
      opacity: 0;
      width:100%;
      transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    }
    .popup-container__popup--visible {
      background-color: rgba(254, 254, 254, 0.7);
      backdrop-filter: blur(5px); 
      transform: translateX(0);
      opacity: 1;
    }
    .popup-container__title-bar {
      display: flex;
      flex-direction:column;
      margin-bottom: 10px;
      font-weight:600;
      color:var(--color-gender);
    }
    .space{
      margin:5px;
    }
 
    .popup-container__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 998; 
    }
  
    .popup-container__simbol---{
      display:flex;
      align-self:center;
      background-color:var(--light-gray);
      width:80px;
      height:6px;
      margin-bottom:15px;
      cursor:pointer;
    }

    .popup-container__do--arrow{
      p{
        margin: 0;
        font-size: 35px;
      }
      color: var(--light-gray);
      cursor:pointer;
    }
   
  `;
  static get properties() {
    return {
      valueColor: { type: String },
      stateOpenInfo:{type:Boolean},
      isOpen: { type: Boolean },
    };
  }
  constructor() {
    super();
    this.stateOpenInfo = false;
    this.isOpen = false;
  }
  updated(changedProps) {
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--color-gender', this.valueColor);
    }
    if (changedProps.has('isOpen')) {
      this._togglePopupVisibility();
    }
  }

  _togglePopupVisibility() {
    const popupContainer = this.shadowRoot.querySelector('.popup-container');
    const popup = this.shadowRoot.querySelector('.popup-container__popup');
    
    if (this.isOpen) {
      popupContainer.classList.add('popup-container--visible');
      setTimeout(() => {
        popup.classList.add('popup-container__popup--visible');
      }, 50);
    } else {
      popup.classList.remove('popup-container__popup--visible');
      setTimeout(() => {
        popupContainer.classList.remove('popup-container--visible');
      }, 500);
    }
  }

  closePopup() {
    this.isOpen = false;
    this.dispatchEvent(new CustomEvent('close-popup'));
  }

  render() {
    return html`
      <div class="popup-container">
        <div class="popup-container__popup">
          <div class="popup-container__title-bar">
            <!-- <div class="popup-container__simbol---" 
            @click="${this.closePopup}"></div> -->
            <div class="popup-container__do--arrow"
            @click="${this.closePopup}"><p>< </p></div>
            <slot name="title"></slot>
          </div>
          <slot></slot>
        </div>
        <div class="popup-container__overlay" @click="${this.closePopup}"></div>
      </div>
    `;
  }
}

customElements.define('popup-item', PopupItem);