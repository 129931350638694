
import serviceBuilder from './serviceBuilder';

export interface Paramsleads {
  termsAndConditions: Boolean,
  email: String,
  phone: String,
  recaptchaToken: String
}

const url = 'users/leads';
export default serviceBuilder<Paramsleads>('post', {
  url,
  auth: false,
})
