import { css } from 'lit';

export const bodyInfoStyles = css`
:host {
  --product-color:#2F5BFF;
  --paragraph-color:#ffffff;
  --gray-color:#6c757d;
  --white-color:#ffffff;
  --pastel-blue:#9DABD7;
  --light-blue-gray:#CED5F0;
  --black-color:#000000;
  --color-gradiente-gray:rgba(255, 255, 255, 0.3);
  --color-gradiente-gray2:rgba(255, 255, 255, 0.5);
  display: inline-block;
}

.container{
  width:400px;
  display:flex;
  flex-direction:column;
  h5{
    text-align:center;
    font-weight: 400; 
    font-size:13px; 
    line-height: 15px; 
    margin:12px 0 8px 0;
  }
}
.content-arrows{
  position:relative;
  display:flex;
  justify-content:center;
  gap:14px;
  height:25px;
}
.arrows{
  display:flex;
  justify-content:center;
  width:88px;
}
.arrow{
  width: 0;
  height: 0;
  margin-top: 5px;
  border-left: 15px solid transparent; 
  border-right: 15px solid transparent; 
  border-bottom: 20px solid var(--arrow-background-color, --white-color);
}
.content-terms-button{
  display:flex;
  justify-content:center;
  gap:14px;
}
.button-term{
  display:flex;
  justify-content:center;
  width:88px;
  padding: 2px;
  font-size: 25px;
  border-radius: 10px;
  color:var(--gray-color);
  font-weight:700;
  border:0;
  cursor:pointer;
}

.content-terms-button button.selected {
  background-color: var(--product-color);
  color:white;
}
.first-box{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-color:var(--product-color);
  border-radius: var(--first-box-border-radius, 23px);
  color:white;
  padding:15px;
  margin-bottom:15px;
}
.sub-first-box{
 display: grid;
 grid-template-columns: 1fr 1px 1fr;
 width:100%;
 align-items: center;
 padding-bottom:10px;
}
.sub-first-box-one{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  p:nth-child(1) {
    margin:5px 5px;
  }
  p:nth-child(2) {
    font-weight: 600; 
    font-size: 27px; 
    line-height: 32.68px; 
    margin:0 2px;
  }
}
.into-sub-first-box{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-top:15px;
  p:nth-child(1) {
    margin:0 5px;
  }
  p:nth-child(2) {
    font-weight: 600; 
    font-size: 20px; 
    line-height: 32.68px; 
    margin:0 2px;
  }
} 
.line {
  width: 100%; /* Ancho de la línea, ajusta según sea necesario */
  margin: 0 auto; /* Centra la línea horizontalmente */
  border-top: 1px solid var(--color-gradiente-gray); /* Línea en la parte superior */
}
.vertical-line{
  margin-top:10px;
  width: 1px; 
  height: calc(100% - 20px);
  background-color: var(--color-gradiente-gray);
}
.checkbox-container {
  display: flex;
  align-items: center;
}
.hidden {
  display: none;
}

.second-box{
  padding:10px 20px;
  background-color:var(--light-blue-gray);
  border-radius:20px;
  color:var(--gray-color);
  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  label{
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: 600; 
    font-size: 13px; 
    line-height: 20.68px; 

  }
  button {
    margin-top:15px;
    width:100%;
    text-align:center;
    border: none; 
    background: none;
    padding: 0; 
    
    cursor: pointer; 
    text-decoration: underline;
    display: inline-block; 
    position: relative; 
    color:var(--gray-color);
    font-size:12px;
  }
}

.continer-warranty{
  position:relative;
  display:flex;
  justify-content:space-between;
  h6{
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: 700; 
    font-size: 13px; 
    margin:0 2px;
  }
}
.continer-warranty--p{
  font-weight: 400; 
  font-size: 13px; 
  line-height: 24px; 
  margin:0;
  padding:0;
}
.border__bottom{
  border-bottom: 1px solid var(--pastel-blue); 
}
.endorsement{
  p{
    font-weight: 400; 
    font-size: 13px; 
    line-height: 24px; 
    margin:0 0 0 20px;
  }
}
.endorsement p:first-child {
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight: 700; 
  font-size: 13px; 
  margin:0 2px;
}
.point{
  position:absolute;
  width:5px;
  height:5px;
  border-radius:50%;
  background-color:var(--gray-color);
  top:10px;
  left:11.5px;
}
.line-between-points{
  position:absolute;
  width:1px;
  height:80px;
  top:12px;
  left:13.5px;
  background-color:var(--gray-color);
}
.subrayado {
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-color) 50%);
  background-size: 100% 2px; /* Ancho y grosor del subrayado */
  background-repeat: no-repeat;
  background-position: 0 50%;
}
.checkbox-container input[type="checkbox"] {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: white;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
  margin-left: 8px;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: var(--gray-color); 
  border-color: var(--gray-color);
}

.checkbox-container input[type="checkbox"]:checked::before {
  content: '✔';
  color: var(--white-color); 
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

.help{
  font-family: Arial, sans-serif;
  font-size:11px;
  background-color:var(--color-gradiente-gray);
  border-radius: 50%;
  margin: 2px 5px;
  padding: 1px 2.9px 1px 2.9px;
  line-height: 10px; 
  color:white;
  cursor: pointer;
}
.help-two{
  font-family: Arial, sans-serif;
  font-size:11px;
  font-weight: 400;  
  background-color:var(--gray-color);
  border-radius: 50%;
  margin: 0 5px;
  padding: 1px 2.9px 1px 2.9px;
  color:white;
  line-height:10px; 
  cursor: pointer;
}
.container-info{
  display:flex;
  align-items:center;
}
.interest-rate{
  width:100%;
  color:var(--color-gradiente-gray2);
  p{
    font-size:10px;
    margin-top:0;
  }
}
.popup-windows{
    line-height:13px; 
    font-size:13px;
}
@media (max-width: 550px){
  .container{
      width:340px;
    }
    .first-box{
      border-radius: var(--first-box-border-radius, 20px);
    }
    .arrow{
      border-left: 12px solid transparent; 
      border-right: 12px solid transparent; 
    }
  }

`;