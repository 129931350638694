import { ServiceLoaded } from '../types/Service';
import serviceBuilder from './serviceBuilder';
export interface Token{
  token:string
} 
export const url = 'loans/status/:uuid';

const service = serviceBuilder<{ uuid: string },Token>('patch', {
  query: true,
  url,
  auth: false,
});
export default async function(
  uuid: string
): Promise<ServiceLoaded<Token>> {
  try {
    const { payload, status } = await service({ uuid });
    if (payload && payload.token) {
      const token: string = payload.token;
      return { payload: { token }, status };
    }
    throw new Error('Token no recibido en la respuesta.');
  } catch (e) {
    throw e;
  }
}
