import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export interface Components{
  id: number|null;
  value:number|null;
  statusId?:number;
  name:string;
}
export interface ApplicationResponse{
  mandatoryComponents:Components[];
  optionalComponents:Components[];
  loanPurpose: number;
  amount:number;
  term:number;
  repaymentsStartingFromDate: string;
  path:number;
  interest:number|null;
  genderName:string;
  product:string;
  daysCounter:number;
}

export const url = 'sec/loans/application';

export default async () => {
  const response = await serviceBuilder<None, ApplicationResponse>('get', {
    url,
    auth: true,
  })();
  return response.payload;
};
