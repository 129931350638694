import { css } from 'lit';

export const jtdAgreementStyles = css`
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 0;
  }

  .modal__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  .modal__main-content {
    background-color: rgb(48, 92, 255);
    padding: 70px 20px 20px 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    max-height: 95%;
    overflow-y: auto;
    z-index: 1001;
    width: 400px;
    border-radius: 10px;
    margin:10px;
  }

  .modal__second-content {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .modal__text-container {
    color: #000;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    top: 5px;
  }

  .modal__text-container > h4 {
    margin: 45px 0 0 0;
    font-weight: 700;
    font-size: 22px;
  }

  .modal__text-first {
    padding: 0 20px 0 20px;
  }

  .modal__text-second {
    padding: 0 10px 0 10px;
    text-align: justify;
  }

  .modal__icon-container {
    position: absolute;
    background-color: rgb(48, 92, 255);
    top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal__notice-content {
    width: 70px;
  }

  .modal__info-content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 5px 0 0 5px;
    font-size: 12px;
    color: #000;
    width: 100%;
  }

  .modal__info {
    background-color: rgb(48, 92, 255);
    border-radius: 5px 0 0 5px;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 12px;
    margin-left:10px;
    color: #000;
  }

  .modal__info > p {
    margin: 8px 0 8px 8px;
    color: white;
  }

  .modal__button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 76px;
  }

  .modal__button {
    background-color: rgb(222, 235, 247);
    color: #000;
    border: none;
    padding: 11px 20px 9px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 20px 0 16px;
  }

  .modal__button > p {
    margin: 3px;
    font-size: 12px;
    font-weight: 500;
  }

  .modal__button:hover {
    background-color: rgb(48, 92, 255);
    color: #fff;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  @media (min-width: 600px) {
    .modal {
      padding-bottom: 160px;
    }
  }

  @media (min-width: 800px) {
    .modal__main-content {
      width: 600px;
    }

    .modal__text-first {
      padding: 0 120px 0 120px;
    }

    .modal__text-second {
      padding: 0 95px 0 95px;
    }
  }
`;