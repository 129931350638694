import { breakpointLarge } from '../../styles/breakpoints';
import styled from '../../styles/styled-components';

const Alert = styled.img`
  margin-top:25px;
  width: 70px;
  height: 70px;
  padding-top: 20px;
  @media (min-width: ${breakpointLarge}) {
    width: 80px;
    height: 80px;
  }
`;

const Alert2 = styled.img`
  
  width: 40px;
  height: 40px;
  @media (min-width: ${breakpointLarge}) {
    width: 80px;
    height: 80px;
  }
`;
const ContentAval = styled.div`
  width:100%
  padding:10px;
  display:flex;
  color: black;
  background-color: #E5E5E5;
  h3{
    margin:0 15px 0 0;
    font-size:22px;

  }
  p{
    background-color: #FF6045;
    color :white;
    margin:0;
    border-radius:14px;
    padding:5px 10px;
    font-size:14px;
  }
  @media (min-width: ${breakpointLarge}) {
    padding:15px;
  }
`

const ContentDays = styled.div`
  margin-bottom: 8px;
  width: 400px;
  display: flex;
  font-weight: 600;
  text-align: left;
  align-items: center;
  color: black;
  font-size: 18px;
  p {
    margin: 10px;
  }
`;

const PrincipleMessage = styled.p`
  color: black;
  text-align: left;
  label {
    color: #6235CB;
    font-weight: 600;
  }
  margin: 10px;
`;

const WatchFlash = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 15px;
  padding: 4px;
  background-color: #E5E5E5;
  border-radius: 12px;
`;

const Title = styled.h2`
  padding-top: 30px;
  color: #2F5BFF;
  font-size: 22px;
`;

const TitleSend2 = styled.h2`
  color: black;
  margin-top: 30px;
`;

const TitleSend = styled.h2`
  color: #6235CB;
  margin-top: 10px;
  
`;

const TextInfo = styled.p`
  padding-top: 20px;
  font-size: 14px;
`;

const DocumentList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    background-color: #6235CB;
    color: #ffffff;
    padding: 9px 18px;
    margin: 4px 0;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  li:before {
    content: '';
    width: 21px;
    height: 14px;
    border-radius: 7px;
    background-color: #AA83FF;
    display: block;
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const MainContent = styled.div`
  color: black;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Message = styled.div`
  color: black;
  text-align: left;
  border-radius: 30px;
  background-color: #E5E5E5;
  padding: 20px;
  p {
    margin: 0;
  }
`;

const SubMessage = styled.div`
  display: flex;
  margin-top: 16px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  p {
    margin: 0;
  }
`;

const SubMessage2 = styled.div`
  color: black;
  display: flex;
  margin: 30px 16px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  p {
    margin: 0;
  }
`;

const Resend = styled.div`
  display: flex;
  color: black;
  width: 400px;
  margin: 16px 0; 
  p {
    text-align: left;
    margin: 0 30px 0 0;
  }

  strong {
    cursor: pointer;
    font-weight: 500;
    color: #6235CB;
    text-decoration: underline;
  }
`;

const ContinerSend = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  #z-index: 1000;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;

const MainContentSend = styled.div`
  position: relative;
  background-color: #fff;
  color: ${(props) => props.theme.gray500};
  padding: 30px;
  margin: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  z-index: 1001;
  max-width: 420px;
  border-radius: 40px;
  font-size: 1.1rem;
  p {
    margin-top: 30px;
    text-align: left;
    color: black;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  &:before {
    content: ' ';
    position: absolute;
    background-color: #000;
    width: 2px;
    height: 16px;
    top: 1px;
    right: 7px;
    transform: rotate(45deg);
    cursor: pointer;
  }
  &:after {
    content: '';
    position: absolute;
    background-color: #000;
    width: 16px;
    height: 2px;
    top: 8px;
    right: 0;
    transform: rotate(45deg);
    cursor: pointer;
  }
`;

const AlertEmail = styled.img`
  position: absolute;
  width: 70px;
  height: 70px;
  top: -80px;
  left: calc(50% - 30px);
`;

export {
  Alert, Alert2, AlertEmail, Background, Close, ContentAval, ContentDays, ContinerSend, DocumentList,
  MainContent, MainContentSend, Message, PrincipleMessage, Resend, SubMessage,
  SubMessage2, TextInfo, Title, TitleSend, TitleSend2, WatchFlash
};


