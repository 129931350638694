/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Column from '../../../components/Grid/Column';
import '../../../components/lit/simulator/credit-simulator';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { ModalF, useModalF } from '../../../components/UI/ModalFirst';
import firstDatePayment from '../../../helpers/financial/firstDatePayment';
import sendEventToGTM from '../../../helpers/tagManager';
import { Components, ComponentsGet, LoanValues } from '../../../services/changeLoanValuesPost';
import jtdClientDecisionPost from '../../../services/jtdClientDecisionPost';
import { Pixel } from '../../../services/pixelGet';
import { RiskMatrix } from '../../../services/riskMatrixGet';
import { Option } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import { ResolveNextPath } from '../../../store/actions/navigation';
import styled from '../../../styles/styled-components';

const Header = styled.div`
  position:absolute;
  width:100%;
  top:0;
  height:150px;
  background-color:#FFCD00;
`
const ContainerSimulate= styled.div`
  background: linear-gradient(to bottom, #D9D9D9, white);
  padding-top:60px;
  position:relative;
`

export interface FormValues {
  loanPurpose: number;
}

/* const cancelProcessModal = {
  main: (
    <>
      <p>
        Cancelaste tu solicitud de crédito. Recuerda que puedes realizar una
        nueva en cualquier momento.
      </p>
    </>
  ),
  cancel: '¡Hasta pronto!',
}; */
interface ButtonRequestEvent extends Event {
    detail: {
      aval?: boolean;
      signature?: boolean;
      secureWellbeing?:boolean;
      loanPurpose: string;
      amount: number;
      repaymentsStartingFromDate: string;
      term: number;
  };
}
interface ButtonDesitionEvent extends Event {
  detail: {
    jtd_client_decision:boolean;
    jtd_sim_info: []
  }
}

export interface Props extends RouteComponentProps {
  gender: string;
  submit(params: LoanValues): Promise<void>;
  resolveNextPath: ResolveNextPath;
  loanPurpose: Option<string>[];
  risk: string;
  riskMatrix: RiskMatrix;
  loans: any;
  finalPath: number;
  incomeFactor: number;
  disposableIncome: number;
  mark: string;
  maxRiskQuota: number;
  product:string;
  simUserMsg:string;
  pixels:[];
  resultSim:ComponentsGet[]
}

const ConfirmLoanView: React.FC<Props> = ({
  history,
  submit,
  loanPurpose,
  gender,
  risk,
  riskMatrix,
  loans,
  incomeFactor,
  finalPath,
  disposableIncome,
  mark,
  maxRiskQuota,
  product,
  simUserMsg,
  pixels,
  resultSim
}) => {
  const [modalF, openModalF] = useModalF();
  const [modalC, openModalC] = useModalC(); 
  const maxAmount = mark !== 'NA' ? maxRiskQuota : riskMatrix[finalPath]['maxAmount'];
  const minAmount = riskMatrix[finalPath]['minAmount']
  const terms=riskMatrix[finalPath]['term']
  // const simulator = useSelector(simulatorSelector);
  const [shuffledLoanPurpose, setShuffledLoanPurpose] = useState([]);
  const [valueFirstDatePayment, setFirstDatePayment] = useState('');

  const getValues = (name: string) => {
    const component = resultSim.find((comp: { name: string }) => comp.name === name);
    return component ? {value:component.value,id:component.id} :  {value:0,id:0};
  };

  const interest = getValues('interest');
  const electronicSignature = getValues('electronicSignature');
  const guaranteeVariableMan = getValues('guaranteeVariableMan');
  const guaranteeVariableWoman = getValues('guaranteeVariableWoman');
  const guaranteeConstant = getValues('guaranteeConstant');
  const guaranteeFurniture = getValues('guaranteeFurniture');
  const maxAmountSim= getValues('maxAmount');
  const minAmountSim= getValues('minAmount');
  // console.log("datos del simulador", simulator)
  // console.log("datos del riskMatrix", riskMatrix)
  // console.log("datos del incomeFactor", incomeFactor)
  // const [modalF, openModalF] = useModalF();
  // const [modalD, openModalD] = useModalD();
  // const [modalC, openModalC] = useModalC(); 
  const internalsimulatorRef = useRef<HTMLElement>(null);
  const handleRequest = async (e:ButtonRequestEvent) => {
    // let date_format = await firstDatePayment();
    const _event = e.detail
    const optionalComponents=[]
    const mandatoryComponents=[]
    if(_event.aval){
      const guaranteeVariable = gender==="Femenino" ? 
      guaranteeVariableWoman: guaranteeVariableMan
      const aval:Components={
        statusId:104,
        id:guaranteeVariable?.id,
        value:guaranteeVariable?.value===null?null:guaranteeVariable?.value*100
      }
      optionalComponents.push(aval)
    }
    if(_event.signature){
      const signature:Components={
        statusId:104,
        id:electronicSignature?.id,
        value:electronicSignature?.value
      }
      optionalComponents.push(signature)
    }
    const _interest:Components = {...interest}
    _interest.statusId=104
    _interest.value = _interest?.value===null?null:_interest?.value*100
    mandatoryComponents.push(_interest)
    const _guaranteeFurniture:Components = {...guaranteeFurniture}
    _guaranteeFurniture.statusId=104
    mandatoryComponents.push(_guaranteeFurniture)
    const _guaranteeConstant:Components = {...guaranteeConstant}
    _guaranteeConstant.statusId=104
    _guaranteeConstant.value = _guaranteeConstant.value===null?
    null:_guaranteeConstant?.value*100
    mandatoryComponents.push(_guaranteeConstant)
    const _maxAmount:Components ={
      id:maxAmountSim.id,
      value:maxAmount,
      statusId:104
    }
    mandatoryComponents.push(_maxAmount)
    const _minAmount:Components ={
      id:minAmountSim.id,
      value:minAmount,
      statusId:104
    }
    mandatoryComponents.push(_minAmount)
    const response:LoanValues ={
      mandatoryComponents:mandatoryComponents,
      optionalComponents:optionalComponents,
      loanPurpose: parseInt(_event.loanPurpose, 10),
      amount:_event.amount,
      repaymentsStartingFromDate: _event.repaymentsStartingFromDate,
      term:_event.term,
      interest:_interest.value,
      path:finalPath,
      genderName:gender,
      product:product
      
    } 
    response.mandatoryComponents=mandatoryComponents
    console.log("response",response)
    await submit(response);
    let contador = 0;
    for (const key in loans) {
        if (loans.hasOwnProperty(key)) {
            const objeto = loans[key];
            if (objeto.statusId === 600 || objeto.statusId === 700) {
                contador++;
            }
        }
    }
    const event =
      contador >= 1
        ? 'AprobacionRecurrente'
        : 'AprobacionNuevo';
    sendEventToGTM(event);
    console.log("evento de tag manager =>",event)
    history.push('/login');
  };
  const handleDesition= async (e:ButtonDesitionEvent) => {
    const _event = e.detail
    console.log("informacion de la peticion desition",_event)
    if(_event.jtd_client_decision){
      try{
        await jtdClientDecisionPost({
          'jtd_client_decision':true,
          'jtd_sim_info':_event.jtd_sim_info})
      }
      catch(e){
        await openModalC(messageError).then(
          ({ cancel }) => {
            cancel && history.push('/logout');
          }
        );
        }
     const {accept} = await openModalF(DesitionAccept)
      accept && history.push('/logout'); 

    }else{
      try{
        await jtdClientDecisionPost({
          'jtd_client_decision':false,
          'jtd_sim_info':_event.jtd_sim_info
      })
      }
      catch(e){
        await openModalC(messageError).then(
          ({ cancel }) => {
            cancel && history.push('/logout');
          }
        );
        }
      const {accept} = await openModalF(DesitionDecline)
         accept && history.push('/logout');  
    }
  }
  useEffect(() => {
    const currentSimulator = internalsimulatorRef.current;

    if (currentSimulator) {
        const handleRequestWrapper = (evt: Event) => {
            if ((evt as ButtonRequestEvent).detail) {
                handleRequest(evt as ButtonRequestEvent);
            }
        };
        const handleDesitionWrapper = (evt: Event) => {
          if ((evt as ButtonDesitionEvent).detail) {
             handleDesition(evt as ButtonDesitionEvent);
          }
      };

        currentSimulator.addEventListener('button-request', handleRequestWrapper);
        currentSimulator.addEventListener('desition', handleDesitionWrapper);

        return () => {
            currentSimulator.removeEventListener('button-request', handleRequestWrapper);
        };
    }
}, [shuffledLoanPurpose]);
  const DesitionDecline = {
    main: (
      <p>
       Tu crédito con juancho solo es posible a través
       del convenio con tu empresa.
      </p> 
    ),
    accept: 'OK',
    noClosable: true,
    icone:false,
    title:'No podemos continuar'
  };
  const DesitionAccept = {
    main: (
      <p>
      En cualquier momento te contactaremos.
     </p> 
    ),
    accept: 'OK',
    noClosable: true,
    icone:true,
    title:'No descuides tu correo y celular'
  };
  const messageError={
    main: (
      <div>
          <p>
        Inicia sesión nuevamente para continuar tu solicitud o comunícate con
        nosotros a:
      </p>
      <p>
        <b>WhatsApp: </b>
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
        >
          https://wa.me/5744807833
        </a>
      </p>
      <p>
        <b>Línea gratuita nacional: </b> 018000413811
      </p>
      <p>
        <b>Correo: </b> ayuda@juanchotepresta.com
      </p>
      </div>
    ),
    noClosable: false,
  }
  const shuffle = (array:any) => {
    let currentIndex = array.length;
    let randomIndex;
  
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // Swap
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
  };
  useEffect(() => {
    firstDatePayment().then((resp) => {
      setFirstDatePayment(resp.format('DD-MM-YYYY'));
    });
  }, []);

  useEffect(() => {
    setShuffledLoanPurpose(shuffle([...loanPurpose]));
    riskMatrix[finalPath]['maxAmount'] =
      mark !== 'NA' ? maxRiskQuota : riskMatrix[finalPath]['maxAmount'];
    const fetchData = async () => {
      try {
        pixels.forEach((pixel: Pixel) => {
          if (pixel.screen === 'simulator') {
            let img = document.createElement('img');
            img.src = pixel.url;
            document.body.appendChild(img);
          }
        });
      } catch (error) {
        console.log("algo salio mal")
      }
    };
    fetchData();
  }, []);

  return (
    <Column fluid>
        {shuffledLoanPurpose.length>0 && (
          <>
          <ModalF props={modalF}/>
          <ModalC props={modalC}/>
          <ContainerSimulate>
          <Header></Header>
          <credit-simulator
            simUserMsg={simUserMsg}
            // simUserMsg={"jtdAcceptMsg"}
            terms={terms}
            interest={interest.value}
            electronicSignature={electronicSignature.value} 
            disposableIncome={disposableIncome}
            guaranteeVariableMan={guaranteeVariableMan.value} 
            guaranteeVariableWoman={guaranteeVariableWoman.value} 
            maxAmount={maxAmount} 
            incomeFactor=	{incomeFactor}
            guaranteeConstant={guaranteeConstant.value}
            guaranteeFurniture={guaranteeFurniture.value} 
            minAmount={minAmount}
            gender={gender}
            internalSimulator={true}
            secureWellbeing={null}
            product={product}
            dataItems={JSON.stringify(shuffledLoanPurpose)}
            firstDatePayment={valueFirstDatePayment}
            ref={internalsimulatorRef}
          >
          </credit-simulator>
          </ContainerSimulate>
          </>)
        }
    </Column>)
};

export default connect(
  (state: JTPState, ownProps: any) => ({
    signup: state.requests.signup,
    pixels: state.pixel.listPixel,
    ...ownProps,
  })
)(ConfirmLoanView);