import { LitElement, html} from 'lit-element';
import ayuda from '../images/preguntas-mas-frecuentes.png';
import '../components/popup-services';
import '../components/modal-dialog';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {formatNumber} from '../utils/formatNumber';
import { monthlyQuote } from '../utils/simCalculations';
import {bodyInfoStyles} from './styles/body-info-styles.js'



class BodyInfo extends LitElement {
  static styles = bodyInfoStyles;
  static get properties() {
    return {
      ValueColor: { type: String },
      stateTerm:{type:Number},
      interest:{type:Number},
      electronicSignature:{type:Number},
      disposableIncome:{type:Number},
      maxAmount:{type:Number},
      incomeFactor:{type:Number},
      guaranteeVariable:{type:Number},
      guaranteeConstant:{type:Number},
      guaranteeFurniture:{type:Number},
      amount:{type:Number},
      secureWellbeing:{type:Number},
      checkboxEndorsementState:{type:Boolean},
      checkboxSignatureState:{type:Boolean},
      checkboxSafeState:{type:Boolean},
      _checkboxEndorsementState:{type:Boolean},
      _checkboxSignatureState:{type:Boolean},
      _checkboxSafeState:{type:Boolean},
      terms:{},
      objecMaxAmount:{},
      product:{type:String},
      firstDatePayment:{type:String},
      _firstDatePayment :{type:String},
    };
  }
   constructor() {
    super();
    this.arrowDirection = 'up';
    this.selectorOne = true; 
    this.selectorTwo = false; 
    this.arrowBackgroundColor = '#ffffff';
    this.checkboxesVisible = false;
    this.popupEndorsement = false;
    this.popupSignature = false;
    this.popupSafe = false;
    this.totalDisbursed =0;
    this.monthlyQuote=0;
    this.monthlyVAT=0;
    this.monthlySecureWellbeing=0;
    this.fixedGuarante=0;
    this.variableGuarantee=0;
    this.creditAmortization=0;
    this.checkboxEndorsementState=true;
    this.checkboxSignatureState=true;
    this.checkboxSafeState=true;
    this._checkboxEndorsementState=true;
    this._checkboxSignatureState=true;
    this._checkboxSafeState=true;
    this.firstDatePayment="";
    
    this.ValueColor = "#2F5BFF";
    this.stateTerm=0;
    this.interest=1;
    this.electronicSignature=1;
    this.disposableIncome=1;
    this.guaranteeVariable=1;
    this.maxAmount=1;
    this.incomeFactor=1;
    this.guaranteeConstant=1;
    this.guaranteeFurniture=1; 
    this.amount=1;
    this.secureWellbeing=0;
    this.terms=[];
    this.objecMaxAmount={};
    this.product="";
    this._firstDatePayment="";
  }
  


  getFormData() {
    const data = {
        term: this.stateTerm,
        amount:this.amount,
        secureWellbeing:this.checkboxSafeState,
        signature:this.checkboxSignatureState,
        aval:this.checkboxEndorsementState,
        loanPurpose:null,
        repaymentsStartingFromDate:this.firstDatePayment,
        
    };
    return data;
}

  
  connectedCallback() {
    super.connectedCallback();
    setTimeout(() => {
      const checkboxEndorsement = this.shadowRoot.getElementById('checkboxEndorsement');
      if (checkboxEndorsement) {
        checkboxEndorsement.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxEndorsement', '.endorsement p');
        });
      }
      const checkboxSignature = this.shadowRoot.getElementById('checkboxSignature');
      if (checkboxSignature) {
        checkboxSignature.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxSignature', '.signature p');
        });
      }
      const checkboxSafe = this.shadowRoot.getElementById('checkboxSafe');
      if (checkboxSafe) {
        checkboxSafe.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxSafe', '.safe p');
        });
      }
      this.initializeTooltips();
    }, 0);
  }
  updated(changedProps){
    if(changedProps.has('firstDatePayment')){
      this._firstDatePayment = this.firstDatePayment
    }
    if(changedProps.has('stateTerm')){
      this.handleClickTerm(this.stateTerm) 
    }
    if (changedProps.has('ValueColor')) {
      this.style.setProperty('--product-color', this.ValueColor);
      this.style.setProperty('--arrow-background-color', this.ValueColor);
      this.handleClickTerm(this.stateTerm) 
    }
    if(changedProps.has('_checkboxEndorsementState')||changedProps.has('_checkboxSignatureState')||
      changedProps.has('_checkboxSafeState')){
      if(this._checkboxEndorsementState!==this.checkboxEndorsementState){
       this.handleClosePopupWithoutDelete("endorsement")
      }
      if(this._checkboxSignatureState!==this.checkboxSignatureState){
        this.handleClosePopupWithoutDelete("signature")
       }
       if(this._checkboxSafeState!==this.checkboxSafeState){
        this.handleClosePopupWithoutDelete("safe")
       }

    }
    if(changedProps.has('stateTerm')||changedProps.has('maxAmount')||
    changedProps.has('amount')||changedProps.has('guaranteeVariable')||
    changedProps.has('checkboxEndorsementState')||changedProps.has('checkboxSignatureState')||
    changedProps.has('checkboxSafeState')) {
      if(this.checkboxSignatureState && this.checkboxEndorsementState){
        this._firstDatePayment = this.firstDatePayment
      }else{
        this._firstDatePayment = "indefinido"
      }

      //--------Servicio de Firma Electrónic-----//
      this.monthlyVAT= this.electronicSignature/this.stateTerm

      //-----------Aval fijo-----------//
      this.fixedGuarante = this.amount* this.guaranteeConstant

      //----------Aval variable----------//
      this.variableGuarantee = this.guaranteeVariable * 30 * this.amount

      //-----amortizacion crédito------//
      const interestMonthly = Math.pow(1 + this.interest, 1 / 12) - 1;
      this.creditAmortization = ((interestMonthly * this.amount * Math.pow(1 + interestMonthly, this.stateTerm)) /
      (1 - Math.pow(1 + interestMonthly, this.stateTerm))) * -1
       
      //----------Total desembolsado------//
      if(this.checkboxEndorsementState){
        this.totalDisbursed= this.amount- this.guaranteeFurniture- this.fixedGuarante
      }else{
        this.totalDisbursed= this.amount- this.guaranteeFurniture
      }
      

      this.monthlyQuote = monthlyQuote(
        this.stateTerm,
        this.interest,
        this.amount,
        this.guaranteeVariable,   
        this.electronicSignature,
        this.checkboxSignatureState,
        this.checkboxSafeState,
        this.checkboxEndorsementState,
        this.secureWellbeing,
        this.product
      )
       
      this.requestUpdate();
    }
  }
  firstUpdated(){
  }

  handleOpenPopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = true;
    }
    if(typePopup==="signature"){
      this.popupSignature = true;
    }
    if(typePopup==="safe"){
      this.popupSafe = true;
    }
    this.requestUpdate();
  }
  handleClosePopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = false;
    }
    if(typePopup==="signature"){
      this.popupSignature = false;
    }
    if(typePopup==="safe"){
      this.popupSafe = false;
    }
    this.requestUpdate();
  }
  handleClosePopupWithoutDelete(typePopup) {
    console.log("se ativo handleClosePopupWithoutDelete",typePopup);
    const pSelector= `.${typePopup} p`
    const pTags = this.shadowRoot.querySelectorAll(pSelector);
    if(typePopup==="endorsement"){
      this.popupEndorsement = false;
      this.checkboxEndorsementState = true;
      const checkboxEndorsement = this.shadowRoot.getElementById('checkboxEndorsement');
      
      if (checkboxEndorsement) {
        checkboxEndorsement.checked = true;
      }
    }
    if(typePopup==="signature"){
      this.popupSignature = false;
      this.checkboxSignatureState = true;
      const checkboxSignature = this.shadowRoot.getElementById('checkboxSignature');
      if (checkboxSignature) {
        checkboxSignature.checked = true;
      }
    }
    if(typePopup==="safe"){
      this.popupSafe = false;
      this.checkboxSafeState = true;
      const checkboxSafe = this.shadowRoot.getElementById('checkboxSafe');
      if (checkboxSafe) {
        checkboxSafe.checked = true;
      }
    }
    pTags.forEach(p => {
      p.classList.remove('subrayado');
    });
    this.requestUpdate();
  }
  handleClickTerm(buttonNumber) {
    this.stateTerm = buttonNumber;
    this.arrowBackgroundColor = this.ValueColor;
    this.dispatchEvent(new CustomEvent('stateTermChanged', { detail: this.stateTerm }))
    this.requestUpdate();
  }
  handleButtonClick() {
    // Cambiar el estado de visibilidad de los checkboxes
    this.checkboxesVisible = !this.checkboxesVisible;
    // Solicitar una actualización de la vista
    this.requestUpdate();
  }
  handleCheckboxChange(checkboxId, pSelector) {
    const checkbox = this.shadowRoot.getElementById(checkboxId);
    const pTags = this.shadowRoot.querySelectorAll(pSelector);
    if (!checkbox.checked) {
      if(checkboxId==="checkboxEndorsement"){
        this.handleOpenPopup("endorsement") 
        this.checkboxEndorsementState=false
      }
      if(checkboxId==="checkboxSignature"){
        this.handleOpenPopup("signature") 
        this.checkboxSignatureState=false
      }
      if(checkboxId==="checkboxSafe"){
        this.handleOpenPopup("safe") 
        this.checkboxSafeState=false
      }
      pTags.forEach(p => {
        p.classList.add('subrayado');
      });
    } else {
      if(checkboxId==="checkboxEndorsement"){
        this.handleClosePopup("endorsement")
        this.checkboxEndorsementState=true
      }
      if(checkboxId==="checkboxSignature"){
        this.handleClosePopup("signature")
        this.checkboxSignatureState=true
      }
      if(checkboxId==="checkboxSafe"){
        this.handleClosePopup("safe")
        this.checkboxSafeState=true
      }
      pTags.forEach(p => {
        p.classList.remove('subrayado');
      });
    }
  }
  initializeTooltips() {
    
    const tooltips = [
      { selector: '.disbursement-info', content: `<h3>Total desembolsaso</h3> 
        <p>Es la cantidad de dinero que recibes una vez que tu crédito
            es aprobadi.<strong>Este monto es menor al que solicitaste,</strong> ya que descuentan los costos
          asociados al aval fijo (opcional) y al registro de garantia mobiliaria.</p>` },
      { selector: '.monthly-quote-info', content: `<h3>Cuota mensual</h3><p>Es el pago que debes realizar cada mes para devolver el dinero prestado
                . La cuota mensual incluye:<br><br>
                <strong>Intereses:</strong>costo adicional que pagas por el dinero que has prestado.<br><br>
                <strong>Amortización de capital:</strong> disminución gradual de tu deuda debido al pago que haces cada mes de tu cuota.
                Nuestro modelo utiliza la fórmula de cuota fija.<br><br>
                <Strong>Aval(opcional):</Strong> Es una garantía en la que un tercero se compromete a pagar el crédito en caso de que tú no
                cumplas con tu oblicación.<br><br>
                <strong>Firma electrónica:</strong> Con este servicio, puedes firmar electrónicamente todos los documentos esenciales para
                la aceptación y desembolso de tu crédico.</p>` },
      { selector: '.first-date-pay', content: `<h3>Total desembolsaso</h3> <p>Es el día en que debes hacer tu primer pago después de obtener el crédito.</p>` },
      { selector: '.guarantee-furniture-info', content: `<h3>Total desembolsaso</h3><p>
                es un respaldo para tu crédito que involucra bienes muebles.
                En caso de incumplimiento, Juancho Te Presta puede recuperar estos bienes para cubrir la deuda.</p>` },
      { selector: '.endorsement-info', content: `<h3>Aval (opcional)</h3> <p>
                Es una garantía de pago que otorgas a través de un tercero.
                Si tu solicitud es aprobada, te ofrecemos proveedores con los cuales puedes contratar el aval. Estos avalistas cobran dos tipos
                avales:<br><br>
                <strong>Aval Fijo:</strong>Es un porcentaje del monto aprobado de tu crédito y se descuenta antes del desembolso.
                <br><br> <Strong>Aval variable:</Strong> Es un porcentaje que cambia de acuerdo al monto solicitado y plazo del crédito.
                Se incluye en tu cuota mensual.</p>` },
      { selector: '.firma-electronica', content: `<h3>Frima electrónica (opcional):</h3> <p>Con este servicio, puedes firmar electrónicamente todos los documentos esenciales para la aceptación y desembolso
                de tu crédito. Esto incluye el acuerdo económico, contrato de garantía crediticia,
                aviso de privacidad de la compañia avaladora, contrato de garantía mobiliaria,
                autorización de débito automático, poder irrevocable para suscribir libranza, 
                pagaré y carta de instrucciones.<br><br>
                si deseas evitar este costo y ya tienes un certificado de firma digital vigente, envíanos todos
                los documentos al correo electrónico ayuda@juanchotepresta.com.<br><br>
                En caso de que no puedas obtener un certificado de firma digital vigente de una entidad
                certificadora, envíanos todos los documentos físicos y autenticados ante notaria a la dirección indicada en el acuerdo
                económico.</p>`},
      { selector: '.safe-info', content: 'Con este servicio puedes obtener un seguro para tu bienestar.' },
      { selector: '.informacion-legal',content: `<h3>informacion-legal</h3> <p>JuanchoTePresta.com, pone a tu disposición el presente Simulador en 
                    los términos de Ley, el cual tiene fines informativos y no comporta
                    oferta o promesa de contratar. Este simulador es indicativo y está destinado a proporcionarte información y 
                    estimaciones de carácter general. El resultado de esta simulación no comprende una certificación o recomendación 
                    comercial, contable, tributaria o legal. Los términos de esta simulación son suministrados con base en las
                     condiciones comerciales y de mercado que han sido establecidas para la fecha en que realizas esta simulación.
                      La tasa de interés remuneratoria corresponderá a la que JUANCHO TE PRESTA SAS tenga establecida al momento
                       de tu utilización. Otros componentes del crédito dependen de terceros y pueden variar según tu nivel de
                        riesgo y capacidad de pago.</p>` }
    ];

    tooltips.forEach(({ selector, content }) => {
      const elements = this.shadowRoot.querySelectorAll(selector);
      elements.forEach(el => {
        tippy(el, {
          content: content,
          animation: 'scale',
          allowHTML: true,
          theme: 'light',
          maxWidth: 300,
          onShow(instance) {
            const tippyBox = instance.popper.querySelector('.tippy-box');
            tippyBox.style.backgroundColor = 'rgba(240, 240, 240, 0.7)';
            tippyBox.style.backdropFilter = 'blur(5px)';
            tippyBox.style.borderRadius = '15px'; // Aumenta este valor para un borde más redondeado
          },
        });
      });
    });
  }

  render() {
    
    return html`
      <div class="container">
      <popup-services valueColor=${this.ValueColor} 
              .isOpen=${this.popupEndorsement}
              @close-popup="${()=>this.handleClosePopup("endorsement")}" 
              @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("endorsement")}">
              <p class="popup-windows">El servicio de Aval te permite pedir el crédito sin 
                codeudor y agilizar los tiempos de desembolso.
              </p> <div slot="title">Aval</div>
              <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
                adipisicing elit. Autem dicta repellendus quia sunt quod,
                sed dolore harum nulla labore tenetur eum maiores perspiciatis
                molestiae dolorum corrupti, doloremque consequuntur veritatis
                quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
                molestiae beatae animi, laboriosam placeat atque error culpa
                aspernatur quas ad dolore accusantium harum excepturi!</div>
        </popup-services>
        <popup-services 
                valueColor=${this.ValueColor}  
                .isOpen=${this.popupSignature}
                @close-popup="${()=>this.handleClosePopup("signature")}"
                @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("signature")}"
                ><p class="popup-windows">El servicio de Firma 
                  Electrónica te evita el papeleo y nos permite desembolsarte en 24 horas. 
                </p> <div slot="title">Firma electrónica</div>
                <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
                adipisicing elit. Autem dicta repellendus quia sunt quod,
                sed dolore harum nulla labore tenetur eum maiores perspiciatis
                molestiae dolorum corrupti, doloremque consequuntur veritatis
                quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
                molestiae beatae animi, laboriosam placeat atque error culpa
                aspernatur quas ad dolore accusantium harum excepturi!</div>
          </popup-services>
          <popup-services 
                valueColor=${this.ValueColor}
                .isOpen=${this.popupSafe}
                @close-popup="${()=>this.handleClosePopup("safe") }"
                @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("safe")}"
                ><p class="popup-windows">El servicio de seguro bienestar
                  te cuida en tus momentos mas dificiles.
                </p> <div slot="title">seguro bienestar</div>
                <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
                adipisicing elit. Autem dicta repellendus quia sunt quod,
                sed dolore harum nulla labore tenetur eum maiores perspiciatis
                molestiae dolorum corrupti, doloremque consequuntur veritatis
                quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
                molestiae beatae animi, laboriosam placeat atque error culpa
                aspernatur quas ad dolore accusantium harum excepturi!</div>
          </popup-services>
        <div class="content-terms-button">
        ${this.terms.map((term) => html`
            <button class="button-term  ${this.stateTerm === term ? 'selected' : ''}" @click="${() => this.handleClickTerm(term)}">${term} </button>
          `)}
        </div>
        <div class="content-arrows">
          ${this.terms.map((term)=> html`
          <div class="arrows">
            <div class="arrow" style="--arrow-background-color: ${this.stateTerm === term ? this.arrowBackgroundColor : '#ffffff'}"></div>
          </div>
          `)}
        </div>
        <div class="first-box">
          <!-- <p>Te desembolsaremos <img class="help-image" src="${ayuda}" alt="ayuda"  @click="${this.handleHelpClick}"/> </p> -->
          <div class="sub-first-box-one">
            <div class="container-info">
              <p>Te desembolsaremos </p>
              <span class="disbursement-info help">?</span>
            </div>
            <p>$${formatNumber(this.totalDisbursed)}</p>
          </div>
          <div class="line"></div>
          <div class="sub-first-box">
            <div class="into-sub-first-box">
              <div class="container-info">
                <p>Cuota mensual</p>
                <span class="monthly-quote-info help">?</span>
              </div>
              <p>$${formatNumber(this.monthlyQuote)}</p>
              
            </div>
            <div class="vertical-line"></div>
            <div class="into-sub-first-box">
              <div class="container-info">
                <P>Pago primera cuota</P>
                <span class="first-date-pay help">?</span>
              </div>
              <p> ${this._firstDatePayment} </p>
              
            </div>
          </div>
          <div class="interest-rate">
            <p>Tasa de interés: mensual: 2.53% - Anual: 34.99%</p>
          </div>
        </div>
        <h5>Esto restamos al desembolsar tu crédito:</h5>
        <div class="second-box">
          <ul>
            <div class="continer-warranty border__bottom">
              <div class="container-info">
                <h6>Garantía Mobiliaria</h6>
                <span class="guarantee-furniture-info help-two">?</span>
              </div>
              <p class="continer-warranty--p">$${formatNumber(this.guaranteeFurniture)}</p>
            </div>
            <li class="checkbox-container endorsement" >
              <input class="${this.checkboxesVisible ? '' : 'hidden'}" type="checkbox" checked id="checkboxEndorsement">
              <label><p> Aval *</p>
                <span class="endorsement-info help-two">?</span>
              </label>
            </li>
            
            <div class="continer-warranty endorsement">
              <div class="point"></div>
              <div class="line-between-points"></div>
              <p>Aval fijo *</p>
              <p>$${formatNumber(this.fixedGuarante)}</p>
            </div>
          </ul>
        </div>
        <h5>Esto pagas cada mes:</h5>
        <div class="second-box">
          <ul>
           <div class="continer-warranty endorsement border__bottom">
              <div class="point"></div>
              <p>Aval Variable *</p>
              <p>$${formatNumber(this.variableGuarantee)}</p>
            </div>
            <div class="continer-warranty border__bottom">
              <li class="checkbox-container" >
                <label> <p class="continer-warranty--p"> 
                  <strong>Amortización crédito</strong> </p>  
                  <span class="informacion-legal help-two">?</span>
                </label>
              </li>
              <p class="continer-warranty--p">$${formatNumber(Math.round(this.creditAmortization))}</p>
            </div>
            <div class="continer-warranty signature border__bottom">
              <li class="checkbox-container" >
                <input class="${this.checkboxesVisible ? '' : 'hidden'}" type="checkbox" checked id="checkboxSignature">
                <label><p class="continer-warranty--p"> <strong>Firma electrónica * </strong> </p>
                  <span class="firma-electronica help-two">?</span>
                </label>
              </li>
              <p class="continer-warranty--p">$${formatNumber(Math.round(this.monthlyVAT))}</p>
            </div>
            ${this.product!=="JTD"&&this.secureWellbeing>0?html`
            <div class="continer-warranty safe">
              <li class="checkbox-container" >
                <input class="${this.checkboxesVisible ? '' : 'hidden'}" type="checkbox" checked id="checkboxSafe">
                <label> <p class="continer-warranty--p"> 
                  <strong>Seguro de bienestar *</strong> </p>  
                  <span class="safe-info help-two">?</span>
                </label>
              </li>
              <p class="continer-warranty--p">$${formatNumber(this.secureWellbeing)}</p>
              
            </div>
            `:''}   
          </ul>
          <button  @click="${this.handleButtonClick}">* Modificar servicios opcionales
            ${this.checkboxesVisible?"<":">"}
          </button>
        </div>
      </div>
    `;
  }
}

customElements.define('body-info', BodyInfo);
