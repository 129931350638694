import { ValuesPach } from '../services/changeLoanValuesPost';
import serviceBuilder from './serviceBuilder';


export const url = 'sec/loans/application';

export default serviceBuilder<ValuesPach>('patch', {
  url,
  auth: true,
});
