import { ComponentsGet } from '../services/changeLoanValuesPost';


interface SimulatorComponent {
  name: string;
  value: number;
  id: number;
}

interface Summary {
  optionalComponents: { name: string }[];
  genderName: string;
}

interface CalculationResult {
  optionalComponents: ComponentsGet[];
  existsElectronicSignature: boolean;
  existsGuaranteeVariable: boolean;
}

const calculateOptionalComponents = (simulatorComponents: SimulatorComponent[], summary: Summary, statusId:number): CalculationResult => {
  const getValues = (name: string) => {
    const component = simulatorComponents.find((comp) => comp.name === name);
    return component ? { value: component.value, id: component.id, name: component.name } : { value: 0, id: 0, name: "" };
  };

  const summaryOptionalComponents = summary.optionalComponents;
  const typeGuaranteeVariable = summary.genderName === "Femenico" ? 'guaranteeVariableWoman' : 'guaranteeVariableMan';
  const existsElectronicSignature = summaryOptionalComponents.some(component => component.name === "electronicSignature");
  const existsGuaranteeVariable = summaryOptionalComponents.some(component => component.name === typeGuaranteeVariable);

  const optionalComponents: ComponentsGet[] = [];

  if (!existsElectronicSignature) {
    const simElectronicSignature = getValues('electronicSignature');
    const signature: ComponentsGet = {
      name: simElectronicSignature.name,
      statusId: statusId,
      id: simElectronicSignature.id,
      value: simElectronicSignature.value
    };
    optionalComponents.push(signature);
  }

  if (!existsGuaranteeVariable) {
    const simGuaranteeVariableMan = getValues('guaranteeVariableMan');
    const simGuaranteeVariableWoman = getValues('guaranteeVariableWoman');
    const guaranteeVariable = summary.genderName === "Femenino" ? simGuaranteeVariableWoman : simGuaranteeVariableMan;
    const aval: ComponentsGet = {
      name: guaranteeVariable.name,
      statusId: statusId,
      id: guaranteeVariable.id,
      value: guaranteeVariable.value === 0 ? 0 : guaranteeVariable.value * 100
    };
    optionalComponents.push(aval);
  }
  console.log("existsElectronicSignature function",existsElectronicSignature)
  console.log("existsGuaranteeVariable fuction",existsGuaranteeVariable)

  return {
    optionalComponents,
    existsElectronicSignature,
    existsGuaranteeVariable,
  };
};

export default calculateOptionalComponents;