import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import calculateOptionalComponents from '../../components/OptionalComponentsCalculator';
import Button from '../../components/UI/Button';
import ButtonGeneric from '../../components/UI/ButtonGeneric';
import GeneralContent from '../../components/UI/GeneralContent';
import { MessagesFormat } from '../../components/UI/MessagesViews';
import { ModalC, useModalC } from '../../components/UI/Modal';
import { ModalSendEmail, useModalSendEmail } from '../../components/UI/ModalSendEmail';
import NavLink from '../../components/UI/NavLink';
import { Spinner } from '../../components/UI/Spinner';
import { SpinnerPro } from '../../components/UI/SpinnerPro';
import {
  Alert,
  ContentAval,
  PrincipleMessage,
  Resend,
  SubMessage2,
  TitleSend,
  WatchFlash
} from '../../components/UI/StylesOptionality';
import sendEventToGTM from '../../helpers/tagManager';
import alert from '../../images/icons/1_Icono-alerta.svg';
import watchFlash from '../../images/icons/2_Icono-relojflash.svg';
import { ComponentsGet, ValuesPach } from '../../services/changeLoanValuesPost';
import loanGetUuid, { Loan } from '../../services/loanGetUuid';
import loanListGet from '../../services/loanListGet';
import { LoanList } from '../../services/loansPayment';
import pixelGet from '../../services/pixelGetUuid';
import applicationGet from '../../services/simpleApplicationGet';
import applicationPatch from '../../services/simpleApplicationPatch';
import simpleLoanSignaturePost from '../../services/simpleLoanSignaturePost';
import updateLoanStatusP from '../../services/updateLoanStatusPatchPayload';
import { JTPState } from '../../store';
import { asyncDispatch } from '../../store/asyncDispatch';
import { updateLoanStatus } from '../../store/reducers/updateLoanStatusReducer';
import { simulatorSelector } from '../../store/selectors/simulatorSelector';
import styled from '../../styles/styled-components';
import { ErrorMessageConfig, ErrorWithEmail, FinishMessage } from './Signature/SignatureView';
const PredisburseStyled = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 15px;

  .desc {
    padding: 15px;
    text-align: center;
    padding-bottom: 80px;
    font-weight: 500;
    font-size: 22px;
    color: ${(props) => props.theme.gray500};
  }
`;

interface Props extends RouteComponentProps<{ uuid: string }> {
  submit(payload: { uuid: string }): Promise<void>;
}

const HireAll = () => (
  <GeneralContent>
    <MessagesFormat>
      <p>
        <strong>Firmaste tus documentos exitosamente, </strong>
        vamos a revisar que todo esté correcto. En caso de necesitar información
        adicional te contactaremos.
      </p>
      <p>Si todo está bien desembolsaremos tu crédito pronto.</p>
      <ButtonGeneric  textcolor="black"  as={NavLink}  to="/logout">
          Salir ahora
      </ButtonGeneric>
    </MessagesFormat>
  </GeneralContent>
);
const LoadingComponent = () => (
  <>
    <Spinner size={64} />
    <div className="desc">Actualizando estado de tu préstamo...</div>
  </>
);

const ErrorComponent = ({ error }: { error: string }) => (
  <>
    <div className="desc">
      <p>{error}</p>
      <p>Recuerda hacer click en el último correo que recibas.</p>
    </div>
    <Button expand outline color="red" as={Link} to="/">
      Salir
    </Button>
  </>
);

const PredisburseView: React.FC<Props> = ({ submit, match,history}) => {
  const [state, setstate] = useState({
    status: 'loading',
    msj: '',
  });
  const [daysCounter, setDaysCounter] = useState(0);
  const [addServices, setAddServices] = useState(false)
  const [stateToken, setStateToken] = useState("")
  const [opComponents, setOpComponents] = useState<ComponentsGet[]>()
  const simulator = useSelector(simulatorSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, openModal] = useModalC();
  const [modalSend, openModalSend] = useModalSendEmail();

  const handleClickResend = useCallback(async () => {
    setIsLoading(true); 
    try {
      await simpleLoanSignaturePost(
            stateToken,
            { 
              url: window.location.origin + '/loans/predisburse'
            });
    } 
    catch (e) {
      const error = e as ErrorWithEmail;
      await openModal(ErrorMessageConfig(error.errors.email));
      return;
    }
    await openModalSend(FinishMessage("¡Genial!"));
    setIsLoading(false);
  },[openModal,openModalSend,stateToken]);

  const handleClickAddServices= async()=>{
    setAddServices(true)
    const responsePayload:ValuesPach={
      optionalComponents:opComponents,
    }
    await applicationPatch(stateToken,responsePayload)
    history.push('/login');
  }
  const HireJustSign = ({daysCounter }: { daysCounter: number })=>(
    <GeneralContent>
      <Alert src={alert} alt='icono  de alerta'/>
      <TitleSend>{daysCounter === 1 ?<>¡Te queda {daysCounter} día!</>:
        <>¡Te quedan {daysCounter} días!</>}</TitleSend>
      <PrincipleMessage>
          Estamos esperando que gestiones el aval por tu cuenta,
          ya que decidiste no contratar nuestro servicio:
      </PrincipleMessage>
      <ContentAval>
          <h3>Aval</h3>
          <p>Debes buscar un codeudor</p>
      </ContentAval>
      <PrincipleMessage> Busca el correo llamado "Contratación de Aval" y
        sigue las instrucciones.
      </PrincipleMessage>
      <Resend>
            {isLoading ? (<>
            <p>¿No encuentras el correo? </p> <SpinnerPro size={44} /> </>// Muestra el spinner mientras carga
          ) : (<p>¿No encuentras el correo? <strong onClick={handleClickResend }>Reenvíalo.</strong></p>)}
            
        </Resend>
      <SubMessage2>
               <WatchFlash src={watchFlash} alt="reloj flash" />
              <p>Agrega los servicios opcionales para que te 
                desembolsemos en 24 horas
              </p>
            </SubMessage2>
            <ButtonGeneric
               margin={true}
               loading={addServices}
               onClick={handleClickAddServices}
               textcolor="black">¡Agregar ahora!</ButtonGeneric>
    </GeneralContent>
  )
  useEffect(() => {
    if (simulator.components.length<=0) return; 
    (async () => {
      try {
        const loan: Loan = (
          await loanGetUuid({
            uuidToken: match.params.uuid,
          })
        ).payload;
        if (loan.loanStatus === 'Approved pre-disburse') {
          setstate({
            status: 'hireAll',
            msj: '',
          });
        } else 
        if (loan.loanStatus !== 'Approved and awaiting signature') {
          setstate({
            status: 'error',
            msj: 'La solicitud de crédito no se encuentra pendiente de firma',
          });
        } else {
          try {
            const result = (await updateLoanStatusP(match.params.uuid)).payload;
            const application = (await applicationGet(result.token)).payload;
            const { optionalComponents,
                    existsElectronicSignature, 
                    existsGuaranteeVariable } = calculateOptionalComponents(simulator.components, application,203);
            const loans: LoanList = (await loanListGet(match.params.uuid))
              .payload;
            setStateToken(result.token)
            setDaysCounter(application.daysCounter)
            setOpComponents(optionalComponents)
              let contador = 0;
              for (const key in loans) {
                  if (loans.hasOwnProperty(key)) {
                      const objeto = loans[key];
                      if (objeto.statusId === 600 || objeto.statusId === 700) {
                          contador++;
                      }
                  }
              }
              const event =
                contador >= 1
                  ? 'PredesembolsoRecurrente'
                  : 'PredesembolsoNuevo';
              sendEventToGTM(event);
              if(existsElectronicSignature && existsGuaranteeVariable){
                setstate({
                  status: 'hireAll',
                  msj: '',
                });
              }
              if(existsElectronicSignature && !existsGuaranteeVariable){
                setstate({
                  status: 'hireJustSign',
                  msj: '',
                });
              }
            let { pixels } = (
              await pixelGet({
                tokenUuid: match.params.uuid,
              })
            ).payload;

            Object.keys(pixels).map(function (key) {
              let pixel = pixels[key];
              if (pixel.screen === 'Predisburse') {
                let img = document.createElement('img');
                img.src = pixel.url;
                document.body.appendChild(img);
              }
              return key;
            });
          } catch (error) {
            let msj =
              error.code === 409
                ? 'Tu tiempo para firmar ha caducado, inicia sesión para solicitar un nuevo link de firma.'
                : 'Servicio no disponible, por favor vuelva a intentarlo más tarde.';
            setstate({
              status: 'error',
              msj: msj,
            });
          }
        }
      } catch (error) {
        setstate({
          status: 'error',
          msj: 'Ha ocurrido un error al intentar firmar el credito.',
        });
      }
    })();
  }, [submit, match.params.uuid,simulator]);

  return (
    <PredisburseStyled>
      <ModalC props={modal} />
      <ModalSendEmail props={modalSend} />
      {state.status === 'loading' && <LoadingComponent />}
      {state.status === 'hireAll' && <HireAll />}
      {state.status === 'hireJustSign' && <HireJustSign daysCounter={daysCounter} />}
      {state.status === 'error' && <ErrorComponent error={state.msj} />}
    </PredisburseStyled>
  );
};

export default connect(
  (_: JTPState, ownProps) => ownProps,
  (dispatch) => ({
    submit: asyncDispatch(dispatch, updateLoanStatus.actions.request),
  })
)(PredisburseView);
