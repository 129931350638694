import { LitElement, html, css } from 'lit-element';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import '../components/popup-services';
import '../components/popup-important';
import '../components/modal-dialog';
import {formatNumber} from '../utils/formatNumber';
import { monthlyQuote } from '../utils/simCalculations';
import check from '../images/check-green.svg'


class SimulatorSummary extends LitElement {
  static styles = css`
    :host {
      --product-color:#2F5BFF;
      --paragraph-color:#ffffff;
      --gray-color:#6c757d;
      --white-color:#ffffff;
      --pastel-blue:#9DABD7;
      --light-blue-gray:#CED5F0;
      --black-color:#000000;
      --color-btn-reques: #FFE331;
      --color-yellow :#FFCD00;
      --color-mint-green:#45DC91;
      --color-cool-gray:#DFE1E5;
      --color-sunset-orange:#FF7664;
      display: inline-block;
      --color-low-green:#27AD68;
      --color-high-green:#7BFFBD;
    }
    .main-continer{
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      width:100%;
    }
    .warning{
      background-color:var(--color-high-green);
      max-width:465px;
      padding:0 18px;
      display:flex;
      align-items:center;
    }
    .warning-check{
      width:47px;
      height:47px;
      margin-right:10px;
    }

    .container-all{
      padding:0 50px;
      display:flex;
      flex-direction:column;
      max-width:400px;
      h4{
        font-size:26px;
        margin:20px 0 0 0;
      }
    }
  
    .content-arrows{
      position:relative;
      display:flex;
      justify-content:center;
      gap:30px;
      height:25px;
    }
    .first-box{
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      background-color:var(--product-color);
      border-radius: 25px;
      color:white;
      justify-content:flex-start;
      padding:20px;
      p{
        font-weight:250; 
        font-size: 14px; 
        line-height: 14px; 
        margin:1px 0 7px 0;
        padding:0;
      }
    }
   
    .sub-first-box{
      margin:7px 0 0 0;
      width:100%;
      position:relative;
      display:flex;
      justify-content:space-between;
      p{
        display:flex;
        font-weight: 500; 
        font-size: 16px; 
        line-height: 15px; 
        margin:2px 0;
        padding:0;
        flex-direction:row;
        align-items:center;
      }
    }
    .title-amount p:first-child {
          margin:0;
          font-weight: 600; 
          font-size: 20px;   
          line-height: 20px; 
          padding:0;
    }
    .horizontal{
      height:1px;
      background-color:rgba(255, 255, 255, 0.5);
      margin-top:7px;
      width:100%;
    }
    .vertical-line{
      margin-top:10px;
      width: 1px; 
      height: calc(100% - 20px);
      background-color: var(--line-gray);
    }
    .checkbox-container {
      display: flex;
      align-items: center;
    }
    .hidden {
      display: none;
    }
    
    .second-box{
      max-width:400px;
      display:flex;
      flex-direction:column;
      padding: 15px 0;
      border-radius:15px;

      h5{
        justify-content:flex-start;
        align-items:flex-start;
        margin: 8px 0; 
      }
      label{
        display:flex;
        justify-content:center;
        align-items:center;
        font-weight: 600; 
        font-size: 13px; 
        line-height: 20.68px; 

      }
      .change-services {
        margin-top:1rem;
        width:100%;
        text-align:center;
        border: none; 
        background: none;
        padding: 0; 
        font: inherit; 
        cursor: pointer; 
        text-decoration: underline;
        display: inline-block; 
        position: relative; 
        color:var(--gray-color);
        font-size:0.7rem;
      }
    }
    .second-box > h4:first-child {
        display:flex;
        align-items:center;
        margin:8px 0;
        font-size: 16px; 
        color:var(--product-color);
        padding:0;
    }
    .movable-guarantee{
      position:relative;
      display:flex;
      justify-content:space-between;
      padding-bottom:10px;
      border-bottom: solid 1px black;
      margin-bottom:10px;
      p{
        display:flex;
        justify-content:center;
        align-items:center;
        font-weight: 400; 
        font-size: 13px; 
        margin:0
      }
      

    }
    
    .continer-warranty{
      margin-top:15px;
      position:relative;
      display:flex;
      justify-content:space-between;
      h6{
        
        justify-content:center;
        align-items:center;
        font-weight: 700; 
        font-size: 13px; 
        margin:0 2px;
      }
      p{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size: 13px; 
        margin:0
      }
    }
    .endorsement-space{
      width:25px;
    }
    .main-container-endorsement {
      display: flex;
      width: 100%; /* Asegura que ocupe todo el ancho disponible */
    }

    .main-container-endorsement > div:first-child {
      flex-shrink: 0; /* Asegura que el primer div (h) no se reduzca */
    }

    .main-container-endorsement > div:last-child {
      flex-grow: 1; /* Asegura que el último div ocupe el espacio disponible */
      display: flex;
      flex-direction: column; /* Si necesitas que los elementos internos se apilen verticalmente */
    }
    .endorsement p{
      line-height: 20px;   
    }
    .continer-endorsement{
      position:relative;
      display:flex;
      justify-content:space-between;
      align-items: center;
    }
    .container-endorsements{
      display:flex;
      flex-direction:column;
    }
    .continer-endorsement p {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin: 0 0 0 10px;
    }
    .line-between-points{
      position:absolute;
      width:1.5px;
      height:28px;
      top:1px;
      left:4px;
      background-color:var(--light-blue-gray);
    }
    .subrayado {
      background-image: linear-gradient(to bottom, transparent 50%, var(--black-color) 50%);
      background-size: 100% 2px; 
      background-repeat: no-repeat;
      background-position: 0 50%;
    }
    .checkbox-container input[type="checkbox"] {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 18px;
      height: 18px;
      background-color: var(--color-cool-gray); 
      /* border: 2px solid var(--gray-color); */
      border-radius: 3px;
      transition: background-color 0.3s;
      position: relative;
      /* margin-left: 8px; */
    }

    .checkbox-container input[type="checkbox"]:checked {
      background-color: var(--gray-color); 
      border-color: var(--gray-color);
    }

    .checkbox-container input[type="checkbox"]:checked::before {
      content: '✔';
      color: var(--white-color); 
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight:700;
    }
    .message{
        background-color:var(--color-yellow);
        color:black;
        margin-left:10px;
    }
    .sub-message-one{
      background-color:var(--color-yellow);
      margin-top:10px;
      color:black;
    }
   
    .sub-message-two{
      background-color:var(--color-mint-green);
      color:black;
      margin-left:10px;
    }
    .sub-message-three{
      background-color:var(--color-sunset-orange);
      color:white;
      margin-left:10px;
    }
    .help{
      font-size:12px;
      background-color:rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      margin: 5px 5px;
      padding: 0 4px 3px 4px;
      line-height: 9px; 
      color:white;
    }
    .help-two{
      font-size:14px;
      font-weight: 400;  
      background-color:var(--product-color);
      border-radius: 50%;
      margin: 0 5px;
      padding: 2px 3px 0 3px;
      color:white;
      line-height:11px; 
    }
    .help-three{
      font-size:12px;
      background-color:var(--color-cool-gray);
      border-radius: 50%;
      margin: 5px 5px;
      padding: 0 4px 3px 4px;
      line-height: 9px; 
      color:white;
    }
    .set-label{
      label{
        padding:1px 7px;
        border-radius:15px;
        font-weight: 400; 
        font-size: 11px; 
        line-height: 15.5px; 
      }
      p{
        font-size:13px;
        margin:0;
        padding:0;
      }
    }
    .important{
      display:flex;
      justify-content:center;
      align-items:center;
      font-size:0.7rem;
      color:var(--gray-color);
      padding:10px 30px 0 30px;
      text-align:center;

      strong{
        cursor:pointer;
        color:var(--product-color);
      }
    }
    .padding-checkbox{
      margin-left:7px;
    }

    .btn-request {
      display:flex;
      align-items:center;
      background-color: var(--color-yellow);
      border:none;
      border-radius: 25px;
      margin-top: 20px;
      height: 50px;
      cursor:pointer;
      font-weight: 600;
      padding: 0 15px 0 15px;
      font-size:25px;
      width:200px;
      line-height: 35px; 
      span{
        margin-left:30px;
        font-size:45px;
        font-weight:200;
        line-height: 35px; 
        /* padding:40px 0 0 0; */
        }
      }
      .popup-windows{
        line-height:13px; 
        font-size:13px;
    }
    @media (max-width: 550px){
      .container-all{
        padding:0 10px;
      }
    }
   
  `;
  static get properties() {
    return {
      valueColor: { type: String },
      term:{type:Number},
      interest:{type:Number},
      electronicSignature:{type:Number},
      guaranteeVariable:{type:Number},
      guaranteeConstant:{type:Number},
      guaranteeFurniture:{type:Number},
      amount:{type:Number},
      secureWellbeing:{type:Number},
      product:{type:String},
      genderName:{type:String},
      firstDatePayment:{type:String},
      _checkboxEndorsementState:{type:Boolean},
      _checkboxSignatureState:{type:Boolean},
      _checkboxSafeState:{type:Boolean},
      checkboxEndorsementState:{type:Boolean},
      checkboxSignatureState:{type:Boolean},
      checkboxSafeState:{type:Boolean},
      _firstDatePayment :{type:String},
      isButtonDisabled: { type: Boolean },
      existAddOptinalServices: { type: Boolean },
    };
  }
   constructor() {
    super(); 
    this.checkboxesVisible = false;
    this.popupEndorsement = false;
    this.popupSignature = false;
    this.popupSafe = false;
    this.popupImportant = false;
    this.totalDisbursed =0;
    this.monthlyQuote=0;
    this.monthlyVAT=0;
    this.fixedGuarante=0;
    this.variableGuarantee=0;
    this.creditAmortization=0;
    this.checkboxEndorsementState=false;
    this.checkboxSignatureState=false;
    this.checkboxSafeState=false;
    this._checkboxEndorsementState=false;
    this._checkboxSignatureState=false;
    this._checkboxSafeState=false;
    this.firstDatePayment="";
    this.genderName="Masculino";
    this.existAddOptinalServices=false;
    
    this.valueColor = "#2F5BFF";
    this.term=0;
    this.interest=1;
    this.electronicSignature=1;
    this.guaranteeVariable=1;
    this.guaranteeConstant=1;
    this.guaranteeFurniture=1; 
    this.amount=1;
    this.secureWellbeing=1;
    this.product="";
    this._firstDatePayment="";
    this.isButtonDisabled = false; 
  }
  getFormData() {
    const data = {
        term: this.term,
        amount:this.amount,
        secureWellbeing:this.checkboxSafeState,
        signature:this.checkboxSignatureState,
        aval:this.checkboxEndorsementState,
        loanPurpose:null,
        repaymentsStartingFromDate:this.firstDatePayment,
        
    };
    return data;
}

  
  connectedCallback() {
    super.connectedCallback();
    setTimeout(() => {
      const checkboxEndorsement = this.shadowRoot.getElementById('checkboxEndorsement');
      if (checkboxEndorsement) {
        checkboxEndorsement.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxEndorsement', '.endorsement p');
        });
      }
      const checkboxSignature = this.shadowRoot.getElementById('checkboxSignature');
      if (checkboxSignature) {
        checkboxSignature.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxSignature', '.signature p');
        });
      }
      const checkboxSafe = this.shadowRoot.getElementById('checkboxSafe');
      if (checkboxSafe) {
        checkboxSafe.addEventListener('change', () => {
          this.handleCheckboxChange('checkboxSafe', '.safe p');
        });
      }
      this.initializeTooltips();
    }, 0);
    
  }
  updated(changedProps){
    // console.log("propiedades del resumen",changedProps)
    if(changedProps.has('firstDatePayment')){
      this._firstDatePayment = this.firstDatePayment
    }
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--product-color', this.valueColor);
      this.style.setProperty('--arrow-background-color', this.valueColor);
    }
    if(changedProps.has('checkboxEndorsementState')||changedProps.has('checkboxSignatureState')||
    changedProps.has('checkboxSafeState')) {
      if(this.checkboxSignatureState && this.checkboxEndorsementState){
        this._firstDatePayment = this.firstDatePayment
      }else{
        this._firstDatePayment = "indefinido"
      }

      //--------Servicio de Firma Electrónic-----//
      this.monthlyVAT= this.electronicSignature/this.term

      //-----------Aval fijo-----------//
      this.fixedGuarante = this.amount* this.guaranteeConstant

      //----------Aval variable----------//
      this.variableGuarantee = this.guaranteeVariable * 30 * this.amount

      //-----amortizacion crédito------//
      const interestMonthly = Math.pow(1 + this.interest, 1 / 12) - 1;
      this.creditAmortization = ((interestMonthly * this.amount * Math.pow(1 + interestMonthly, this.term)) /
      (1 - Math.pow(1 + interestMonthly, this.term))) * -1
       
      //----------Total desembolsado------//
      if(this.checkboxEndorsementState){
        this.totalDisbursed= this.amount- this.guaranteeFurniture- this.fixedGuarante
      }else{
        this.totalDisbursed= this.amount- this.guaranteeFurniture
      }
      

      this.monthlyQuote = monthlyQuote(
        this.term,
        this.interest,
        this.amount,
        this.guaranteeVariable,    
        this.electronicSignature,
        this.checkboxSignatureState,
        this.checkboxSafeState,
        this.checkboxEndorsementState,
        this.secureWellbeing,
        this.product
      )
       
      this.requestUpdate();
    }
  }
  firstUpdated(){
    // console.log("endorsment=>",this._checkboxEndorsementState)
    // console.log("Signature=>",this._checkboxSignatureState)
    // console.log("seguro=>",this._checkboxSafeState)
    this.checkboxEndorsementState=this._checkboxEndorsementState
    this.checkboxSignatureState=this._checkboxSignatureState
    if(!this._checkboxEndorsementState){
      this.handelCheckboxInit('checkboxEndorsement', '.endorsement p');
    }
    if(!this._checkboxSignatureState){
      this.handelCheckboxInit('checkboxSignature', '.signature p');
    }
    if(!this._checkboxEndorsementState||!this._checkboxSignatureState){
      this.checkboxesVisible=true;
    }
    this.valueColor= this.product==="JTD"?"#6235CB":"#2F5BFF"

    this.requestUpdate();
  }

  initializeTooltips() {
    const tooltips = [
      { selector: '.disbursement-info', content: 'Esto es lo que te desembolsaremos' },
      { selector: '.monthly-quote-info', content: 'Esta es la cuota mensual' },
      { selector: '.credit-cost-info', content: 'Estos son los costos de tu credito' },
      { selector: '.guarantee-furniture-info', content: 'Es un respaldo para tu crédito que involucra bienes muebles. En caso de incumplimiento, Juancho Te Presta puede recuperar estos bienes para cubrir la deuda.' },
      { selector: '.endorsement-info', content: 'Es una garantía de pago que otorgas a través de un tercero. Si tu solicitud es aprobada, te ofrecemos proveedores con los cuales puedes contratar el aval.' },
      { selector: '.firma-electronica', content: `Con este servicio, puedes firmar electrónicamente todos los documentos esenciales para la aceptación y desembolso
                de tu crédito. Esto incluye el acuerdo económico, contrato de garantía crediticia,
                aviso de privacidad de la compañia avaladora, contrato de garantía mobiliaria,
                autorización de débito automático, poder irrevocable para suscribir libranza, 
                pagaré y carta de instrucciones.<br><br>
                si deseas evitar este costo y ya tienes un certificado de firma digital vigente, envíanos todos
                los documentos al correo electrónico ayuda@juanchotepresta.com.<br><br>
                En caso de que no puedas obtener un certificado de firma digital vigente de una entidad
                certificadora, envíanos todos los documentos físicos y autenticados ante notaria a la dirección indicada en el acuerdo
                económico.`},
      { selector: '.safe-info', content: 'Con este servicio puedes obtener un seguro para tu bienestar.' },
      { selector: '.informacion-legal',content: `JuanchoTePresta.com, pone a tu disposición el presente Simulador en 
                    los términos de Ley, el cual tiene fines informativos y no comporta
                    oferta o promesa de contratar. Este simulador es indicativo y está destinado a proporcionarte información y 
                    estimaciones de carácter general. El resultado de esta simulación no comprende una certificación o recomendación 
                    comercial, contable, tributaria o legal. Los términos de esta simulación son suministrados con base en las
                     condiciones comerciales y de mercado que han sido establecidas para la fecha en que realizas esta simulación.
                      La tasa de interés remuneratoria corresponderá a la que JUANCHO TE PRESTA SAS tenga establecida al momento
                       de tu utilización. Otros componentes del crédito dependen de terceros y pueden variar según tu nivel de
                        riesgo y capacidad de pago.` }
    ];

    tooltips.forEach(({ selector, content }) => {
      const elements = this.shadowRoot.querySelectorAll(selector);
      elements.forEach(el => {
        tippy(el, {
          content: content,
          animation: 'scale',
          allowHTML: true,
          theme: 'light',
          maxWidth: 300,
          onShow(instance) {
            const tippyBox = instance.popper.querySelector('.tippy-box');
            tippyBox.style.backgroundColor = 'rgba(240, 240, 240, 0.7)';
            tippyBox.style.backdropFilter = 'blur(5px)';
            tippyBox.style.borderRadius = '15px'; // Aumenta este valor para un borde más redondeado
          },
        });
      });
    });
  }

  handleOpenPopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = true;
    }
    if(typePopup==="signature"){
      this.popupSignature = true;
    }
    if(typePopup==="safe"){
      this.popupSafe = true;
    }
    if(typePopup==="important"){
      this.popupImportant = true;
    }
    this.requestUpdate();
  }
  handleClosePopup(typePopup) {
    if(typePopup==="endorsement"){
      this.popupEndorsement = false;
    }
    if(typePopup==="signature"){
      this.popupSignature = false;
    }
    if(typePopup==="safe"){
      this.popupSafe = false;
    }
    if(typePopup==="important"){
      this.popupImportant = false;
    }
    this.requestUpdate();
  }
  handleClosePopupWithoutDelete(typePopup) {
    console.log("se ativo handleClosePopupWithoutDelete",typePopup);
    const pSelector= `.${typePopup} p`
    const pTags = this.shadowRoot.querySelectorAll(pSelector);
    if(typePopup==="endorsement"){
      this.popupEndorsement = false;
      this.checkboxEndorsementState = true;
      const checkboxEndorsement = this.shadowRoot.getElementById('checkboxEndorsement');
      
      if (checkboxEndorsement) {
        checkboxEndorsement.checked = true;
      }
    }
    if(typePopup==="signature"){
      this.popupSignature = false;
      this.checkboxSignatureState = true;
      const checkboxSignature = this.shadowRoot.getElementById('checkboxSignature');
      if (checkboxSignature) {
        checkboxSignature.checked = true;
      }
    }
    if(typePopup==="safe"){
      this.popupSafe = false;
      this.checkboxSafeState = true;
      const checkboxSafe = this.shadowRoot.getElementById('checkboxSafe');
      if (checkboxSafe) {
        checkboxSafe.checked = true;
      }
    }
    pTags.forEach(p => {
      p.classList.remove('subrayado');
    });
    this.requestUpdate();
  }
  handleButtonClick() {
    this.checkboxesVisible = !this.checkboxesVisible;
    this.requestUpdate();
  }
  handelCheckboxInit(checkboxId, pSelector){
    const checkbox = this.shadowRoot.getElementById(checkboxId);
    const pTags = this.shadowRoot.querySelectorAll(pSelector);
    if(checkboxId==="checkboxEndorsement"){
        pTags.forEach(p => {
          p.classList.add('subrayado');
        });
        checkbox.checked=false
    }
    if(checkboxId==="checkboxSignature"){
        pTags.forEach(p => {
          p.classList.add('subrayado');
        });
        checkbox.checked=false
    }
  }
  handleCheckboxChange(checkboxId, pSelector) {
    const checkbox = this.shadowRoot.getElementById(checkboxId);
    const pTags = this.shadowRoot.querySelectorAll(pSelector);

    if (checkboxId === 'checkboxEndorsement') {
      this._checkboxEndorsementState = checkbox.checked;
    } else if (checkboxId === 'checkboxSignature') {
        this._checkboxSignatureState = checkbox.checked;
    } else if (checkboxId === 'checkboxSafe') {
        this._checkboxSafeState = checkbox.checked;
    }
    if (!checkbox.checked) {
      if(checkboxId==="checkboxEndorsement"){
        this.handleOpenPopup("endorsement") 
        this.checkboxEndorsementState=false
      }
      if(checkboxId==="checkboxSignature"){
        this.handleOpenPopup("signature") 
        this.checkboxSignatureState=false
      }
      if(checkboxId==="checkboxSafe"){
        this.handleOpenPopup("safe") 
        this.checkboxSafeState=false
      }
      pTags.forEach(p => {
        p.classList.add('subrayado');
      });
    } else {
      if(checkboxId==="checkboxEndorsement"){
        this.handleClosePopup("endorsement")
        this.checkboxEndorsementState=true
      }
      if(checkboxId==="checkboxSignature"){
        this.handleClosePopup("signature")
        this.checkboxSignatureState=true
      }
      if(checkboxId==="checkboxSafe"){
        this.handleClosePopup("safe")
        this.checkboxSafeState=true
      }
      pTags.forEach(p => {
        p.classList.remove('subrayado');
      });
    }
  }
  handleButtonRequest() {
    this.isButtonDisabled = true;
    this.dispatchEvent(new CustomEvent('button-request', {
        bubbles: true, 
        composed: true,
        detail:{
           signature:this.checkboxSignatureState,
           Endorsement:this.checkboxEndorsementState, 
           repaymentsStartingFromDate:this._firstDatePayment
       } 
       }));
   }
  render() {
    
    return html`
    <div class="main-continer">
      <popup-important valueColor=${this.valueColor} 
        .isOpen=${this.popupImportant}
        @close-popup="${()=>this.handleClosePopup("important")}">
       <div slot="title">¿Qué debo hacer?</div>
      </popup-important>
      <popup-services valueColor=${this.valueColor} 
        .isOpen=${this.popupEndorsement}
        @close-popup="${()=>this.handleClosePopup("endorsement")}" 
        @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("endorsement")}">
        <p class="popup-windows">El servicio de Aval te permite pedir el crédito sin 
          codeudor y agilizar los tiempos de desembolso.
        </p> <div slot="title">Aval</div>
        <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
          adipisicing elit. Autem dicta repellendus quia sunt quod,
          sed dolore harum nulla labore tenetur eum maiores perspiciatis
          molestiae dolorum corrupti, doloremque consequuntur veritatis
          quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
          molestiae beatae animi, laboriosam placeat atque error culpa
          aspernatur quas ad dolore accusantium harum excepturi!</div>
      </popup-services>
      <popup-services 
        valueColor=${this.valueColor}  
        .isOpen=${this.popupSignature}
        @close-popup="${()=>this.handleClosePopup("signature")}"
        @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("signature")}"
        ><p class="popup-windows">El servicio de Firma 
          Electrónica te evita el papeleo y nos permite desembolsarte en 24 horas. 
        </p> <div slot="title">Firma electrónica</div>
        <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
          adipisicing elit. Autem dicta repellendus quia sunt quod,
          sed dolore harum nulla labore tenetur eum maiores perspiciatis
          molestiae dolorum corrupti, doloremque consequuntur veritatis
          quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
          molestiae beatae animi, laboriosam placeat atque error culpa
          aspernatur quas ad dolore accusantium harum excepturi!</div>
      </popup-services>
      <popup-services 
        valueColor=${this.valueColor}
        .isOpen=${this.popupSafe}
        @close-popup="${()=>this.handleClosePopup("safe") }"
        @close-popup-without-delete="${()=>this.handleClosePopupWithoutDelete("safe")}"
        ><p class="popup-windows">El servicio de seguro bienestar
          te cuida en tus momentos mas dificiles.
        </p> <div slot="title">seguro bienestar</div>
        <div slot="extra-info"> Lorem ipsum dolor sit, amet consectetur 
          adipisicing elit. Autem dicta repellendus quia sunt quod,
          sed dolore harum nulla labore tenetur eum maiores perspiciatis
          molestiae dolorum corrupti, doloremque consequuntur veritatis
          quis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
          Aliquam totam voluptatibus repellendus! Rem perspiciatis neque
          molestiae beatae animi, laboriosam placeat atque error culpa
          aspernatur quas ad dolore accusantium harum excepturi!</div>
      </popup-services>
      ${this.existAddOptinalServices ? html` 
       <div class="warning" >
         <img class="warning-check" src=${check} alt="icono de check" />
        <p>Has agregado los servicios opcionales. Haz clic en <strong>Siguiente</strong>
           para finalizar tu solicitud de préstamo.</p>
           </div>
          `:html``}
      <div class="container-all">
      
        <h4>Resumen de tu crédito</h4>
        <p> Este es el resumen de tu credito, podras ver tus opciones  </p>
        <div class="first-box">
          <div class="sub-first-box title-amount" >
            <p>Monto solicitado</p>
            <p>$${formatNumber(this.amount)}</p>
          </div>
          <p>${this.term} cuotas</p>
          <div class="horizontal"></div>
          <div class="sub-first-box ">
            <p>Te desembolsaremos <span class="disbursement-info help">¡</span> </p>
            <p>$${formatNumber(this.totalDisbursed)}</p>
          </div>
          <div class="horizontal"></div>
          <div class="sub-first-box">
            <p>Cuota mensual <span class="monthly-quote-info help">¡</span> </p>
            <p>$${formatNumber(this.monthlyQuote)}</p>
          </div>
          <p>Primer pago: ${this._firstDatePayment}</p>
          ${this.genderName==="Femenino"? html`
          <div class="set-label">
            <label class="sub-message-one"> Mujer, tu couta mensual más baja</label>
          </div>`:html``} 
        </div>
        <div class="second-box">
          <h4>Costos de tu credito <span class="credit-cost-info help-two">?</span></h4>
          <h5>Costo obligatorio</h5>
          <div class="movable-guarantee">
             <p>Garantía Mobiliaria</p> <p><span class="guarantee-furniture-info help-three">¡</span>$${formatNumber(this.guaranteeFurniture)}</p>
          </div>
          <h5>Servicios opcionales</h5>
          <p>Texto persuasivo para agregar los servicios.</p>
          <div class="checkbox-container endorsement set-label" >
              <input class="${this.checkboxesVisible ? '' : 'hidden'}" type="checkbox" checked id="checkboxEndorsement">
              <p><strong class="padding-checkbox"> Aval </strong></p>
              <span class="endorsement-info help-three">¡</span>
              ${this.checkboxEndorsementState===true
               ? html`<label class="message"> No codeudor</label>`
               :html`<label class="sub-message-three">Necesitas codeudor</label>`}
              ${this.product==="JTD"
               ? html`<label class="sub-message-two">-50% aval variable</label>`
               : html``
              }
               
          </div>
          <div class="main-container-endorsement">
            ${this.checkboxesVisible===true
                ? html`<div class="endorsement-space"></div>`
                :html``}
            
            <div class=" container-endorsements padding-checkbox">
              <div class="continer-endorsement ">
                  <div class="line-between-points"></div>
                  <p>Fijo</p>
                  <p>$${formatNumber(this.fixedGuarante)}</p>
              </div>
              <div class="continer-endorsement endorsement">
                  <p>Variable</p>
                  <p>$${formatNumber(this.variableGuarantee)}</p>
              </div>
            </div> 
          </div>
          <div class="continer-warranty">
            <div class="checkbox-container padding-checkbox" >
              <p> <strong>Amortización crédito</strong> </p>
              <span class="informacion-legal help-three">¡</span>
            </div>
            <p>$${formatNumber(Math.round(this.creditAmortization))}</p>
          </div>
          <div class="continer-warranty signature">
            <div class="checkbox-container set-label" >
              <input class="${this.checkboxesVisible ? '' 
                : 'hidden'}" type="checkbox" checked id="checkboxSignature">
                <label><p> <strong>Firma electrónica </strong> </p>
                   <span class="firma-electronica help-three">¡</span>
                   ${this.checkboxSignatureState===true
                    ? html`<label class="message"> Desembolso rápido</label>`
                    :html`<label class="sub-message-three">Necesitas Firma</label>`}
                </label>
            </div>
            <p>$${formatNumber(Math.round(this.monthlyVAT))}</p>
          </div>
          <!-- ${this.product!=="JTD"?html`
            <div class="continer-warranty safe">
              <div class="checkbox-container set-label" >
                <input class="${this.checkboxesVisible ? '' : 'hidden'}" type="checkbox" checked id="checkboxSafe">
                <label> <p> <strong>Seguro de bienestar</strong></p>  
                  <span class="safe-info help-three">¡</span>
                  ${this.checkboxSafeState===true
                  ? html`<label class="message">Protección</label>`
                  :html``}
                </label>
              </div>
              <p>$${formatNumber(this.secureWellbeing)}</p>
            </div>
            `:''
          } -->
          ${!this.checkboxEndorsementState||!this.checkboxSignatureState?
          html`<div class="important">
            <p>Es importante que revises <strong @click="${()=>this.handleOpenPopup("important")}">aquí</strong>  lo que debes 
            hacer en caso de no contratar algún servicio opcional.</p>  
          </div>`:html``
          }
          <button class="change-services" @click="${this.handleButtonClick}">* Modificar servicios opcionales 
            ${this.checkboxesVisible?"<":">"}
          </button>
        </div>  
      </div>
      <button class="btn-request" 
        ?disabled="${this.isButtonDisabled}" 
        @click="${this.handleButtonRequest}">Siguiente  <span> ></span>
      </button>
    </div>
    `;
  }
  
  }

customElements.define('simulator-summary', SimulatorSummary);
