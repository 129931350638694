import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Cookies from './components/Cookies';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import './components/lit/simulator/credit-simulator';
import ScrollToTop from './components/ScrollToTop';
import ValidVersion from './components/ValidVersion';
import { VideoIDPortal } from './components/VideoID';
import Routes from './Routes';
import { store } from './store';
import GlobalStyle from './styles/GlobalStyles';
import './styles/Icons';
import styled, { ThemeProvider } from './styles/styled-components';
import theme from './styles/theme';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;
  align-items: stretch;
  min-height: 100%;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: top;
  align-content: center;

  > div {
    max-width: 100%;
    flex-grow: 1;
  }
`;

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <VideoIDPortal />
      <ValidVersion />
      <Cookies />
      <BrowserRouter>
        <ScrollToTop>
          <GlobalStyle />
          <Main>
            <Header />
            <Content>
              <Routes />
            </Content>
            <Footer />
          </Main>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

export default App;
