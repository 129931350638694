import { LitElement, html, css } from 'lit';

class PopupRequest extends LitElement {
  static styles = css`
    :host {
      --light-blue-gray: #CED5F0;
      --light-gray: #ccc;
      --dark-gray: #333;
      --color-gender: #2F5BFF;
      --gray-color:#6c757d;
      --white-color:#ffffff;
      display: inline-block;
    }
    .popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
    .popup-container__popup {
      position:relative;
      background-color: white;
      padding: 10px 20px 20px 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      border-radius: 35px;
      z-index: 1000;
      max-width: 32ch;
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      color:black;
    }
    .popup-container__title-bar {
      display:flex;
      flex-direction:column;
      align-items:center;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .close-button {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      transition: background-color 0.3s ease;
    }
    .close-button:hover {
      background-color: var(--light-gray);
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
      z-index: 998; 
    }
    button {
      background-color: var(--light-blue-gray);
      border: none;
      border-radius: 20px;
      margin-top: 20px;
      height: 39px;
      transition: box-shadow 0.3s, transform 0.3s;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0 , 0.4);
      cursor: pointer;
      font-weight: 600;
      padding: 0 15px 0 15px;
      color: white;
      background-color: var(--color-gender);
    }
    .checkbox-container {
      margin-top:5px;
      display: flex;
      justify-content:flex-start;
      flex-direction: column;
    }

    .checkbox-container input[type="checkbox"] {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 2px solid var(--gray-color); 
      background-color: transparent;
      transition: background-color 0.3s, border-color 0.3s;
      position: relative;
      margin-right:8px;
    }

    .checkbox-container input[type="checkbox"]:checked {
      background-color: var(--gray-color); 
      border-color: var(--gray-color);
    }

    .checkbox-container input[type="checkbox"]:checked::before {
      content: '✔';
      color: var(--white-color); 
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight:700;
    }

    .popup-container__simbol---{
      background-color:var(--gray-color);
      width:45px;
      height:4px;
      margin-bottom:15px;
      cursor:pointer;
    }
    .popup-container__simbol--x{
      position:absolute;
      top:7px;
      left:7px;
      border-radius:50%;
      /* border: solid 1px var(--gray-color); */
      font-size:19px;
      width:18px;
      height:18px;
      margin:10px;
      color:var(--gray-color);
      cursor:pointer;
      p{
        margin:0;
        padding:0 0 1px 4px;
        line-height: 16px; 
      }
    }
    .checkbox-container__label{
      display:flex;
      align-items:center;
      justify-content:flex-start;
      margin-top:5px;
    }
  `;
  static get properties() {
    return {
      valueColor: { type: String },
      secureWellbeing: { type: Boolean },
      signature: { type: Boolean },
      aval: { type: Boolean },
      _secureWellbeing: { type: Boolean },
      _signature: { type: Boolean },
      _aval: { type: Boolean },
    };
  }
  constructor() {
    super();
    this.secureWellbeing = false;
    this.signature = false;
    this.aval = false;
    this._secureWellbeing = false;
    this._signature = false;
    this._aval = false;
  }

  updated(changedProps) {
    if (changedProps.has('valueColor')) {
      this.style.setProperty('--color-gender', this.valueColor);
    }
  }
  closePopup() {
    this.dispatchEvent(new CustomEvent('close-popup', {
      detail: {
        secureWellbeing: this.secureWellbeing,
        signature: this.signature,
        aval: this.aval
      }
    }));
  }

  acceptClosePopup() {
    this.dispatchEvent(new CustomEvent('accept-close-popup', {
      detail: {
        secureWellbeing: this.secureWellbeing,
        signature: this.signature,
        aval: this.aval
      }
    }));
  }


  firstUpdated() {
    const titleElement = this.querySelector('[slot="title"]');
    if (titleElement) {
      this.title = titleElement.innerText;
    }
    this._secureWellbeing=this.secureWellbeing
    this._signature=this.signature
    this._aval=this.aval
  }

  handleCheckboxChange(e) {
    const { name, checked } = e.target;
    if (checked) {
      this[name] = checked;
    } else {
      e.target.checked = true;
    }
  }

  render() {
    return html`
      <div class="popup-container">
        <div class="popup-container__popup">
          <div class="popup-container__simbol--x" @click="${this.closePopup}">
            <p>x</p>
          </div>
          <div class="popup-container__title-bar">
            <div class="popup-container__simbol---" @click="${this.closePopup}"></div>
            <slot name="title"></slot>
          </div>
          <!-- Contenido de la ventana emergente -->
          <slot></slot>
          <div class="checkbox-container">
            ${this._aval ===false ? html`
            <label class="checkbox-container__label">
              <input
                type="checkbox"
                name="aval"
                .checked=${this.aval}
                @change=${this.handleCheckboxChange}
              />
              aval
            </label>
            `: html``
            }
            ${this._signature===false ? html`
            <label class="checkbox-container__label">
              <input
                type="checkbox"
                name="signature"
                .checked=${this.signature}
                @change=${this.handleCheckboxChange}
              />
              signature
            </label>
            `  : html``
            }
             ${this._secureWellbeing ===false ? html`
             <label class="checkbox-container__label">
              <input
                type="checkbox"
                name="secureWellbeing"
                .checked=${this.secureWellbeing}
                @change=${this.handleCheckboxChange}
              />
              Secure Wellbeing
            </label>
            `: html``
            }
          </div>
          <button @click="${this.acceptClosePopup}">Aceptar</button>
        </div>
        <!-- Capa de fondo -->
        <div class="overlay" @click="${this.closePopup}"></div>
      </div>
    `;
  }
}

customElements.define('popup-request', PopupRequest);