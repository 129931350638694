import { ServiceLoaded } from '../types/Service';
import { request } from './api';
import { ApplicationResponse } from './changeLoanValuesGet';

interface Params {
  url: string;
}

export const url = (uuid: string) => `loans/signature/${uuid}`;

export default async function loanSignaturePost(
  uuid: string,
  payload: Params
): Promise<ServiceLoaded<ApplicationResponse>> {
  try {
    const responsePayload = await request(
      'post',               
      url(uuid),             
      false,                  
      payload,              
      false                    
    );

    return {
      status: 'loaded',
      payload: responsePayload,
    };
  } catch (e) {
    console.error('Error al realizar la solicitud:', e);
    throw e;
  }
}