import React from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import calculateOptionalComponents from '../../../components/OptionalComponentsCalculator';
import changeLoanValuesGet, { ApplicationResponse } from '../../../services/changeLoanValuesGet';
import { ValuesPach } from '../../../services/changeLoanValuesPost';
import { UserDesenbplsingMethods } from '../../../services/eventTypeGet';
import { Option, OptionNumber } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import {
  ResolveNextPath,
  resolveNextPath,
} from '../../../store/actions/navigation';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { changeLoanValuesPatch } from '../../../store/reducers/changeLoanValuesPatchReducer';
import { simulatorSelector } from '../../../store/selectors/simulatorSelector';
import PredisburseInfoView from './PredisburseInfoView';
export interface Props extends RouteComponentProps {
  submit(params: ValuesPach): Promise<void>;
  resolveNextPath: ResolveNextPath;
  banks: Option[];
  accountTypes: Option[];
  fullName: string;
  email: string;
  amount: number;
  term: number;
  loanId: string;
  loanStatus: string;
  filterEventType: (value: UserDesenbplsingMethods[]) => OptionNumber[];
}

const SignatureContainer: React.FC<Props> = (props) => {
  const simulator = useSelector(simulatorSelector);
  return (
    <Mount
      before={async () => {
        const summary: ApplicationResponse = await changeLoanValuesGet();
        const { optionalComponents, existsElectronicSignature, existsGuaranteeVariable } = calculateOptionalComponents(simulator.components, summary,203);
        
        return {
          daysCounter:summary.daysCounter,
          optionalComponents,
          existsElectronicSignature,
          existsGuaranteeVariable,
        };
      }}
      props={props}
      component={PredisburseInfoView}
    />
  );
};

export default connect((state: JTPState, ownProps) => ({
  loanId: state.loan.id,
  loanStatus: state.loan.status,
  ...state.user.user,
  ...ownProps,
}),
(dispatch) => ({
  submit: asyncDispatch(dispatch, changeLoanValuesPatch.actions.request),
  resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
})
)(SignatureContainer);
